import { baseAxiosInstance } from 'axios-config';
import {
  BulkChangeProductVariantStatusData,
  CreateData,
  CreateProductVariantData,
  ProductVariantUpdateResponse,
  UpdateProductVariantData,
} from 'services/product-variant.model';
import { BarcodeValidationModel, ProductVariant } from 'services/products.model';
import { IdName } from 'types/common-types';

export class ProductVariantService {
  public static async getAll(id: string, isActive?: boolean) {
    const { data } = await baseAxiosInstance.get<ProductVariant[]>(`/product-variants/all`, {
      params: {
        is_active: isActive,
        product_configuration_id: id,
      },
    });
    return data;
  }

  public static async getUniqueVariants() {
    const { data } = await baseAxiosInstance.get<IdName[]>(`/product-variants/find-unique-names`);
    return data;
  }

  public static async getById(id: string) {
    const { data } = await baseAxiosInstance.get<ProductVariant>(`/product-variants/${id}`);
    return data;
  }

  public static async update(id: string, body: UpdateProductVariantData) {
    const { data } = await baseAxiosInstance.put<ProductVariantUpdateResponse>(`/product-variants/${id}`, body);
    return data;
  }

  public static async bulkChangeStatus(body: BulkChangeProductVariantStatusData) {
    const { data } = await baseAxiosInstance.put<any>(`/product-variants/activate-deactivate-all`, body);
    return data;
  }

  public static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete<ProductVariant>(`/product-variants/${id}`);
    return data;
  }

  public static async create(body: CreateData) {
    const { data } = await baseAxiosInstance.post<ProductVariant>(`/product-variants/create`, body);
    return data;
  }

  public static async createVariants(body: CreateProductVariantData | FormData) {
    const { data } = await baseAxiosInstance.post<ProductVariant>(`/product-variants/create-product-variants`, body);
    return data;
  }

  public static async validate(sku: string, configurationId: string) {
    const { data } = await baseAxiosInstance.get<BarcodeValidationModel>(
      `/product-variants/validate?sku=${sku}&id=${configurationId}`,
    );
    return data;
  }
}
