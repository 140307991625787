import { baseAxiosInstance } from 'axios-config';
import { ProductVendor } from 'services/product-vendor.model';
import { IdName, PaginatedResponse } from 'types/common-types';
import { buildQueryString } from 'utils/build-query-string';

export class ProductVendorService {
  public static async getAll(params: { search?: string; skip?: number; take?: number } = {}) {
    const { search = '', skip = 0, take = 0 } = params;

    const queryParams = buildQueryString({ search, skip, take });

    const { data } = await baseAxiosInstance.get<PaginatedResponse<IdName[]>>(`/product-vendors/all?${queryParams}`);

    return data;
  }

  public static async create(text: string) {
    const { data } = await baseAxiosInstance.post<ProductVendor>('/product-vendors/create', { name: text });
    return data;
  }
}
