import { notify } from 'notifications';
import { ColGroupDef, ValueSetterParams, ColDef, ColumnState, GridApi } from 'ag-grid-community';
import { ManageTaskPriorityModalActions } from 'pages/production/controllers/manage-task-priority.controller';
import { DisplayRangeType } from 'pages/production/controllers/production-filters-controller/types';
import {
  agGridGroupedColumnDefinitions,
  TASKS_PER_PAGE,
  ALL,
  NO_POSITIONS,
  unassignedOption,
  valueOptionsSelectedOptions,
  SELECT_ALL,
} from 'pages/tasks/constants';
import {
  getPinnedSide,
  getColumnWidth,
  filterValidFields,
  checkHiddenColumn,
  generateRequestBody,
  getFormattedFilters,
  getActiveFilters,
  sortSavedColumnsState,
  transformToAgGridColumnState,
} from 'pages/tasks/tasks.helpers';
import {
  BulkSCDeferredTasksT,
  BulkSCProcessedState,
  ColumnOrderItemT,
  ColumnSizesT,
  ManageSelectOrDeselectAllTasksArgs,
  PerformerItemT,
  ResponsibilityDepartmentsEnum,
  SelectedTaskT,
  TasksFiltersEnum,
  TasksFiltersT,
  TaskTableStateT,
  TaskTableWebsocketEventCategory,
} from 'pages/tasks/types/types';
import { AppState, GetStateFunction } from 'redux/store';
import { ProductionTaskService } from 'services/production-task.service';
import { ShowCompletedPeriodEnum } from 'services/production-workflow.model';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import {
  TaskTableModel,
  TaskTableFiltersT,
  WebsocketResponseMessageForTaskTableT,
  FinishedAssignWebsocketResponseForTasksTableT,
  WebsocketResponseMessageForAssignmentT,
  WebsocketResponseMessageForTaskStatusT,
  WebsocketStartEndMessageForTaskStatusT,
} from 'services/task-table.model';
import { TaskTableService } from 'services/task-table.service';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';
import { StateController } from 'state-controller';
import { TaskTypeEnum, WebsocketEvent } from 'types/common-enums';
import { IdName, MetaT, PaginationData, SortOrderOption } from 'types/common-types';
import { UserService } from 'services/user.service';
import { PriorityEnum } from 'types/priority-enums';
import { ProductionStatusEnum, TaskStatusEnum, UserStatusEnum } from 'types/status-enums';
import { debounce } from 'utils/debounce';
import { TaskAssigmentService } from 'services/task-assigment.service';
import {
  AssignmentUser,
  GetTasksByFiltersArgs,
  HandleSlotIdsUpdateFromWebsocketArgs,
  HandleTasksIdsUpdateFromWebsocketArgs,
  OnAssigneeOrVendorFilterChangeArgs,
  OnFilterChangeArgs,
  OnReasonForFailureFilterChangeArgs,
  ReplaceTaskInfoFromWsArgs,
  UpdateBulkSCProgressArgs,
  updatedSelectedTasksStateArgs,
} from 'pages/tasks/types/function-types';
import { UserShortModel, User } from 'services/user.model';
import {
  collectAllTaskSlotIds,
  extractUpdatedTaskResponsibilitiesValues,
  getAllTasksOnTheScreen,
  getResponsibleId,
  mapAssignAt,
  mapBulkStatusChangeCountersToState,
  prepareRequestBodyForMultipleBulkAssignUsers,
  prepareRequestBodyForSingleBulkAssignUsers,
  updateTasksFromStatusChangeWsResponse,
  updateTasksFromWsResponse,
} from 'pages/tasks/helpers';
import { UNASSIGNED } from 'constants/unassigned';
import { PAGE_SIZE } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import { TasksLaunchingProgressActions } from 'pages/tasks/components/task-assign-users-progress-popover/task-assign-users-progress-popover.controller';
import axios from 'axios';
import { MouseEvent } from 'react';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';

export type TasksState = {
  tasks: {
    meta: MetaT;
    data: TaskTableStateT[];
  };
  bulkAssignPerformer: {
    isLoading: Record<number, boolean>;
    options: Record<number, PerformerItemT[]>;
    value: Record<number, PerformerItemT | null>;
  };
  isLoading: boolean;
  editTaskId: string;
  isFetching: boolean;
  users: Array<User>;
  filters: TasksFiltersT;
  columnSizes: ColumnSizesT;
  lastSelectedTaskId: string;
  bulkAssignTaskIds: string[];
  bulkAssignSlotIds: string[];
  selectedTasks: SelectedTaskT[];
  columnOrder: ColumnOrderItemT[];
  activeFilters: TasksFiltersEnum[];
  bulkAssignFailedTaskKeys: string[];
  isEditBasicRewardModalOpened: boolean;
  groupedColumnDefinition: ColGroupDef[];
  bulkStatusChangeModalIdForData: string;
  bulkAssignIsInvalidSlotAssignment: boolean;
  bulkAssignIsAssignUsersModalOpened: boolean;
  savedAndSortedColumnStateGrid: ColumnState[];
  bulkStatusChangeIsConfirmationModalOpened: boolean;
  bulkStatusChangeSelectedStatus: TaskStatusEnum | string;
  bulkAssignSelectedPerformers: Record<number, string | null>;
  bulkStatusChangeIsFailedModalOpened: Record<string, boolean>;
  bulkStatusChangeIsSuccessModalOpened: Record<string, boolean>;
  bulkStatusChangeProgress: Record<string, BulkSCProcessedState>;
  bulkAssignPaginationPerformers: Record<number, PaginationData>;
  bulkStatusChangeDeferredTasks: Record<string, BulkSCDeferredTasksT[]>;
};

const tasksDefaultState: TasksState = {
  tasks: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      next: 0,
      perPage: 0,
      prev: 0,
      total: 0,
    },
  },
  bulkAssignPerformer: {
    isLoading: {},
    value: {},
    options: [],
  },
  users: [],
  editTaskId: '',
  columnOrder: [],
  activeFilters: [],
  isLoading: false,
  isFetching: false,
  columnSizes: null,
  selectedTasks: [],
  bulkAssignTaskIds: [],
  bulkAssignSlotIds: [],
  lastSelectedTaskId: '',
  groupedColumnDefinition: [],
  bulkAssignFailedTaskKeys: [],
  bulkStatusChangeProgress: {},
  bulkAssignSelectedPerformers: {},
  savedAndSortedColumnStateGrid: [],
  bulkAssignPaginationPerformers: {},
  bulkStatusChangeModalIdForData: '',
  bulkStatusChangeDeferredTasks: {},
  bulkStatusChangeSelectedStatus: '',
  isEditBasicRewardModalOpened: false,
  bulkStatusChangeIsFailedModalOpened: {},
  bulkStatusChangeIsSuccessModalOpened: {},
  bulkAssignIsInvalidSlotAssignment: false,
  bulkAssignIsAssignUsersModalOpened: false,
  bulkStatusChangeIsConfirmationModalOpened: false,
  filters: {
    filters: {
      // Product
      // [TasksFiltersEnum.ProductVendor]: valueOptionsSelectedOptions,
      [TasksFiltersEnum.ProductVendor]: {
        value: [],
        options: [],
        selectedOptions: [],
        shortcutOptions: [{ name: 'Select all', id: SELECT_ALL }],
      },

      // Production
      [TasksFiltersEnum.ProductionDeadline]: { value: [null, null] },
      [TasksFiltersEnum.ProductionStatus]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'To Do', id: ProductionStatusEnum.To_Do },
          { name: 'Stopped', id: ProductionStatusEnum.Stopped },
          { name: 'In Progress', id: ProductionStatusEnum.In_Progress },
          { name: 'Done', id: ProductionStatusEnum.Done },
          { name: 'From stock', id: ProductionStatusEnum.From_Stock },
          { name: 'Canceled', id: ProductionStatusEnum.Canceled },
        ],
      },
      [TasksFiltersEnum.RootProductionDeadline]: { value: [null, null] },

      [TasksFiltersEnum.ShowCompleted]: {
        radioValue: ShowCompletedPeriodEnum.Some,
        value: { id: '1', name: '1 day' },
        options: [
          { id: '1', name: '1 day' },
          { id: '3', name: '3 days' },
          { id: '7', name: '7 days' },
          { id: '30', name: '30 days' },
          { id: '90', name: '90 days' },
        ],
      },

      // Task
      [TasksFiltersEnum.IsInQueue]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.IsFailed]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.FailedAt]: { value: [null, null] },
      [TasksFiltersEnum.TaskType]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Workflow', id: TaskTypeEnum.Workflow },
          { name: 'Additional', id: TaskTypeEnum.Additional },
        ],
      },
      [TasksFiltersEnum.TaskStatus]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Reopened', id: TaskStatusEnum.Reopened },
          { name: 'In Progress', id: TaskStatusEnum.In_Progress },
          { name: 'On hold', id: TaskStatusEnum.On_Hold },
          { name: 'To Do', id: TaskStatusEnum.To_Do },
          { name: 'Blocked', id: TaskStatusEnum.Blocked },
          { name: 'Done', id: TaskStatusEnum.Done },
          { name: 'Canceled', id: TaskStatusEnum.Canceled },
        ],
      },
      [TasksFiltersEnum.ReportingPeriod]: { value: [null, null] },
      [TasksFiltersEnum.TaskPriority]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: PriorityEnum.Highest, id: PriorityEnum.Highest },
          { name: PriorityEnum.High, id: PriorityEnum.High },
          { name: PriorityEnum.Medium, id: PriorityEnum.Medium },
          { name: PriorityEnum.Low, id: PriorityEnum.Low },
          { name: PriorityEnum.Lowest, id: PriorityEnum.Lowest },
        ],
      },
      // [TasksFiltersEnum.ReasonForFailure]: valueOptionsSelectedOptions,
      [TasksFiltersEnum.ReasonForFailure]: {
        value: [],
        options: [],
        selectedOptions: [],
        shortcutOptions: [{ name: 'Select all', id: SELECT_ALL }],
      },
      // Assignment
      // [TasksFiltersEnum.Assignee]: valueOptionsSelectedOptions,
      [TasksFiltersEnum.Assignee]: {
        value: [],
        options: [],
        selectedOptions: [],
        shortcutOptions: [{ name: 'Select all', id: SELECT_ALL }],
      },
      [TasksFiltersEnum.AssigneeType]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Manual', id: AssignmentType.Manual },
          { name: 'Automatic', id: AssignmentType.Auto },
          { name: 'Self assignment', id: AssignmentType.Self_Assignment },
        ],
      },
      [TasksFiltersEnum.AssigneePosition]: {
        value: [],
        options: [],
        selectedOptions: [],
        shortcutOptions: [{ name: 'All', id: ResponsibilityDepartmentsEnum.All }],
      },
      [TasksFiltersEnum.AssigneeDepartment]: {
        value: [],
        options: [],
        selectedOptions: [],
        shortcutOptions: [{ name: 'All', id: ResponsibilityDepartmentsEnum.All }],
      },
      [TasksFiltersEnum.ResponsibilityDepartment]: {
        value: [],
        options: [],
        selectedOptions: [],
        shortcutOptions: [
          { name: 'All', id: ResponsibilityDepartmentsEnum.All },
          { name: 'No departments', id: ResponsibilityDepartmentsEnum.No_Departments },
        ],
      },
      [TasksFiltersEnum.ResponsibilityPosition]: {
        value: [],
        options: [],
        selectedOptions: [],
        shortcutOptions: [
          { name: 'All', id: ResponsibilityDepartmentsEnum.All },
          { name: 'No positions', id: ResponsibilityDepartmentsEnum.No_Positions },
        ],
      },

      // Warnings
      [TasksFiltersEnum.AssigneeRequired]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.TimeLimitExceeded]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },

      // Time
      [TasksFiltersEnum.DueDate]: { value: [null, null] },
    },
    queries: {
      // Product
      [TasksFiltersEnum.ProductName]: '',
      [TasksFiltersEnum.ProductVersion]: 0,
      [TasksFiltersEnum.ProductVendor]: '',
      [TasksFiltersEnum.ProductVariant]: '',
      [TasksFiltersEnum.RootProductName]: '',
      [TasksFiltersEnum.RootProductVersion]: 0,
      [TasksFiltersEnum.RootProductVariant]: '',
      [TasksFiltersEnum.ProductConfiguration]: '',
      [TasksFiltersEnum.RootProductConfiguration]: '',

      // Production
      [TasksFiltersEnum.ProductionKey]: '',
      [TasksFiltersEnum.RootProductionKey]: '',

      // Order
      [TasksFiltersEnum.Client]: '',
      [TasksFiltersEnum.OrderKey]: '',
      [TasksFiltersEnum.PrimaryClient]: '',
      [TasksFiltersEnum.ExternalOrderNumber]: '',
      [TasksFiltersEnum.MarketPlaceOrderNumber]: '',

      // Task
      [TasksFiltersEnum.TaskKey]: '',
      [TasksFiltersEnum.TaskName]: '',
      [TasksFiltersEnum.TaskDescription]: '',
      [TasksFiltersEnum.ReasonForFailure]: '',
      [TasksFiltersEnum.ProductionHierarchy]: '',

      // Assignment
      [TasksFiltersEnum.AssigneePosition]: '',
      [TasksFiltersEnum.AssigneeDepartment]: '',
    },
    pins: {
      left: [],
      right: [],
    },
    sort: {
      orderBy: [TasksFiltersEnum.RootProductionDeadline],
      orderOption: SortOrderOption.Ascending,
    },
  },
};

const stateController = new StateController<TasksState>('TASKS', tasksDefaultState);

// Abort controllers
let getTasksByFiltersAbortController: AbortController | null = null;

export class TasksActions {
  static initTasksPage() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isLoading: true }));

      dispatch(TasksActions.getBulkStatusChangeCounters());
      await dispatch(TasksActions.getTableFilters());
      await dispatch(TasksActions.getTasksByFilters({}));

      dispatch(stateController.setState({ isLoading: false }));
    };
  }

  public static clearTasksState() {
    return (dispatch) => dispatch(stateController.setState(tasksDefaultState));
  }

  static loadMoreTasks() {
    return (dispatch, getState: GetStateFunction) => {
      const { meta, data } = getState().tasks.tasks;

      if (data.length >= meta.total) return;

      dispatch(TasksActions.getTasksByFilters({ skip: meta.next, isFetching: true }));
    };
  }

  static getTableFilters() {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const filtersData: TaskTableFiltersT | undefined = await UserService.getTaskTableFilters();

        const activeFilters = getActiveFilters(filtersData);

        const modified = agGridGroupedColumnDefinitions.map((group) => {
          const updatedChildren = group.children.map((column: ColDef) => ({
            ...column,
            pinned: getPinnedSide(column, filtersData.pins),
            width: getColumnWidth(column.field as TasksFiltersEnum, filtersData.sizes),
            hide: checkHiddenColumn(column.field as TasksFiltersEnum, filtersData.columns),
            sort: column.field === filtersData.sort?.orderBy?.[0] ? filtersData.sort?.orderOption : undefined,
            toolPanelClass: activeFilters.includes(column.field as TasksFiltersEnum) ? 'column-marker' : '',
            // Unfortunately this is how updates should happen. If you try to use onCellValueChanged
            // Ag-Grid will be mutating your original state and not via dispatch but like plane object mutations
            valueSetter: (params: ValueSetterParams<TaskTableModel>) =>
              dispatch(TasksActions.updateTask(params.data.id, { [params.colDef.field as string]: params.newValue })),
          }));

          const anyChildHasActiveFilterIndicator = updatedChildren.some((child) => child.toolPanelClass === 'column-marker');

          return {
            ...group,
            children: updatedChildren,
            toolPanelClass: anyChildHasActiveFilterIndicator ? 'column-marker' : '',
          };
        });

        const columnStateGrid = transformToAgGridColumnState(modified);

        const savedAndSortedColumnStateGrid = sortSavedColumnsState(columnStateGrid, filtersData.columns);

        const { filters } = getState().tasks.filters;

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnOrder: filtersData.columns || null,
            columnSizes: filtersData.sizes || null,
            groupedColumnDefinition: modified,
            savedAndSortedColumnStateGrid,
            activeFilters,
            filters: {
              ...prev.filters,
              pins: {
                ...prev.filters.pins,
                ...filtersData.pins,
              },
              filters: {
                ...prev.filters.filters,
                ...getFormattedFilters(filtersData.filters, filters),
              },
              queries: {
                ...prev.filters.queries,
                ...filtersData.queries,
              },
              sort: {
                orderBy: filtersData.sort?.orderBy,
                orderOption: filtersData.sort?.orderOption,
              },
            },
          })),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  static getTasksByFilters({
    skip = 0,
    take = TASKS_PER_PAGE,
    isFetching = false,
    sorting = false,
    resetToDefaultData = false,
    filterChanged = false,
    isResetColumns = false,
  }: GetTasksByFiltersArgs) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters, columnOrder, columnSizes } = getState().tasks;

      // !Decompose logic
      if (getTasksByFiltersAbortController) {
        getTasksByFiltersAbortController.abort();
      }

      getTasksByFiltersAbortController = new AbortController();
      const { signal } = getTasksByFiltersAbortController;

      try {
        dispatch(stateController.setState({ isLoading: !isFetching, isFetching }));

        const columns = columnOrder?.length ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, columns, columnSizes);

        // For resetting state of columns and filters
        const updatedResetColumns = {
          ...filters,
          pins: {
            left: [],
            right: [],
          },
        };

        const ignoreShowCompletedFilter = filters.filters[TasksFiltersEnum.ShowCompleted];

        const updatedResetFilters = {
          ...filters,
          filters: {
            ...tasksDefaultState.filters.filters,
            [TasksFiltersEnum.ShowCompleted]: ignoreShowCompletedFilter,
          },
          queries: tasksDefaultState.filters.queries,
        };

        const defaultBody = isResetColumns
          ? generateRequestBody(updatedResetColumns, null, null)
          : generateRequestBody(updatedResetFilters, columnOrder, columnSizes);

        const response = await TaskTableService.getAllTask(resetToDefaultData ? defaultBody : body, { skip, take }, signal);

        const mappedData = response.data.map((task: TaskTableModel) => ({
          ...task,
          assigned_at:
            (task.assigned_at && task.assignee && mapAssignAt({ assignee: task.assignee, assigned_ats: task.assigned_at })) ||
            null,
        }));

        // !Don`t move this, need actual data before update state(ws bulk trigger update tasks too)
        const {
          tasks: { data },
        } = getState().tasks;

        dispatch(
          stateController.setState({
            tasks: {
              ...response,
              data: filterChanged || sorting || resetToDefaultData ? mappedData : [...data, ...mappedData],
            },
          }),
        );
      } catch (error) {
        if (axios.isCancel(error)) return;

        notify.error(error.message);
      } finally {
        dispatch(stateController.setState({ isLoading: false, isFetching: false }));
        getTasksByFiltersAbortController = null;
      }
    };
  }

  static resetColumnStates() {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().tasks;
      const updatedFilters = {
        ...filters,
        pins: {
          left: [],
          right: [],
        },
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedTasks: [],
          bulkAssignIsAssignUsersModalOpened: false,
          bulkStatusChangeIsConfirmationModalOpened: false,
          bulkAssignSelectedPerformers: {},
          bulkAssignTaskIds: [],
          bulkAssignSlotIds: [],
          lastSelectedTaskId: '',
          editTaskId: '',
        })),
      );

      const body = generateRequestBody(updatedFilters, null, null);

      await UserService.setTableFilters(body);

      await dispatch(TasksActions.getTableFilters());
      await dispatch(TasksActions.getTasksByFilters({ resetToDefaultData: true, isResetColumns: true }));
    };
  }

  static resetFilterStates() {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters, columnOrder, columnSizes } = getState().tasks;

      const ignoreShowCompletedFilter = filters.filters[TasksFiltersEnum.ShowCompleted];

      const updatedFilters = {
        ...filters,
        filters: {
          ...tasksDefaultState.filters.filters,
          [TasksFiltersEnum.ShowCompleted]: ignoreShowCompletedFilter,
        },
        queries: tasksDefaultState.filters.queries,
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: updatedFilters,
          selectedTasks: [],
          bulkAssignIsAssignUsersModalOpened: false,
          bulkStatusChangeIsConfirmationModalOpened: false,
          bulkAssignSelectedPerformers: {},
          bulkAssignTaskIds: [],
          bulkAssignSlotIds: [],
          lastSelectedTaskId: '',
          editTaskId: '',
        })),
      );
      const fields = columnOrder ? filterValidFields(columnOrder) : null;

      const body = generateRequestBody(updatedFilters, fields, columnSizes);

      await UserService.setTableFilters(body);

      await dispatch(TasksActions.getTableFilters());

      await dispatch(TasksActions.getTasksByFilters({ resetToDefaultData: true, isResetColumns: false }));
    };
  }

  static onColumnMoved(newColumnOrder: ColumnOrderItemT[]) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnOrder: newColumnOrder,
          })),
        );

        const { filters, columnSizes } = getState().tasks;
        const fields = filterValidFields(newColumnOrder);
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static onColumnChecked(newColumnOrder: ColumnOrderItemT[], isColumnVisible: boolean) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnOrder: newColumnOrder,
          })),
        );

        const { filters, columnSizes } = getState().tasks;
        const fields = filterValidFields(newColumnOrder);
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);

        if (isColumnVisible) {
          await dispatch(
            TasksActions.getTasksByFilters({ skip: 0, take: TASKS_PER_PAGE, isFetching: true, filterChanged: true }),
          );
        }
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static onColumnPinned(side: 'left' | 'right' | null, columnName: TasksFiltersEnum) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const { left, right } = getState().tasks.filters.pins;
        let pinsLeft = [...left];
        let pinsRight = [...right];

        if (side === 'left') {
          pinsLeft = [...pinsLeft, columnName];
        } else if (side === 'right') {
          pinsRight = [...pinsRight, columnName];
        } else {
          pinsLeft = pinsLeft.filter((i) => i !== columnName);
          pinsRight = pinsRight.filter((i) => i !== columnName);
        }

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              pins: {
                left: pinsLeft,
                right: pinsRight,
              },
            },
          })),
        );

        const { filters, columnOrder, columnSizes } = getState().tasks;
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static onSortChanged(columnName: TasksFiltersEnum | null, sortOrder: SortOrderOption | null) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              sort: {
                orderBy: [columnName],
                orderOption: sortOrder,
              },
            },
          })),
        );

        const { filters, columnOrder, columnSizes, tasks } = getState().tasks;
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);

        dispatch(TasksActions.getTasksByFilters({ isFetching: true, sorting: true, take: tasks.data.length }));
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  static onSizeChanged(columnName: TasksFiltersEnum, newSize: number) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnSizes: {
              ...prev.columnSizes,
              [columnName]: newSize,
            },
          })),
        );

        const { filters, columnOrder, columnSizes } = getState().tasks;
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static changeTaskDisplayRange(value: Partial<DisplayRangeType>) {
    return async (dispatch, getState: GetStateFunction) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            filters: {
              ...prev.filters.filters,
              [TasksFiltersEnum.ShowCompleted]: {
                ...prev.filters.filters[TasksFiltersEnum.ShowCompleted],
                ...value,
              },
            },
          },
        })),
      );
      const { filters, columnOrder, columnSizes } = getState().tasks;
      const fields = columnOrder ? filterValidFields(columnOrder) : null;
      const body = generateRequestBody(filters, fields, columnSizes);

      await UserService.setTableFilters(body);

      dispatch(TasksActions.getTasksByFilters({ isFetching: true, filterChanged: true }));
    };
  }

  static setFilters(values: Partial<TasksFiltersT>, extendExistingValue?: boolean) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          filters: (Object.keys(values) as [keyof Partial<TasksFiltersT>]).reduce((acc, item) => {
            const currentValue = values[item];
            const previousFilter = prevState.filters[item];

            // The "undefined" comparison is needed to allow empty string in search filter
            if (currentValue === undefined) {
              return { ...acc, [item]: previousFilter };
            }

            if (
              extendExistingValue ||
              typeof currentValue === 'boolean' ||
              (Array.isArray(currentValue) && typeof currentValue[0] === 'object')
            ) {
              return { ...acc, [item]: { ...previousFilter, value: currentValue } };
            }

            return { ...acc, [item]: { value: currentValue } };
          }, prevState.filters),
        })),
      );
    };
  }

  static updateTask(taskId: string, value: Partial<TaskTableStateT>) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const { tasks, filters } = getState().tasks;

        const data = await ProductionTaskService.updateTask(taskId, value);
        const { positions, departments, assignAt } = extractUpdatedTaskResponsibilitiesValues(data);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            tasks: {
              ...prev.tasks,
              data: prev.tasks.data.map((task) => {
                if (task.id === taskId) {
                  const updatedAssignee = value.assignee
                    ? task.assignee.map((currentAssignee) => {
                        if (currentAssignee.slot_id === value.assignee?.[0].slot_id) {
                          return value.assignee?.[0];
                        }

                        return currentAssignee;
                      })
                    : task.assignee;

                  const updatedSlots = value.task_slots
                    ? task.task_slots.map((currentSlots) => {
                        if (currentSlots.id === value.task_slots?.[0].id) {
                          return value.task_slots?.[0];
                        }
                        return currentSlots;
                      })
                    : task.task_slots;
                  return {
                    ...task,
                    ...value,
                    assignee: updatedAssignee,
                    task_slots: updatedSlots,
                    assignee_position: positions,
                    assignee_department: departments,
                    assigned_at: assignAt,
                  };
                }

                return task;
              }),
            },
            selectedTasks: prev.selectedTasks.map((selectedTask) => {
              if (selectedTask.id === taskId) {
                return {
                  ...selectedTask,
                  status: value.status ?? selectedTask.status,
                  departmentIds: value.department_ids ?? selectedTask.departmentIds,
                  is_in_queue: value.is_in_queue ?? selectedTask.is_in_queue,
                  production_status: value.production_status ?? selectedTask.production_status,
                };
              }

              return selectedTask;
            }),
          })),
        );

        const filterKeys: Array<[keyof TaskTableStateT, TasksFiltersEnum]> = [
          ['status', TasksFiltersEnum.TaskStatus],
          ['assignee', TasksFiltersEnum.Assignee],
          ['priority', TasksFiltersEnum.TaskPriority],
          ['is_in_queue', TasksFiltersEnum.IsInQueue],
        ];

        const shouldRefetch = filterKeys.some(
          ([field, filterEnum]) => value[field] !== undefined && filters.filters[filterEnum]?.selectedOptions.length,
        );

        if (shouldRefetch) {
          debounce(() => {
            dispatch(
              TasksActions.getTasksByFilters({
                skip: 0,
                take: tasks.data.length,
                filterChanged: true,
                isFetching: true,
              }),
            );
          }, 500);
        }
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static updateMultipleTaskPriority(taskIds: string[], newPriority: PriorityEnum) {
    return (dispatch, getState: GetStateFunction) => {
      const { tasks, filters } = getState().tasks;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            data: prev.tasks.data.map((task) => {
              if (taskIds.includes(task.id)) {
                return {
                  ...task,
                  priority: newPriority,
                };
              }

              return task;
            }),
          },
        })),
      );

      if (filters.filters[TasksFiltersEnum.TaskPriority].selectedOptions.length) {
        debounce(() => {
          dispatch(
            TasksActions.getTasksByFilters({
              skip: 0,
              take: tasks.data.length,
              filterChanged: true,
              isFetching: true,
            }),
          );
        }, 500);
      }
    };
  }

  static assignUserToTask(taskId: string, user: UserShortModel, slotId: string, responsibilityId: string) {
    return async (dispatch) => {
      try {
        await TaskAssigmentService.assignUser(user.id, slotId);

        const assignee = {
          slot_id: slotId,
          task_responsibility_id: responsibilityId,
          taskAssignment: {
            user: {
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
              avatar_image_url: user.avatar_image_url,
            },
          },
        };

        const task_slots = {
          id: slotId,
          task_assignment_id: user.id,
          task_responsibility_id: responsibilityId,
        };

        dispatch(
          TasksActions.updateTask(taskId, {
            assignee: [assignee],
            task_slots: [task_slots],
          }),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static unassignUserFromTask(taskId: string, user: AssignmentUser, slotId: string) {
    return async (dispatch) => {
      try {
        await TaskAssigmentService.unassignUser(slotId);

        const task_slots = {
          id: slotId,
          task_responsibility_id: user.task_responsibility_id,
        };

        dispatch(
          TasksActions.updateTask(taskId, {
            assignee: [user],
            task_slots: [task_slots],
          }),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static copyTaskLink(taskId: string) {
    return async () => {
      const { origin } = window.location;
      const link = `${origin}/task/${taskId}`;

      await navigator.clipboard.writeText(link);

      notify.success('The link is copied');
    };
  }

  static onFilterChange({
    id,
    newValue,
    fieldName,
    value = [],
    isChecked = false,
    chipsSelector = false,
    gridApi,
  }: OnFilterChangeArgs) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().tasks;

      const isFilterField = fieldName in filters.filters;
      const isQueryField = fieldName in filters.queries;

      const updatedFilters = { ...filters };

      if (isQueryField && !chipsSelector) {
        updatedFilters.queries = {
          ...filters.queries,
          [fieldName]: value,
        };
      }

      const shouldSkipFilterUpdate =
        (fieldName === TasksFiltersEnum.AssigneeDepartment || fieldName === TasksFiltersEnum.AssigneePosition) &&
        typeof value === 'string';

      if (isFilterField && !shouldSkipFilterUpdate) {
        if (chipsSelector && newValue) {
          if ((newValue as IdName).id === ALL && isChecked) {
            updatedFilters.filters = {
              ...filters.filters,
              [fieldName]: {
                ...filters.filters[fieldName],
                value: [{ id: ALL, name: ALL }],
              },
            };
          } else if ((newValue as IdName).id === NO_POSITIONS && isChecked) {
            updatedFilters.filters = {
              ...filters.filters,
              [fieldName]: {
                ...filters.filters[fieldName],
                value: [{ id: NO_POSITIONS, name: NO_POSITIONS }],
              },
            };
          } else if ((newValue as IdName).id !== NO_POSITIONS && (newValue as IdName).id !== ALL && isChecked) {
            const filteredValues = (value as IdName[]).filter((i) => i.id !== NO_POSITIONS && i.id !== ALL);
            updatedFilters.filters = {
              ...filters.filters,
              [fieldName]: {
                ...filters.filters[fieldName],
                value: filteredValues,
              },
            };
          } else {
            updatedFilters.filters = {
              ...filters.filters,
              [fieldName]: {
                ...filters.filters[fieldName],
                value,
              },
            };
          }
        } else {
          const currentSelectedOptions = filters.filters[fieldName].selectedOptions || [];
          const updatedSelectedOptions = currentSelectedOptions.includes(id)
            ? currentSelectedOptions.filter((option) => option !== id)
            : [...currentSelectedOptions, id];

          updatedFilters.filters = {
            ...filters.filters,
            [fieldName]: {
              ...filters.filters[fieldName],
              value,
              selectedOptions: updatedSelectedOptions,
            },
          };
        }
      }

      dispatch(TasksActions.refreshTasksByFilters(updatedFilters, gridApi));
    };
  }

  static onReasonForFailureFilterChange({
    id,
    name,
    fieldName,
    value = [],
    gridApi,
    isQueryField = false,
  }: OnReasonForFailureFilterChangeArgs) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().tasks;

      const updatedFilters = { ...filters };

      if (isQueryField && fieldName in filters.queries) {
        updatedFilters.queries = {
          ...filters.queries,
          [fieldName]: value,
        };
      }

      if (fieldName in filters.filters && !isQueryField) {
        const currentSelectedOptions = filters.filters[fieldName]?.selectedOptions || [];
        let updatedSelectedOptions;

        if (id === SELECT_ALL) {
          updatedSelectedOptions = currentSelectedOptions.some((option) => option.id === SELECT_ALL)
            ? []
            : [{ id: SELECT_ALL, name: SELECT_ALL }];
        } else {
          updatedSelectedOptions = currentSelectedOptions.some((option) => option.id === id)
            ? currentSelectedOptions.filter((option) => option.id !== id)
            : [...currentSelectedOptions.filter((option) => option.id !== SELECT_ALL), { id, name }];
        }

        updatedFilters.filters = {
          ...filters.filters,
          [fieldName]: {
            ...filters.filters[fieldName],
            value,
            selectedOptions: updatedSelectedOptions,
          },
        };
      }

      dispatch(TasksActions.refreshTasksByFilters(updatedFilters, gridApi));
    };
  }

  static onAssigneeOrVendorFilterChange({ id, fieldName, value = [], gridApi, name }: OnAssigneeOrVendorFilterChangeArgs) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().tasks;

      const isFilterField = fieldName in filters.filters;

      const updatedFilters = { ...filters };

      if (isFilterField) {
        const currentSelectedOptions = filters.filters[fieldName]?.selectedOptions || [];
        let updatedSelectedOptions;

        if (id === SELECT_ALL) {
          updatedSelectedOptions = currentSelectedOptions.some((option) => option.id === SELECT_ALL)
            ? []
            : [{ id: SELECT_ALL, name: SELECT_ALL }];
        } else {
          updatedSelectedOptions = currentSelectedOptions.some((option) => option.id === id)
            ? currentSelectedOptions.filter((option) => option.id !== id)
            : [...currentSelectedOptions.filter((option) => option.id !== SELECT_ALL), { id, name }];
        }

        updatedFilters.filters = {
          ...filters.filters,
          [fieldName]: {
            ...filters.filters[fieldName],
            value,
            selectedOptions: updatedSelectedOptions,
          },
        };
      }

      dispatch(TasksActions.refreshTasksByFilters(updatedFilters, gridApi));
    };
  }

  static refreshTasksByFilters(updatedFilters: TasksFiltersT, gridApi: GridApi<any> | undefined) {
    return async (dispatch, getState: GetStateFunction) => {
      const { columnOrder, columnSizes } = getState().tasks;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: updatedFilters,
          activeFilters: getActiveFilters(generateRequestBody(updatedFilters, columnOrder, columnSizes)),
          selectedTasks: [],
          lastSelectedTaskId: '',
          editTaskId: '',
          bulkAssignTaskIds: [],
          bulkAssignSlotIds: [],
        })),
      );

      debounce(async () => {
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(updatedFilters, fields, columnSizes);

        await UserService.setTableFilters(body);

        await dispatch(TasksActions.getTasksByFilters({ isFetching: true, filterChanged: true }));

        if (gridApi) {
          if (!gridApi || gridApi.isDestroyed()) return;
          gridApi.onFilterChanged();
        }
      }, 500);
    };
  }

  static setFilterOptions(fieldName: TasksFiltersEnum, options: IdName[]) {
    return (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().tasks;

      const currentFilter = filters?.filters[fieldName] || {};

      dispatch(
        stateController.setState({
          filters: {
            ...filters,
            filters: {
              ...filters.filters,
              [fieldName]: {
                ...currentFilter,
                options,
              },
            },
          },
        }),
      );
    };
  }

  static openTaskInNewTab(taskId: string) {
    return () => {
      const { origin } = window.location;
      const link = `${origin}/task/${taskId}`;

      window.open(link, '_blank');
    };
  }

  static openRootOrMainProductionInNewTab(productionId: string | null) {
    return () => {
      if (!productionId) return;

      const { origin } = window.location;
      const link = `${origin}/production-workflow/${productionId}`;

      window.open(link, '_blank');
    };
  }

  static openManageTaskPriorityModal(productionId: string, taskId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { data } = getState().tasks.tasks;

      const task = data.find((t) => t.id === taskId);
      const isProcessingGuard = task?.locked;

      if (isProcessingGuard) {
        dispatch(PermissionGuardActions.openModal(isProcessingGuard));

        return;
      }

      try {
        const production = await ProductionWorkflowService.getProductionWorkflowInfo(productionId);

        if (production) {
          dispatch(ManageTaskPriorityModalActions.openModal({ production, taskId }));
        }
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  // ====== Edit basic reward modal =====================================================================================================

  public static openEditBasicRewardModal(taskId: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isEditBasicRewardModalOpened: true,
          editTaskId: taskId,
        })),
      );
    };
  }

  public static closeEditBasicRewardModal() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isEditBasicRewardModalOpened: false,
          editTaskId: '',
        })),
      );
    };
  }

  // ====== Multiselect actions ====================================================================================================

  public static manageSelectOrDeselectAllTasks = ({ resetAll }: ManageSelectOrDeselectAllTasksArgs) => {
    return (dispatch, getState: () => AppState) => {
      const { data } = getState().tasks.tasks;
      const { selectedTasks } = getState().tasks;
      if (resetAll || selectedTasks.length) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedTasks: [],
            bulkAssignTaskIds: [],
            lastSelectedTaskId: '',
          })),
        );
        return;
      }

      const tasks = getAllTasksOnTheScreen(data);

      const updateAssignTaskIds = tasks.map((task) => task.id);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedTasks: tasks,
          bulkAssignTaskIds: updateAssignTaskIds,
        })),
      );
    };
  };

  public static handleSelectDeselectTasks = (task: TaskTableStateT, event: MouseEvent) => {
    return (dispatch, getState: () => AppState) => {
      const { selectedTasks, tasks, bulkAssignTaskIds, lastSelectedTaskId } = getState().tasks;

      const isShiftPressed = event?.shiftKey;
      const allTasks = tasks.data;

      const selectedIds = new Set(selectedTasks.map((t) => t.id));
      const assignIds = new Set(bulkAssignTaskIds);

      if (isShiftPressed && lastSelectedTaskId) {
        const lastIndex = allTasks.findIndex((t) => t.id === lastSelectedTaskId);
        const currentIndex = allTasks.findIndex((t) => t.id === task.id);

        if (lastIndex !== -1 && currentIndex !== -1) {
          const [start, end] = [lastIndex, currentIndex].sort((a, b) => a - b);
          const rangeTasks = allTasks.slice(start, end + 1);

          const rangeTaskIds = new Set(rangeTasks.map((t) => t.id));
          const isSelected = selectedIds.has(task.id);

          if (isSelected) {
            rangeTaskIds.forEach((id) => {
              selectedIds.delete(id);
              assignIds.delete(id);
            });
          } else {
            rangeTasks.forEach((rangeTask) => {
              selectedIds.add(rangeTask.id);
              assignIds.add(rangeTask.id);
            });
          }

          dispatch(TasksActions.updatedSelectedTasksState({ allTasks, assignIds, selectedIds, lastSelectedTaskId: task.id }));

          return;
        }
      }

      if (selectedIds.has(task.id)) {
        selectedIds.delete(task.id);
        assignIds.delete(task.id);
      } else {
        selectedIds.add(task.id);
        assignIds.add(task.id);
      }

      dispatch(TasksActions.updatedSelectedTasksState({ allTasks, assignIds, selectedIds, lastSelectedTaskId: task.id }));
    };
  };

  public static updatedSelectedTasksState = ({
    allTasks,
    selectedIds,
    assignIds,
    lastSelectedTaskId,
  }: updatedSelectedTasksStateArgs) => {
    return (dispatch) => {
      const updatedSelectedTasks = allTasks
        .filter((t) => selectedIds.has(t.id))
        .map((t) => ({
          id: t.id,
          slots: t.task_slots.map((slot) => ({
            slot_id: slot.id,
            task_responsibility_id: slot.task_responsibility_id,
          })),
          departmentIds: t.department_ids,
          status: t.status,
          locked: t.locked,
          is_in_queue: t.is_in_queue,
          production_status: t.production_status,
          is_reporting_period_closed: t.is_reporting_period_closed,
        }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedTasks: updatedSelectedTasks,
          bulkAssignTaskIds: Array.from(assignIds),
          lastSelectedTaskId,
        })),
      );
    };
  };

  // ====== Bulk actions assign user ====================================================================================================

  public static initPerformersForSingleTaskSlotAssignment() {
    return async (dispatch) => {
      try {
        const users = (
          await UserService.getAllUsers({
            skip: 0,
            take: PAGE_SIZE,
            status: [UserStatusEnum.Active],
          })
        ).data;
        dispatch(stateController.setState({ users }));
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  public static bulkAssignMultipleOrSingleUserToTasks(responsibleId?: string) {
    return async (dispatch, getState: () => AppState) => {
      const { bulkAssignSelectedPerformers, selectedTasks } = getState().tasks;

      const performerId = getResponsibleId(responsibleId);

      const assignToManyTasksBody = responsibleId
        ? prepareRequestBodyForSingleBulkAssignUsers(performerId, selectedTasks)
        : prepareRequestBodyForMultipleBulkAssignUsers(bulkAssignSelectedPerformers, selectedTasks);

      if (assignToManyTasksBody.length === 0) return;

      const allTaskSlotIds = collectAllTaskSlotIds(assignToManyTasksBody);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          bulkAssignSlotIds: allTaskSlotIds,
        })),
      );

      try {
        await TaskTableService.bulkAssignUsersToTasks(assignToManyTasksBody);
        if (selectedTasks.length) {
          dispatch(TasksLaunchingProgressActions.showModalAndSetAssignUsersTasksCount(selectedTasks.length));
        }
      } catch (error) {
        notify.error(error.message);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignSlotIds: [],
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedTasks: [],
            lastSelectedTaskId: '',
            didFiltersChange: false,
            bulkAssignSelectedPerformers: {},
            bulkAssignPerformer: {
              isLoading: {},
              value: {},
              options: [],
            },
          })),
        );
      }
    };
  }

  static openAssignUsersModal(value: boolean) {
    return (dispatch) => {
      dispatch(stateController.setState({ bulkAssignIsAssignUsersModalOpened: value }));
    };
  }

  static onChangeAssignmentSlotModalValue(slotIndex: number, value: PerformerItemT) {
    return (dispatch, getState: () => AppState) => {
      const { bulkAssignSelectedPerformers } = getState().tasks;

      const transformedId = value.id === UNASSIGNED ? null : value.id;

      const updatedSlots = {
        ...bulkAssignSelectedPerformers,
        [slotIndex]: transformedId,
      };

      const assignedIds = Object.values(updatedSlots).filter((id) => Boolean(id));

      const hasDuplicates = new Set(assignedIds).size !== assignedIds.length;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          bulkAssignSelectedPerformers: updatedSlots,
          bulkAssignPerformer: {
            ...prev.bulkAssignPerformer,
            value: {
              ...prev.bulkAssignPerformer.value,
              [slotIndex]: value,
            },
          },
          bulkAssignIsInvalidSlotAssignment: hasDuplicates,
        })),
      );
    };
  }

  public static initPerformersForMultiTaskSlotAssignment(slotIndex: number) {
    return async (dispatch, getState: GetStateFunction) => {
      const { options } = getState().tasks.bulkAssignPerformer;
      if (options[slotIndex] && Object.keys(options[slotIndex]).length) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignPerformer: {
              ...prev.bulkAssignPerformer,
              isLoading: {
                ...prev.bulkAssignPerformer.isLoading,
                [slotIndex]: true,
              },
            },
          })),
        );

        const { data, meta } = await UserService.getAllUsers({
          skip: 0,
          take: PAGE_SIZE,
          status: [UserStatusEnum.Active],
        });

        const newOptions = data.map((performer) => ({
          id: performer.id,
          name: `${performer.first_name} ${performer.last_name}`.trim(),
          avatar: performer.avatar_image_url,
          lastName: performer.last_name,
          firstName: performer.first_name,
        }));

        const newOptionsWitUnassignOption = [unassignedOption, ...newOptions];

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignPaginationPerformers: {
              ...prev.bulkAssignPaginationPerformers,
              [slotIndex]: {
                total: meta.total,
                currentPage: meta.currentPage,
                perPage: meta.perPage,
                lastPage: meta.lastPage,
                next: meta.next,
              },
            },
            bulkAssignPerformer: {
              ...prev.bulkAssignPerformer,
              options: {
                ...prev.bulkAssignPerformer.options,
                [slotIndex]: newOptionsWitUnassignOption,
              },
              isLoading: {
                ...prev.bulkAssignPerformer.isLoading,
                [slotIndex]: false,
              },
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignPerformer: {
              ...prev.bulkAssignPerformer,
              isLoading: {
                ...prev.bulkAssignPerformer.isLoading,
                [slotIndex]: false,
              },
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchPerformersForMultiTaskSlotAssignment(
    slotIndex: number,
    value: string = '',
    isScroll: boolean = false,
  ) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignPaginationPerformers: {
              ...prev.bulkAssignPaginationPerformers,
              [slotIndex]: {
                ...prev.bulkAssignPaginationPerformers[slotIndex],
                next: 0,
              },
            },
          })),
        );
      }
      const { bulkAssignPaginationPerformers } = getState().tasks;

      if (isScroll) {
        const { currentPage, lastPage } = bulkAssignPaginationPerformers[slotIndex];
        if (currentPage >= lastPage) return;
      }

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignPerformer: {
              ...prev.bulkAssignPerformer,
              isLoading: {
                ...prev.bulkAssignPerformer.isLoading,
                [slotIndex]: true,
              },
            },
          })),
        );
        debounce(async () => {
          const { data, meta } = await UserService.getAllUsers({
            search: value.trim(),
            skip: bulkAssignPaginationPerformers[slotIndex].next,
            take: bulkAssignPaginationPerformers[slotIndex].perPage,
            status: [UserStatusEnum.Active],
          });

          const newOptions = data.map((performer) => ({
            id: performer.id,
            name: `${performer.first_name} ${performer.last_name}`.trim(),
            avatar: performer.avatar_image_url,
            lastName: performer.last_name,
            firstName: performer.first_name,
          }));

          dispatch(
            stateController.setState((prev) => {
              const currentOptions: PerformerItemT[] = prev.bulkAssignPerformer.options[slotIndex] || [];
              const unassignedUserOptionExists = currentOptions.some((option) => option.id === unassignedOption.id);

              // eslint-disable-next-line no-nested-ternary
              const updatedSlotOptions = isScroll
                ? [...currentOptions, ...newOptions]
                : unassignedUserOptionExists
                ? newOptions
                : [unassignedOption, ...newOptions];

              return {
                ...prev,
                bulkAssignPerformer: {
                  ...prev.bulkAssignPerformer,
                  options: {
                    ...prev.bulkAssignPerformer.options,
                    [slotIndex]: updatedSlotOptions,
                  },
                  isLoading: {
                    ...prev.bulkAssignPerformer.isLoading,
                    [slotIndex]: false,
                  },
                },
                bulkAssignPaginationPerformers: {
                  ...prev.bulkAssignPaginationPerformers,
                  [slotIndex]: {
                    total: meta.total,
                    currentPage: meta.currentPage,
                    perPage: meta.perPage,
                    lastPage: meta.lastPage,
                    next: meta.next,
                  },
                },
              };
            }),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignPerformer: {
              ...prev.bulkAssignPerformer,
              isLoading: {
                ...prev.bulkAssignPerformer.isLoading,
                [slotIndex]: false,
              },
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  static handleBulkAssignWebsocketResponse = (message: WebsocketResponseMessageForAssignmentT, event: WebsocketEvent) => {
    return (dispatch) => {
      if (!message || !event) {
        return;
      }

      const { task_id, slot_id, task_key, user } = message;

      const isAssignFinishedEvent = event === WebsocketEvent.BulkTaskAssignmentFinished;
      const isUnassignFinishedEvent = event === WebsocketEvent.BulkTaskUnAssignmentFinished;
      const isAssignFailedEvent = event === WebsocketEvent.BulkTaskAssignmentFailed;
      const isUnassignFailedEvent = event === WebsocketEvent.BulkTaskUnAssignmentFailed;

      if (isAssignFailedEvent || isUnassignFailedEvent) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignFailedTaskKeys: [...prev.bulkAssignFailedTaskKeys, task_key],
          })),
        );
      }
      if (isAssignFinishedEvent || isUnassignFinishedEvent) {
        setTimeout(
          () => dispatch(TasksActions.replaceUserInTaskSlotFromWebsocket(task_id, user, slot_id, isUnassignFinishedEvent)),
          200,
        );
      }

      dispatch(
        TasksActions.handleTasksIdsUpdateFromWebsocket({
          value: [task_id],
          removeLaunchedTaskIds: true,
        }),
      );

      dispatch(
        TasksActions.handleSlotIdsUpdateFromWebsocket({
          value: [slot_id],
          removeLaunchedSlotIds: true,
        }),
      );

      dispatch(TasksActions.handleNotificationAndProgressBulkAssignment());
    };
  };

  static handleTasksIdsUpdateFromWebsocket = ({ value, removeLaunchedTaskIds }: HandleTasksIdsUpdateFromWebsocketArgs) => {
    return (dispatch) => {
      if (removeLaunchedTaskIds) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignTaskIds: prev.bulkAssignTaskIds.filter((launchingId) => !value.includes(launchingId)),
          })),
        );
      }
    };
  };

  static handleSlotIdsUpdateFromWebsocket = ({ value, removeLaunchedSlotIds }: HandleSlotIdsUpdateFromWebsocketArgs) => {
    return (dispatch) => {
      if (removeLaunchedSlotIds) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignSlotIds: prev.bulkAssignSlotIds.filter((launchingId) => !value.includes(launchingId)),
          })),
        );
      }
    };
  };

  static replaceUserInTaskSlotFromWebsocket(
    taskId: string,
    user: FinishedAssignWebsocketResponseForTasksTableT['user'],
    slotId: string,
    isUnassignFinishedEvent: boolean,
  ) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            data: updateTasksFromWsResponse({
              id: taskId,
              slotId,
              user,
              tasks: prev.tasks,
              isUnassignFinishedEvent,
            }).data,
          },
        })),
      );
    };
  }

  static handleNotificationAndProgressBulkAssignment = () => {
    return (dispatch, getState: () => AppState) => {
      const { bulkAssignTaskIds, bulkAssignSlotIds, bulkAssignFailedTaskKeys, filters, tasks } = getState().tasks;

      if (bulkAssignSlotIds.length === 0) {
        if (bulkAssignFailedTaskKeys.length === 0) {
          notify.success('Successfully updated');
          dispatch(TasksLaunchingProgressActions.hideModal());

          if (filters.filters[TasksFiltersEnum.Assignee].selectedOptions.length) {
            debounce(() => {
              dispatch(
                TasksActions.getTasksByFilters({ skip: 0, take: tasks.data.length, filterChanged: true, isFetching: true }),
              );
            }, 500);
          }
        } else {
          notify.error(
            <div>
              <p style={{ whiteSpace: 'nowrap' }}>Users weren&apos;t assigned to the following tasks:</p>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {bulkAssignFailedTaskKeys.map((el) => (
                  <li key={el}>
                    <strong>{el}</strong>
                  </li>
                ))}
              </ul>
            </div>,
          );
          dispatch(TasksLaunchingProgressActions.hideModal());
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              bulkAssignFailedTaskKeys: [],
            })),
          );
        }
      } else {
        dispatch(TasksLaunchingProgressActions.setTasksAssignUsersCount(bulkAssignTaskIds.length));
      }
    };
  };

  // ====== Bulk actions change status task ====================================================================================================

  static openConfirmationTaskStatusChangeModal(value: boolean, bulkStatusChangeSelectedStatus: TaskStatusEnum | string) {
    return (dispatch) => {
      dispatch(stateController.setState({ bulkStatusChangeIsConfirmationModalOpened: value, bulkStatusChangeSelectedStatus }));
    };
  }

  static openSuccessTaskStatusChangeModal(bulkStatusChangeId: string, value: boolean) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => {
          const newBulkSCIsSuccessModalOpened = {
            ...prev.bulkStatusChangeIsSuccessModalOpened,
            [bulkStatusChangeId]: value,
          };

          return {
            ...prev,
            bulkStatusChangeIsSuccessModalOpened: newBulkSCIsSuccessModalOpened,
            bulkStatusChangeModalIdForData: value ? bulkStatusChangeId : '',
          };
        }),
      );
    };
  }

  static openFailedTaskStatusChangeModal(bulkStatusChangeId: string, value: boolean) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => {
          const newBulkSCIsFailedModalOpened = {
            ...prev.bulkStatusChangeIsFailedModalOpened,
            [bulkStatusChangeId]: value,
          };

          return {
            ...prev,
            bulkStatusChangeIsFailedModalOpened: newBulkSCIsFailedModalOpened,
            bulkStatusChangeModalIdForData: value ? bulkStatusChangeId : '',
          };
        }),
      );
    };
  }

  static showStatusChangeProgressPopover(key: string) {
    return (dispatch, getState: () => AppState) => {
      const prevState = getState().tasks.bulkStatusChangeProgress[key];

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          bulkStatusChangeProgress: {
            ...prev.bulkStatusChangeProgress,
            [key]: {
              ...prevState,
              isShow: true,
            },
          },
        })),
      );
    };
  }

  static closeStatusChangeProgressPopover(key: string) {
    return (dispatch, getState: () => AppState) => {
      const prevState = getState().tasks.bulkStatusChangeProgress[key];

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          bulkStatusChangeProgress: {
            ...prev.bulkStatusChangeProgress,
            [key]: {
              ...prevState,
              isShow: false,
            },
          },
        })),
      );
    };
  }

  static applyBulkSCTasksRequest(status: TaskStatusEnum) {
    return async (dispatch, getState: () => AppState) => {
      const { selectedTasks } = getState().tasks;

      const allTaskIds = selectedTasks.map((task) => task.id);

      const bulkSCTasksBody = {
        status,
        taskIds: allTaskIds,
      };

      selectedTasks.forEach((task) => {
        dispatch(TasksActions.updateTaskLockedState(task.id, true));
      });

      try {
        debounce(async () => {
          await TaskTableService.bulkTasksStatusChange(bulkSCTasksBody);
        }, 100);
      } catch (error) {
        notify.error(error.message);

        selectedTasks.forEach((task) => {
          dispatch(TasksActions.updateTaskLockedState(task.id, false));
        });
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedTasks: [],
            lastSelectedTaskId: '',
            didFiltersChange: false,
          })),
        );
      }
    };
  }

  static handleBulkSCWsResponse = (message: WebsocketResponseMessageForTaskStatusT, event: WebsocketEvent) => {
    return (dispatch, getState: () => AppState) => {
      if (!message || !event || !message.task_id) {
        return;
      }

      const { status, task_id, bulkStatusChangeId, totalNumberOfTasksForProcessing, task, user_id } = message;

      const { user: currentUser } = getState().auth;

      const { selectedTasks } = getState().tasks;

      const bulkSCProgressState = getState().tasks.bulkStatusChangeProgress[bulkStatusChangeId] || {
        currentNumberOfTaskForProcessing: 0,
        totalNumberOfTasksForProcessing: 0,
        finishedTasks: 0,
        failedTasks: [],
      };

      const isStatusChangeFinishedEvent = event === WebsocketEvent.BulkStatusChangeTaskUpdateFinished;
      const isStatusChangeFailedEvent = event === WebsocketEvent.BulkStatusChangeTaskUpdateFailed;

      if (isStatusChangeFailedEvent) {
        dispatch(TasksActions.updateTaskLockedState(task_id, false));

        dispatch(
          TasksActions.updateBulkSCProgressById({
            updates: {
              currentNumberOfTaskForProcessing: bulkSCProgressState.currentNumberOfTaskForProcessing + 1,
              failedTasks: [...bulkSCProgressState.failedTasks, { taskId: task_id, taskKey: task.task_key }],
              totalNumberOfTasksForProcessing,
            },
            bulkSCProgressState,
            bulkStatusChangeId,
          }),
        );
      }

      if (isStatusChangeFinishedEvent) {
        dispatch(
          TasksActions.replaceTaskInfoFromWs({
            status,
            taskId: task_id,
            is_in_queue: task.is_in_queue,
            locked: task.locked,
            bulkStatusChangeId,
            is_reporting_period_closed: task.is_reporting_period_closed,
          }),
        );

        dispatch(
          TasksActions.updateBulkSCProgressById({
            updates: {
              currentNumberOfTaskForProcessing: bulkSCProgressState.currentNumberOfTaskForProcessing + 1,
              finishedTasks: bulkSCProgressState.finishedTasks + 1,
              totalNumberOfTasksForProcessing,
            },
            bulkSCProgressState,
            bulkStatusChangeId,
          }),
        );
      }

      const existingState = getState().tasks.bulkStatusChangeProgress[bulkStatusChangeId];

      if (bulkStatusChangeId && !existingState?.isShow && user_id === currentUser?.id) {
        dispatch(TasksActions.showStatusChangeProgressPopover(bulkStatusChangeId));
      }

      if (selectedTasks.length > 0) {
        const hasChanges = selectedTasks.some((t) => t.id === task_id);

        if (hasChanges) {
          const updatedSelectedTasks = selectedTasks.map((t) => {
            return t.id === task_id
              ? {
                  ...t,
                  locked: task.locked,
                  status: task.status,
                  is_in_queue: task.is_in_queue,
                  is_reporting_period_closed: task.is_reporting_period_closed,
                  id: task.id,
                }
              : t;
          });

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              selectedTasks: updatedSelectedTasks,
            })),
          );
        }
      }
    };
  };

  static handleBulkSCViewerWsResponse = (message: WebsocketStartEndMessageForTaskStatusT, event: WebsocketEvent) => {
    return (dispatch, getState: () => AppState) => {
      const { user_id, bulkStatusChangeId } = message;
      const { user: currentUser } = getState().auth;
      const { tasks, filters } = getState().tasks;

      const activeStatusFilter = filters.filters[TasksFiltersEnum.TaskStatus].selectedOptions.length;

      const isInitiatorUser = user_id === currentUser?.id;

      const isStartEvent = event === WebsocketEvent.BulkStatusChangeStarted;
      const isFinishEvent = event === WebsocketEvent.BulkStatusChangeFinished;

      if (isFinishEvent && 'task_ids' in message) {
        // !Updates deferred tasks that failed to sync due to user interactions (reload, filtering, etc.)
        // !after bulk status change.
        dispatch(TasksActions.updateAfterProcessingDeferredTasks(bulkStatusChangeId));
      }

      if (isFinishEvent && activeStatusFilter) {
        debounce(() => {
          dispatch(TasksActions.getTasksByFilters({ skip: 0, take: tasks.data.length, filterChanged: true, isFetching: true }));
        }, 500);
      }

      if (isStartEvent && !isInitiatorUser && 'tasksIds' in message) {
        message.tasksIds.forEach((taskId) => {
          dispatch(TasksActions.updateTaskLockedState(taskId, true));
        });
      }
    };
  };

  static updateAfterProcessingDeferredTasks = (bulkStatusChangeId: string) => {
    return (dispatch, getState: () => AppState) => {
      const { bulkStatusChangeDeferredTasks, tasks } = getState().tasks;
      const { data: tasksData } = tasks;

      if (!bulkStatusChangeDeferredTasks[bulkStatusChangeId]?.length) return;

      dispatch(
        stateController.setState((prev) => {
          const updatedTasksData = bulkStatusChangeDeferredTasks[bulkStatusChangeId].reduce(
            (acc, { taskId, status, is_in_queue, locked }) => {
              const task = acc.find((t) => t.id === taskId);

              if (!task || task.locked === false) {
                return acc;
              }

              return updateTasksFromStatusChangeWsResponse({
                id: taskId,
                tasks: { data: acc },
                status,
                is_in_queue,
                locked,
              }).data;
            },
            tasksData,
          );

          const updatedBulkSCDeferredTasks = { ...prev.bulkStatusChangeDeferredTasks };
          delete updatedBulkSCDeferredTasks[bulkStatusChangeId];

          return {
            ...prev,
            bulkStatusChangeDeferredTasks: updatedBulkSCDeferredTasks,
            tasks: {
              ...prev.tasks,
              data: updatedTasksData,
            },
          };
        }),
      );
    };
  };

  static updateBulkSCProgressById({ updates, bulkSCProgressState, bulkStatusChangeId }: UpdateBulkSCProgressArgs) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          bulkStatusChangeProgress: {
            ...prev.bulkStatusChangeProgress,
            [bulkStatusChangeId]: {
              ...bulkSCProgressState,
              ...updates,
            },
          },
        })),
      );
    };
  }

  static clearBulkSCProgressById(bulkStatusChangeId: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          bulkStatusChangeProgress: Object.fromEntries(
            Object.entries(prev.bulkStatusChangeProgress).filter(([key]) => key !== bulkStatusChangeId),
          ),
          bulkStatusChangeIsSuccessModalOpened: Object.fromEntries(
            Object.entries(prev.bulkStatusChangeIsSuccessModalOpened).filter(([key]) => key !== bulkStatusChangeId),
          ),
          bulkStatusChangeIsFailedModalOpened: Object.fromEntries(
            Object.entries(prev.bulkStatusChangeIsFailedModalOpened).filter(([key]) => key !== bulkStatusChangeId),
          ),
          bulkStatusChangeModalIdForData: '',
        })),
      );
    };
  }

  static updateTaskLockedState(taskId: string, locked: boolean) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            data: prev.tasks.data.map((taskItem) => (taskItem.id === taskId ? { ...taskItem, locked } : taskItem)),
          },
        })),
      );
    };
  }

  static replaceTaskInfoFromWs({
    status,
    taskId,
    is_in_queue,
    locked,
    bulkStatusChangeId,
    is_reporting_period_closed,
  }: ReplaceTaskInfoFromWsArgs) {
    return (dispatch, getState: () => AppState) => {
      const {
        tasks: { data },
      } = getState().tasks;

      const taskExists = data.some((task) => task.id === taskId);

      if (!taskExists) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkStatusChangeDeferredTasks: {
              ...prev.bulkStatusChangeDeferredTasks,
              [bulkStatusChangeId]: [
                ...(prev.bulkStatusChangeDeferredTasks[bulkStatusChangeId] || []),
                { status, taskId, is_in_queue, locked },
              ],
            },
          })),
        );

        return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            data: updateTasksFromStatusChangeWsResponse({
              id: taskId,
              tasks: { data },
              status,
              is_in_queue,
              locked,
              is_reporting_period_closed,
            }).data,
          },
        })),
      );
    };
  }

  static getBulkStatusChangeCounters() {
    return async (dispatch) => {
      try {
        const response = await TaskTableService.getBulkStatusChangeCounters();

        if (!response || response.length === 0) {
          return;
        }
        const mappedProgress = mapBulkStatusChangeCountersToState(response);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkStatusChangeProgress: {
              ...prev.bulkStatusChangeProgress,
              ...mappedProgress,
            },
          })),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  // ====== WS handlers ==================================================================================================================

  static handleWebsocketResponse = (message: WebsocketResponseMessageForTaskTableT, event: WebsocketEvent) => {
    return (dispatch) => {
      const eventHandlers = {
        [TaskTableWebsocketEventCategory.BULK_ASSIGN]: TasksActions.handleBulkAssignWebsocketResponse,
        [TaskTableWebsocketEventCategory.BULK_STATUS_CHANGE]: TasksActions.handleBulkSCWsResponse,
        [TaskTableWebsocketEventCategory.BULK_STATUS_CHANGE_VIEWER]: TasksActions.handleBulkSCViewerWsResponse,
      };

      const eventCategoryMapping: Partial<Record<WebsocketEvent, keyof typeof eventHandlers>> = {
        [WebsocketEvent.BulkTaskAssignmentFinished]: TaskTableWebsocketEventCategory.BULK_ASSIGN,
        [WebsocketEvent.BulkTaskUnAssignmentFinished]: TaskTableWebsocketEventCategory.BULK_ASSIGN,
        [WebsocketEvent.BulkTaskAssignmentFailed]: TaskTableWebsocketEventCategory.BULK_ASSIGN,
        [WebsocketEvent.BulkTaskUnAssignmentFailed]: TaskTableWebsocketEventCategory.BULK_ASSIGN,
        [WebsocketEvent.BulkStatusChangeTaskUpdateFinished]: TaskTableWebsocketEventCategory.BULK_STATUS_CHANGE,
        [WebsocketEvent.BulkStatusChangeTaskUpdateFailed]: TaskTableWebsocketEventCategory.BULK_STATUS_CHANGE,
        [WebsocketEvent.BulkStatusChangeStarted]: TaskTableWebsocketEventCategory.BULK_STATUS_CHANGE_VIEWER,
        [WebsocketEvent.BulkStatusChangeFinished]: TaskTableWebsocketEventCategory.BULK_STATUS_CHANGE_VIEWER,
      };

      const handlerName = eventCategoryMapping[event];

      switch (handlerName) {
        case TaskTableWebsocketEventCategory.BULK_ASSIGN:
          dispatch(eventHandlers[handlerName](message as WebsocketResponseMessageForAssignmentT, event));
          break;
        case TaskTableWebsocketEventCategory.BULK_STATUS_CHANGE:
          dispatch(eventHandlers[handlerName](message as WebsocketResponseMessageForTaskStatusT, event));
          break;
        case TaskTableWebsocketEventCategory.BULK_STATUS_CHANGE_VIEWER:
          dispatch(eventHandlers[handlerName](message as WebsocketStartEndMessageForTaskStatusT, event));
          break;

        default:
      }
    };
  };
}

export class TasksSelectors {
  public static isSelectedTask = (state: AppState, taskId: string): boolean => {
    return state.tasks.selectedTasks.some((selectedTask) => selectedTask.id === taskId);
  };

  public static canMassAssignUser = (state: AppState): boolean => {
    const { selectedTasks } = state.tasks;

    const hasSelectedTasks = selectedTasks.length > 0;

    const areAllSlotsEqual =
      selectedTasks[0]?.slots.length > 0 && selectedTasks.every((task) => task.slots.length === selectedTasks[0]?.slots.length);

    const areNotLocked = selectedTasks.every((task) => !task.locked);

    return hasSelectedTasks && areAllSlotsEqual && areNotLocked;
  };

  public static canSingleOrMultiAssignUser = (state: AppState): boolean => {
    const { selectedTasks } = state.tasks;
    return selectedTasks.some((task) => task.slots.length >= 2);
  };

  public static canMassChangeStatus = (state: AppState, isPermittedManageInQueueState: boolean): boolean => {
    const { selectedTasks } = state.tasks;

    if (selectedTasks.length === 0) return false;

    const hasSameStatus = selectedTasks.every((task) => task.status === selectedTasks[0]?.status);

    const canIgnoreQueueRestriction = isPermittedManageInQueueState || selectedTasks.every((task) => !task.is_in_queue);

    const hasValidProductionStatus = selectedTasks.every(
      (task) => task.production_status !== ProductionStatusEnum.Done && task.production_status !== ProductionStatusEnum.Canceled,
    );

    const areNotLocked = selectedTasks.every((task) => !task.locked);

    return hasSameStatus && canIgnoreQueueRestriction && hasValidProductionStatus && areNotLocked;
  };
}

export const tasksReducer = stateController.getReducer();
