import { Stack } from '@mui/material';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import StatusSelector from 'components/status-selector/status-selector';
import { PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';
import { TaskStatusEnum } from 'types/status-enums';
import { ICellRendererParams } from 'ag-grid-community';
import { TaskTableModel } from 'services/task-table.model';
import ProcessingIndicator from 'components/ui-new/processing-indicator/processing-indicator';

type Props = ICellRendererParams<TaskTableModel>;

const TaskStatusCell = ({ data }: Props) => {
  if (!data) return null;

  const dispatch = useDispatch();

  const taskDepartments = data?.department_ids.map((i: string) => ({ id: i })) || [];
  const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];
  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webTaskEditLevel, accessLevels, taskDepartments),
  );

  const isAllowedUndoChangingTaskStatus = useSelector((state: AppState) => state.settings.isAllowedUndoChangingTaskStatus);
  const isAllowedChangingTaskStatus = isAllowedUndoChangingTaskStatus && !data.is_reporting_period_closed;

  const task = useSelector((state: AppState) => state.tasks.tasks.data.find((t) => t.id === data.id));
  const isProcessingGuard = task?.locked;

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
      {isProcessingGuard ? (
        <ProcessingIndicator isProcessingGuard={isProcessingGuard} />
      ) : (
        <StatusSelector
          optionsFor="task"
          status={data.status}
          isEditPermitted={isPermitted}
          productionStatus={data?.production_status}
          isBlockedLaunch={data.status === TaskStatusEnum.Done && !isAllowedChangingTaskStatus}
          onSelect={async (selectedStatus) => {
            dispatch(TasksActions.updateTask(data.id, { status: selectedStatus }));
          }}
        />
      )}
    </Stack>
  );
};

export default TaskStatusCell;
