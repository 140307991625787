import {
  VariantState,
  ProductState,
  RewardsState,
  PerformersModel,
  DescriptionState,
  UpdateTaskValueT,
  RelatedTasksState,
  ConfigurationState,
  EditNumberOfProducedItemsModalState,
  WebsocketResponseMessageForTaskDetailsT,
  TaskDetailsWebsocketEventCategory,
  WebsocketStartEndMessageForTaskStatusT,
} from 'pages/task/types';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { notify } from 'notifications';
import {
  ProductFileModel,
  FailReasonsModel,
  ProductPhotoModel,
  UpdateTaskRewardsBody,
  TaskResponsibilityModel,
} from 'services/production-task.model';
import {
  NoteHistoryItem,
  VariantHistoryItem,
  DeadlineHistoryItem,
  ExternalOrderNumberHistoryItem,
  ProductionWorkflowTagModel,
} from 'services/production-workflow.model';
import { StateController } from 'state-controller';
import { PriorityEnum } from 'types/priority-enums';
import { UserShortModel } from 'services/user.model';
import { FileItem, IdName } from 'types/common-types';
import { AppState, GetStateFunction } from 'redux/store';
import { TaskSlotService } from 'services/task-slot.service';
import { TaskAssigmentService } from 'services/task-assigment.service';
import { ProductionTaskService } from 'services/production-task.service';
import { TaskTimeTrackingService } from 'services/time-tracking.service';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { TaskResponsibilityService } from 'services/task-responsibility.service';
import { TaskResponsibilityUpdateRequest } from 'services/task-responsibility.model';
import { UpdateMultipleTaskAssignmentsRequestBody } from 'services/task-assigment.model';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { ItemType, BreadcrumbItem } from 'components/ui-new/breadcrumbs/components/helpers/helpers';
import {
  formatFailedBy,
  formatLaunchedBy,
  mapRewardsToRewardsState,
  mapFormattedAssignedUsers,
} from 'pages/task/helpers/mappers';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { WebsocketEvent } from 'types/common-enums';
import { WebsocketResponseMessageForTaskStatusT } from 'services/task-table.model';
import { t } from '../../setup-localization';
import FireMinusIcon from '../../icons/fire-minus';
import { MODALS } from '../../modules/root-modals/modals';
import { TaskFilesService } from '../../services/task-files.service';
import { ModalActions } from '../../modules/root-modals/root-modals.controller';
import { TaskResponsibilityCreateRequest } from '../../services/task-responsibility.model';
import { DeleteConfirmationOwnProps } from '../../modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { Actions as WorkflowTaskTemplateResponsibilityModalActions } from '../../modules/task-template-responsibility-modal/workflow-task-template-responsibility-modal.controller';

export type TaskState = {
  taskDepartments: IdName[];
  breadcrumbs: BreadcrumbItem[];
  fail_reasons: FailReasonsModel[];
  productMedia: ProductPhotoModel[];
  taskAttachments: ProductFileModel[];
  productAttachments: ProductFileModel[];
  taskResponsibilities: TaskResponsibilityModel[];
  id: string;
  name: string;
  locked: boolean;
  deadline: string;
  failedBy: string;
  launchedBy: string;
  created_at: string;
  started_at: string;
  fail_comment: string;
  productionId: string;
  dueDate: Date | null;
  status_changed_at: string;
  reporting_period_to: string;
  reporting_period_from: string;
  time_limit: number;
  total_paused_time: number;
  productionProgress: number;
  total_in_progress_time: number;
  total_in_progress_overtime: number;
  total_paused_out_of_work_time_range: number;
  isLoading: boolean;
  isUpdating: boolean;
  is_in_queue: boolean;
  is_reopened: boolean;
  isAdditional: boolean;
  isCalculatedPerItems: boolean;
  isReportingPeriodClosed: boolean;
  is_reporting_period_closed: boolean;
  is_root_workflow_completed: boolean;
  isShowReportingPeriodWarning: boolean;
  isProductionHasTaskInDoneStatus: boolean;
  status: TaskStatusEnum;
  priority: PriorityEnum;
  productionStatus: ProductionStatusEnum;
  rewards: RewardsState;
  product: ProductState;
  variant: VariantState;
  perfomers: PerformersModel;
  description: DescriptionState;
  relatedTasks: RelatedTasksState;
  configuration: ConfigurationState;
  editNumberOfProducedItemsModal: EditNumberOfProducedItemsModalState;
  orderInfo: {
    order_key: string;
    external_order_number: string;
  };
  history: {
    notes: NoteHistoryItem[];
    variantsHistory: VariantHistoryItem[];
    deadlineHistory: DeadlineHistoryItem[];
    externalOrderNumberHistory: ExternalOrderNumberHistoryItem[];
  };
  production_workflow: {
    title: string;
    started_at: string;
    deadline_at: string;
    production_key: string;
    tags: ProductionWorkflowTagModel[];
  };
};

const defaultState: TaskState = {
  breadcrumbs: [],
  productMedia: [],
  fail_reasons: [],
  taskAttachments: [],
  taskDepartments: [],
  productAttachments: [],
  taskResponsibilities: [],
  id: '',
  name: '',
  deadline: '',
  failedBy: '',
  locked: false,
  launchedBy: '',
  started_at: '',
  created_at: '',
  productionId: '',
  fail_comment: '',
  status_changed_at: '',
  reporting_period_to: '',
  reporting_period_from: '',
  time_limit: 0,
  total_paused_time: 0,
  productionProgress: 0,
  total_in_progress_time: 0,
  total_in_progress_overtime: 0,
  total_paused_out_of_work_time_range: 0,
  dueDate: null,
  isLoading: false,
  isUpdating: false,
  is_in_queue: false,
  is_reopened: false,
  isAdditional: false,
  isCalculatedPerItems: false,
  isReportingPeriodClosed: false,
  is_root_workflow_completed: false,
  is_reporting_period_closed: false,
  isShowReportingPeriodWarning: false,
  isProductionHasTaskInDoneStatus: false,
  status: TaskStatusEnum.To_Do,
  priority: PriorityEnum.Lowest,
  productionStatus: ProductionStatusEnum.To_Do,
  editNumberOfProducedItemsModal: {
    users: [],
    updatedAssignments: [],
    isLoading: false,
    isModalOpen: false,
  },
  perfomers: {
    users: {
      suggestedUsers: [],
      otherUsers: [],
    },
  },
  product: {
    id: '',
    type: '',
    name: '',
    version: '',
    sourceId: '',
    vendorName: '',
    product_configuration_details: '',
  },
  configuration: {
    id: '',
    sku: '',
    name: '',
  },
  variant: {
    id: '',
    sku: '',
    name: '',
    barcode: '',
  },
  description: {
    text: '',
    isEditing: false,
  },
  rewards: {
    bonuses: [],
    totalBonus: '0',
    basicReward: '0',
    totalReward: '0',
    itemsProduced: 0,
    basicRewardPrev: '0',
    assignmentRewards: [],
  },
  relatedTasks: {
    nextTasks: [],
    failedTasks: [],
    previousTasks: [],
  },
  orderInfo: {
    order_key: '',
    external_order_number: '',
  },
  history: {
    notes: [],
    deadlineHistory: [],
    variantsHistory: [],
    externalOrderNumberHistory: [],
  },
  production_workflow: {
    tags: [],
    title: '',
    started_at: '',
    deadline_at: '',
    production_key: '',
  },
};

const stateController = new StateController<TaskState>('TASK_IN_PROGRESS', defaultState);

export class Actions {
  public static init(id: string, spinner = true) {
    return async (dispatch) => {
      try {
        if (spinner) dispatch(stateController.setState({ isLoading: true }));

        const data = await ProductionTaskService.getTaskInfo(id);
        const productionTags = await ProductionWorkflowService.getProductionWorkflowTags(data.production_workflow_id);

        const breadcrumbsMapped = [
          {
            name: 'Production',
            type: 'production',
          },
          ...data.breadcrumbs.map((item) => ({
            ...item,
            isAllowedCopy: true,
            isHaveTooltip: true,
            isDisabled: item.type === ItemType.OrderKey && true,
          })),
        ] as BreadcrumbItem[];

        const previousItemsMapped = data.previous_items.map((item, index) => {
          if ('task' in item) {
            const { id: taskId, name, status, task_key } = item.task;
            return {
              id: taskId,
              name,
              status,
              type: 'task',
              taskKey: task_key,
            };
            // eslint-disable-next-line no-else-return
          } else if ('workflow' in item) {
            const { id: workflowId, workflow_name: name, status, product_name, configuration } = item.workflow;
            return {
              id: workflowId,
              name,
              status,
              type: 'workflow',
              configuration,
              product_name,
            };
          }
          return {
            id: index,
            isEmptySlot: true,
          };
        });

        const nextItemsMapped = data.next_items.map((item) => {
          if ('task' in item) {
            const { id: taskId, name, status, task_key } = item.task;
            return {
              name,
              status,
              id: taskId,
              type: 'task',
              taskKey: task_key,
            };
            // eslint-disable-next-line no-else-return
          } else if ('workflow' in item) {
            const { id: workflowId, workflow_name: name, status } = item.workflow;
            return {
              id: workflowId,
              name,
              status,
              type: 'workflow',
            };
          }
          return null;
        });

        const isShowReportingPeriodWarning =
          (data.is_reopened || Boolean(data.failed_by)) && dayjs().isAfter(dayjs(data.reporting_period_to));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            id: data.id,
            name: data.name,
            locked: data.locked,
            status: data.status,
            dueDate: data.due_date,
            priority: data.priority,
            deadline: data.deadline_at,
            created_at: data.created_at,
            started_at: data.started_at,
            time_limit: data.time_limit,
            isShowReportingPeriodWarning,
            is_in_queue: data.is_in_queue,
            is_reopened: data.is_reopened,
            breadcrumbs: breadcrumbsMapped,
            fail_reasons: data.fail_reasons,
            fail_comment: data.fail_comment,
            isAdditional: data.is_additional,
            productMedia: data.product.photos,
            taskAttachments: data.attachments,
            itemsProduced: data.items_produced,
            taskDepartments: data.departmets_all,
            productAttachments: data.product.files,
            productionId: data.production_workflow_id,
            status_changed_at: data.status_changed_at,
            total_paused_time: data.total_paused_time,
            reporting_period_to: data.reporting_period_to,
            failedBy: formatFailedBy(data.failed_by_info),
            launchedBy: formatLaunchedBy(data.launched_by),
            taskResponsibilities: data.taskResponsibilities,
            reporting_period_from: data.reporting_period_from,
            productionStatus: data.production_workflow.status,
            total_in_progress_time: data.total_in_progress_time,
            isCalculatedPerItems: data.reward_calculated_per_item,
            productionProgress: data.production_workflow.progress,
            isReportingPeriodClosed: data.is_reporting_period_closed,
            total_in_progress_overtime: data.total_in_progress_overtime,
            is_reporting_period_closed: data.is_reporting_period_closed,
            is_root_workflow_completed: data.is_root_workflow_completed,
            total_paused_out_of_work_time_range: data.total_paused_out_of_work_time_range,
            isProductionHasTaskInDoneStatus: data.is_production_has_task_in_done_status,
            description: {
              ...prev.description,
              text: data.description,
            },
            product: {
              id: data.product.id,
              name: data.product.name,
              vendor: data.product.product_vendor,
              version: `v.${data.product.version}`,
              type: data?.product?.product_type?.name,
              sourceId: data.product.product_source_id,
              vendorName: data.product.product_vendor?.name,
              product_configuration_details: data.product.product_configuration_details,
            },
            configuration: {
              id: data.configuration.id,
              sku: data.configuration.sku,
              name: data.configuration.name,
            },
            variant: {
              id: data.variant.id,
              sku: data.variant.sku,
              name: data.variant.name,
              barcode: data.variant.barcode,
            },
            relatedTasks: {
              ...prev.relatedTasks,
              nextTasks: nextItemsMapped,
              failedTasks: data.failed_tasks,
              previousTasks: previousItemsMapped,
            },
            orderInfo: {
              order_key: data.orderInfo.order_key,
              external_order_number: data.orderInfo.external_order_number,
            },
            history: {
              notes: data.history.notes,
              variantsHistory: data.history.variantsHistory,
              deadlineHistory: data.history.deadlineHistory,
              externalOrderNumberHistory: data.history.externalOrderNumberHistory,
            },
            production_workflow: {
              tags: productionTags,
              title: data.production_workflow.title,
              started_at: data.production_workflow.started_at,
              deadline_at: data.production_workflow.deadline_at,
              production_key: data.production_workflow.production_key,
            },
          })),
        );

        await dispatch(Actions.getRewards(id, data.status));
      } finally {
        if (spinner) {
          dispatch(stateController.setState({ isLoading: false }));
        }
      }
    };
  }

  public static getRewards(id: string, status: TaskStatusEnum) {
    return async (dispatch) => {
      try {
        const data = await ProductionTaskService.getTaskRewards(id);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            rewards: mapRewardsToRewardsState(data, status),
          })),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static dispose() {
    return async (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static addSlot(task_responsibility_id: string, task_assignment_id: string | null) {
    return async (dispatch) => {
      const newSlot = await TaskSlotService.createSlot(task_responsibility_id, task_assignment_id);
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
            if (responsibility.id === newSlot.task_responsibility_id) {
              return { ...responsibility, taskSlots: [...responsibility.taskSlots, newSlot] };
            }
            return responsibility;
          }),
        })),
      );
    };
  }

  public static openRemoveSlotConfirmationModal(slot_id: string) {
    return async (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete?',
            text: <>Are you sure you want to remove the slot?</>,
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Delete',
            action: () => dispatch(Actions.removeSlot(slot_id)),
          },
        }),
      );
    };
  }

  public static removeSlot(slot_id: string) {
    return async (dispatch) => {
      const removedSlot = await TaskSlotService.removeSlot(slot_id);
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
            if (responsibility.id === removedSlot.task_responsibility_id) {
              return { ...responsibility, taskSlots: responsibility.taskSlots.filter((slot) => slot.id !== slot_id) };
            }
            return responsibility;
          }),
        })),
      );
    };
  }

  public static assignUser(user: UserShortModel, slot_id: string) {
    return async (dispatch) => {
      await TaskAssigmentService.assignUser(user.id, slot_id);
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
            return {
              ...responsibility,
              taskSlots: responsibility.taskSlots.map((slot) => {
                if (slot.id === slot_id) {
                  return { ...slot, task_assignment_id: user.id, taskAssignment: { user } };
                }
                return slot;
              }),
            };
          }),
        })),
      );
    };
  }

  static updateTask(value: UpdateTaskValueT, isRefreshTask?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      const taskState = getState().task.task;
      const taskId = taskState.id;

      try {
        dispatch(stateController.setState((prev) => ({ ...prev, ...value })));

        const updateBody = { make_assignment: false, ...value };
        const response = await ProductionTaskService.updateTask(taskId, updateBody);

        if (isRefreshTask) {
          await dispatch(Actions.init(taskId, false));
          return;
        }

        if (value.is_in_queue !== undefined) {
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              taskResponsibilities: response.responsibilities,
            })),
          );
        }
      } catch (err) {
        dispatch(stateController.setState(taskState));
      }
    };
  }

  // ====== Files ===================================================================================================================

  public static deleteTaskFile(fileId: string) {
    return async (dispatch) => {
      await TaskFilesService.delete(fileId);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          taskAttachments: prev.taskAttachments.filter((file) => file.id !== fileId),
        })),
      );
    };
  }

  public static openDeleteTaskModal(id: string, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: <>{t('product_flow.delete_product_file_modal.title')}</>,
            text: (
              <div style={{ marginBottom: '7px' }}>
                Are you sure you want to delete the file <strong>{name}</strong>
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(Actions.deleteTaskFile(id)),
          },
        }),
      );
    };
  }

  public static uploadFile(files: File[]) {
    return async (dispatch, getState: GetStateFunction) => {
      const { taskAttachments, id: taskId } = getState().task.task;
      if (!files.length) {
        return;
      }

      const onProgress = (e, id) => {
        const newProgress = Math.round((e.loaded / e.total) * 100);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskAttachments: prev.taskAttachments.map((item) => {
              if (item.id === id) {
                return {
                  ...item,
                  progress: newProgress,
                };
              }
              return item;
            }),
          })),
        );
      };

      const mockFiles: FileItem[] = files.map((file) => ({
        id: `new-${uuidv4()}`,
        name: file.name,
        link: '',
        is_show_by_default: false,
        isUploading: true,
        progress: 0,
      }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          taskAttachments: [...taskAttachments, ...mockFiles].sort((a, b) => a.name.localeCompare(b.name)),
        })),
      );
      const promises = files.map(async (file, i) => {
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('task_id', taskId);

        const newFile = await TaskFilesService.create(formData, (e) => onProgress(e, mockFiles[i].id));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskAttachments: prev.taskAttachments.map((item) => {
              return mockFiles[i]?.id === item.id ? newFile : item;
            }),
          })),
        );
      });
      await Promise.all(promises);
    };
  }

  // ====== Description ===================================================================================================================

  public static updateDescription(value: string) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const taskId = getState().task.task.id;

        await ProductionTaskService.updateTask(taskId, { description: value });
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      }
    };
  }

  public static toggleIsEditing() {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          description: {
            ...prev.description,
            isEditing: !prev.description.isEditing,
          },
        })),
      );
    };
  }

  public static setDescription(text: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          description: {
            ...prev.description,
            text,
          },
        })),
      );
    };
  }

  // ====== Rewards =======================================================================================================================

  // ------ Basic rewards ------------------------------------------

  public static onChangeBasicReward(value: string, isProcessingGuard = false) {
    return async (dispatch) => {
      if (isProcessingGuard) {
        dispatch(PermissionGuardActions.openModal(isProcessingGuard));

        return;
      }
      if ((value.match(/\./g) || []).length > 1) return; // more than two characters "."
      const secondChar = value.charAt(1);
      let val: string;

      if (!value) {
        val = '0';
      } else if (secondChar === '.') {
        val = value;
      } else {
        val = value.replace(/^0+/, '') || '0';
      }

      const numbers = val.match(/[0-9.]/g)?.join('') || '';

      if (numbers.split('.')[1]?.length > 2) return; // only two chars after comma

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            basicReward: numbers,
          },
        })),
      );
    };
  }

  public static saveBasicRewardValue(value: number) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id, rewards, status } = getState().task.task;
        if (parseFloat(rewards.basicRewardPrev) === value) return;
        dispatch(stateController.setState({ isUpdating: true }));

        const updatedRewards = await ProductionTaskService.updateTaskRewards(id, { basic_reward: value });

        dispatch(stateController.setState((prev) => ({ ...prev, rewards: mapRewardsToRewardsState(updatedRewards, status) })));
      } catch (err) {
        notify.error(err.message);
      } finally {
        dispatch(stateController.setState({ isUpdating: false }));
      }
    };
  }

  public static setPrevBasicRewardValue() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            basicReward: prev.rewards.basicRewardPrev,
          },
        })),
      );
    };
  }

  // ------ Bonuses ------------------------------------------------

  public static onChangeBonusInput(inputId: string, value: string) {
    return async (dispatch) => {
      if ((value.match(/\./g) || []).length > 1) return; // more than two characters "."
      const secondChar = value.charAt(1);
      let val: string;

      if (!value) {
        val = '0';
      } else if (secondChar === '.') {
        val = value;
      } else {
        val = value.replace(/^0+/, '') || '0';
      }

      const numbers = val.match(/[0-9.]/g)?.join('') || '';

      if (numbers.split('.')[1]?.length > 2) return; // only two chars after comma

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            bonuses: prev.rewards.bonuses.map((bonus) => {
              return bonus.id === inputId ? { ...bonus, value: numbers } : bonus;
            }),
          },
        })),
      );
    };
  }

  public static saveBonusValue(id: string, value: number, forced?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id: taskId, rewards, status } = getState().task.task;
        const bonusItem = rewards.bonuses.find((i) => i.id === id);

        if ((bonusItem && parseFloat(bonusItem.valuePrev)) === value && !forced) return;
        dispatch(stateController.setState({ isUpdating: true }));

        const updatedRewards = await ProductionTaskService.updateTaskRewards(taskId, { bonus: { bonus_id: id, value } });

        dispatch(stateController.setState((prev) => ({ ...prev, rewards: mapRewardsToRewardsState(updatedRewards, status) })));
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      } finally {
        dispatch(stateController.setState({ isUpdating: false }));
      }
    };
  }

  public static setPrevBonusValue(id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { bonuses } = getState().task.task.rewards;

      const updatedBonuses = bonuses.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            value: item.valuePrev,
          };
        }
        return item;
      });

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            bonuses: updatedBonuses,
          },
        })),
      );
    };
  }

  // ------ Correction sum -----------------------------------------

  public static onChangeCorrectionValue(id: string, value?: string) {
    return async (dispatch) => {
      let val: string;

      if ((value?.match(/\./g) || []).length > 1) return; // more than two characters "."
      if ((value?.match(/-/g) || []).length > 1) return; // more than two characters "-"

      const secondChar = value?.charAt(1) || '';

      if (!value) {
        val = '0';
      } else if (secondChar === '.') {
        val = value;
      } else {
        val = value.replace(/^0+/, '') || '0';
      }

      const numbers = val.match(/[0-9.-]/g)?.join('') || '';

      if (numbers.split('.')[1]?.length > 2) return; // only two chars after comma
      if (numbers.indexOf('-') !== -1 && numbers.indexOf('-') !== 0) return;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            assignmentRewards: prev.rewards.assignmentRewards.map((assignmentReward) => {
              if (assignmentReward.taskAssignmentId === id) {
                return { ...assignmentReward, correctionSum: numbers };
              }

              return assignmentReward;
            }),
          },
        })),
      );
    };
  }

  public static onClearCorrectionClick() {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id, rewards, status } = getState().task.task;

        const correctionSumBody: UpdateTaskRewardsBody['correction_sum'] = rewards.assignmentRewards.map(
          ({ taskAssignmentId }) => ({ task_assignment_id: taskAssignmentId, value: 0 }),
        );

        const updatedRewards = await ProductionTaskService.updateTaskRewards(id, { correction_sum: correctionSumBody });

        dispatch(stateController.setState((prev) => ({ ...prev, rewards: mapRewardsToRewardsState(updatedRewards, status) })));
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      }
    };
  }

  public static saveCorrectionValue(id: string, value: number, forced?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id: taskId, rewards, status } = getState().task.task;
        const taskAssignment = rewards.assignmentRewards.find((i) => i.taskAssignmentId === id);
        const prevValue = (taskAssignment && parseFloat(taskAssignment.correctionSumPrev)) || 0;

        if (prevValue === value && !forced) return;
        dispatch(stateController.setState({ isUpdating: true }));

        const updatedRewards = await ProductionTaskService.updateTaskRewards(taskId, {
          correction_sum: [{ task_assignment_id: id, value }],
        });

        dispatch(stateController.setState((prev) => ({ ...prev, rewards: mapRewardsToRewardsState(updatedRewards, status) })));
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      } finally {
        dispatch(stateController.setState({ isUpdating: false }));
      }
    };
  }

  public static setPrevCorrectionValue(id: string) {
    return async (dispatch, getState: () => AppState) => {
      const { assignmentRewards } = getState().task.task.rewards;

      const updatedPerformers = assignmentRewards.map((performer) => {
        if (performer.taskAssignmentId === id) {
          return {
            ...performer,
            correctionSum: performer.correctionSumPrev,
          };
        }
        return performer;
      });

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          rewards: {
            ...prev.rewards,
            assignmentRewards: updatedPerformers,
          },
        })),
      );
    };
  }

  // ------ Edit number of produced items (modal) ------------------

  public static editNumberOfProducedItemsModalOpen() {
    return async (dispatch, getState: () => AppState) => {
      const task_id: string = getState().task.task.id;
      const { data: assignedUsers } = await TaskAssigmentService.getTaskAssignments({ task_id });

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          editNumberOfProducedItemsModal: {
            ...prev.editNumberOfProducedItemsModal,
            isModalOpen: true,
            users: mapFormattedAssignedUsers(assignedUsers),
          },
        })),
      );
    };
  }

  public static editNumberOfProducedItemsModalClose() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          editNumberOfProducedItemsModal: defaultState.editNumberOfProducedItemsModal,
        })),
      );
    };
  }

  public static onInputChange(value: string, assignmentId: string) {
    return async (dispatch) => {
      const numbers = value.replace(/[^0-9]/g, '');
      const newValue = numbers === '' ? 0 : Number(numbers);

      dispatch(
        stateController.setState((prev) => {
          const updatedAssignments = prev.editNumberOfProducedItemsModal.updatedAssignments.filter(
            (assignment) => assignment.taskAssignmentId !== assignmentId,
          );

          return {
            ...prev,
            editNumberOfProducedItemsModal: {
              ...prev.editNumberOfProducedItemsModal,
              users: prev.editNumberOfProducedItemsModal.users.map((user) => {
                if (user.taskAssignmentId === assignmentId) {
                  if (user.producedItems !== newValue || (user.producedItems === 0 && newValue === 0)) {
                    updatedAssignments.push({ ...user, producedItems: newValue });
                  }
                  return { ...user, producedItems: newValue };
                }
                return user;
              }),
              updatedAssignments,
            },
          };
        }),
      );
    };
  }

  public static saveNumberOfProducedItems() {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { id, status } = getState().task.task;
        const { updatedAssignments } = getState().task.task.editNumberOfProducedItemsModal;
        if (!updatedAssignments.length) {
          dispatch(Actions.editNumberOfProducedItemsModalClose());
          return;
        }

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            editNumberOfProducedItemsModal: {
              ...prev.editNumberOfProducedItemsModal,
              isLoading: true,
            },
          })),
        );

        const body: UpdateMultipleTaskAssignmentsRequestBody = updatedAssignments.map((i) => ({
          id: i.taskAssignmentId,
          items_produced: i.producedItems,
        }));

        await TaskAssigmentService.updateMultipleTaskAssignments(body);
        await dispatch(Actions.getRewards(id, status));

        dispatch(Actions.editNumberOfProducedItemsModalClose());
      } catch (err) {
        notify.error(err.message);
        throw err;
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            editNumberOfProducedItemsModal: {
              ...prev.editNumberOfProducedItemsModal,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  // ====== Related tasks =================================================================================================================

  public static unassigneUser(prevUser: UserShortModel, slot_id: string) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
              return {
                ...responsibility,
                taskSlots: responsibility.taskSlots.map((slot) => {
                  if (slot.id === slot_id) {
                    return { ...slot, taskAssignment: null, task_assignment_id: null };
                  }
                  return slot;
                }),
              };
            }),
          })),
        );
        await TaskAssigmentService.unassignUser(slot_id);
      } catch (error) {
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            taskResponsibilities: prevState.taskResponsibilities.map((responsibility) => {
              return {
                ...responsibility,
                taskSlots: responsibility.taskSlots.map((slot) => {
                  if (slot.id === slot_id) {
                    return { ...slot, task_assignment_id: prevUser.id, taskAssignment: { user: prevUser } };
                  }
                  return slot;
                }),
              };
            }),
          })),
        );
        notify.error('Somethin went wrong');
        throw error;
      }
    };
  }

  // ====== Responsibilities ==============================================================================================================

  public static createResponsibility() {
    return async (dispatch, getState: GetStateFunction) => {
      const { general, access } = getState().workflow_task_template_responsibility_modal;
      const { id, taskResponsibilities } = getState().task.task;

      const getIdArray = <T extends { id: string }[]>(array: T): string[] => array.map((item) => item.id);

      const data: TaskResponsibilityCreateRequest = {
        name: general.name,
        task_id: id,
        reward_id: null,
        assigment_type: general.type,
        responsibility_count: general.workersCount,
        departments: getIdArray(access.selectedDepartments),
        positionTypes: getIdArray(access.selectedPositions),
        users: getIdArray(access.selectedWorkers),
      };

      try {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.closeModal());

        const newResponsibility = await TaskResponsibilityService.createResponsibility(data);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: [...prev.taskResponsibilities, newResponsibility],
          })),
        );
      } catch (error) {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.openModal(taskResponsibilities));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: [...taskResponsibilities],
          })),
        );

        throw error;
      }
    };
  }

  public static updateResponsibility() {
    return async (dispatch, getState: GetStateFunction) => {
      const { general, access, currentId } = getState().workflow_task_template_responsibility_modal;
      const { taskResponsibilities } = getState().task.task;

      const getIdArray = <T extends { id: string }[]>(array: T): string[] => array.map((item) => item.id);

      const data: TaskResponsibilityUpdateRequest = {
        name: general.name,
        assigment_type: general.type,
        taskDepartmentRelations: getIdArray(access.selectedDepartments),
        taskPositionTypeRelations: getIdArray(access.selectedPositions),
        taskUserRelations: getIdArray(access.selectedWorkers),
      };

      try {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.closeModal());
        const updatedResponsibility = await TaskResponsibilityService.updateResponsibility(currentId, data);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: prev.taskResponsibilities.map((item) => {
              if (item.id === currentId) {
                return updatedResponsibility;
              }
              return item;
            }),
          })),
        );
      } catch (error) {
        dispatch(WorkflowTaskTemplateResponsibilityModalActions.openModal(taskResponsibilities));
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: [...taskResponsibilities],
          })),
        );

        throw error;
      }
    };
  }

  public static deleteResponsibility(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { taskResponsibilities } = getState().task.task;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: taskResponsibilities.filter((item) => item.id !== id),
          })),
        );

        await TaskResponsibilityService.deleteResponsibility(id);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            taskResponsibilities: [...taskResponsibilities],
          })),
        );

        throw error;
      }
    };
  }

  public static openDeleteResponsibilityConfirmationModal(id: string, name: string) {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete responsibility',
            text: (
              <div style={{ marginBottom: '7px' }}>
                Are you sure you want to delete <strong>{name}</strong>
                <br />
                You cannot undo this action.
              </div>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => dispatch(Actions.deleteResponsibility(id)),
          },
        }),
      );
    };
  }

  // ====== Time tracking =================================================================================================================

  public static setTrackingInProgress(taskId: string, isEditPermitted: boolean, isProcessingGuard: boolean) {
    return async (dispatch) => {
      if (!isEditPermitted) {
        dispatch(PermissionGuardActions.openModal());
        return;
      }

      if (isProcessingGuard) {
        dispatch(PermissionGuardActions.openModal(isProcessingGuard));

        return;
      }

      try {
        const data = await TaskTimeTrackingService.setStatus(taskId, TaskStatusEnum.In_Progress);
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            status_changed_at: data.status_changed_at,
            started_at: data.started_at,
            status: data.status,
            is_in_queue: data.is_in_queue,
          })),
        );
      } catch (error) {
        notify.error(error.message);
        throw error;
      }
    };
  }

  public static setTrackingOnHold(taskId: string, isEditPermitted: boolean, isProcessingGuard: boolean) {
    return async (dispatch) => {
      if (!isEditPermitted) {
        dispatch(PermissionGuardActions.openModal());
        return;
      }

      if (isProcessingGuard) {
        dispatch(PermissionGuardActions.openModal(isProcessingGuard));

        return;
      }

      try {
        const data = await TaskTimeTrackingService.setStatus(taskId, TaskStatusEnum.On_Hold);
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            status_changed_at: data.status_changed_at,
            total_in_progress_time: data.total_in_progress_time,
            total_in_progress_overtime: data.total_in_progress_overtime,
            status: data.status,
            is_in_queue: data.is_in_queue,
          })),
        );
      } catch (error) {
        notify.error('Somethin went wrong');
        throw error;
      }
    };
  }

  public static setTrackingDone(taskId: string, isEditPermitted: boolean, isProcessingGuard: boolean) {
    return async (dispatch) => {
      if (!isEditPermitted) {
        dispatch(PermissionGuardActions.openModal());
        return;
      }

      if (isProcessingGuard) {
        dispatch(PermissionGuardActions.openModal(isProcessingGuard));

        return;
      }

      try {
        const data = await TaskTimeTrackingService.setStatus(taskId, TaskStatusEnum.Done);
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            status_changed_at: data.status_changed_at,
            total_in_progress_time: data.total_in_progress_time,
            total_in_progress_overtime: data.total_in_progress_overtime,
            total_paused_time: data.total_paused_time,
            total_paused_out_of_work_time_range: data.total_paused_out_of_work_time_range,
            status: data.status,
            is_in_queue: data.is_in_queue,
          })),
        );
      } catch (error) {
        notify.error('Somethin went wrong');
        throw error;
      }
    };
  }

  // ====== WS handlers ==================================================================================================================

  static handleWebsocketResponse = (message: WebsocketResponseMessageForTaskDetailsT, event: WebsocketEvent) => {
    return (dispatch) => {
      if (!message || !event) {
        return;
      }

      const eventHandlers = {
        [TaskDetailsWebsocketEventCategory.BULK_STATUS_CHANGE_VIEWER]: Actions.handleBulkSCViewerWsResponse,
        [TaskDetailsWebsocketEventCategory.BULK_STATUS_CHANGE]: Actions.handleBulkSCWsResponse,
      };

      const eventCategoryMapping: Partial<Record<WebsocketEvent, keyof typeof eventHandlers>> = {
        [WebsocketEvent.BulkStatusChangeStarted]: TaskDetailsWebsocketEventCategory.BULK_STATUS_CHANGE_VIEWER,
        [WebsocketEvent.BulkStatusChangeFinished]: TaskDetailsWebsocketEventCategory.BULK_STATUS_CHANGE_VIEWER,
        [WebsocketEvent.BulkStatusChangeTaskUpdateFinished]: TaskDetailsWebsocketEventCategory.BULK_STATUS_CHANGE,
        [WebsocketEvent.BulkStatusChangeTaskUpdateFailed]: TaskDetailsWebsocketEventCategory.BULK_STATUS_CHANGE,
      };

      const handlerName = eventCategoryMapping[event];

      switch (handlerName) {
        case TaskDetailsWebsocketEventCategory.BULK_STATUS_CHANGE_VIEWER:
          dispatch(eventHandlers[handlerName](message as WebsocketStartEndMessageForTaskStatusT, event));
          break;
        case TaskDetailsWebsocketEventCategory.BULK_STATUS_CHANGE:
          dispatch(eventHandlers[handlerName](message as WebsocketResponseMessageForTaskStatusT, event));
          break;
        default:
      }
    };
  };

  static handleBulkSCViewerWsResponse = (message: WebsocketStartEndMessageForTaskStatusT, event: WebsocketEvent) => {
    return async (dispatch, getState: () => AppState) => {
      const taskState = getState().task.task;
      const taskId = taskState.id;
      const isLockedTask = taskState.locked;

      const isStartEvent = event === WebsocketEvent.BulkStatusChangeStarted;
      const isFinishEvent = event === WebsocketEvent.BulkStatusChangeFinished;

      if (isStartEvent && 'tasksIds' in message) {
        const { tasksIds } = message;

        if (!tasksIds.includes(taskId)) return;

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            locked: true,
          })),
        );
      }

      if (isFinishEvent && isLockedTask && 'task_ids' in message) {
        const { task_ids } = message;

        if (!task_ids.includes(taskId)) return;

        await dispatch(Actions.init(taskId, false));
      }
    };
  };

  static handleBulkSCWsResponse = (message: WebsocketResponseMessageForTaskDetailsT, event: WebsocketEvent) => {
    return async (dispatch, getState: () => AppState) => {
      const taskState = getState().task.task;
      const taskId = taskState.id;

      const isUpdateFinishTaskEvent = event === WebsocketEvent.BulkStatusChangeTaskUpdateFinished;
      const isUpdateFailedTaskEvent = event === WebsocketEvent.BulkStatusChangeTaskUpdateFailed;

      if (isUpdateFinishTaskEvent && 'task_id' in message) {
        const { task_id } = message;

        if (taskId !== task_id) return;

        await dispatch(Actions.init(taskId, false));

        return;
      }

      if (isUpdateFailedTaskEvent && 'task_id' in message) {
        const { task_id } = message;

        if (taskId !== task_id) return;

        await dispatch(Actions.init(taskId, false));
      }
    };
  };
}

export class Selectors {
  public static getAssignedUsers(state: AppState) {
    const slots = state.task.task.taskResponsibilities.flatMap((responsibility) => {
      return responsibility.taskSlots;
    });
    return slots.filter((slot) => !!slot.task_assignment_id);
  }

  public static getSlotsForMultiAssigneeSelect(state: AppState) {
    return state.task.task.taskResponsibilities.flatMap((responsibility) =>
      responsibility.taskSlots.map((slot) => ({
        id: slot.id,
        responsibilityId: slot.task_responsibility_id,
        assignedUserId: slot.taskAssignment?.user?.id,
      })),
    );
  }

  public static getAssignmentRewardsByPeriod(state: AppState) {
    const assignment_rewards = state.task.task.rewards.assignmentRewards;

    const assignmentInPeriod = assignment_rewards.filter((user) => user.is_in_current_reporting_period);
    const assignmentOutPeriod = assignment_rewards.filter((user) => !user.is_in_current_reporting_period);
    return {
      assignmentInPeriod,
      assignmentOutPeriod,
    };
  }

  public static getSlots(state: AppState) {
    const slots = state.task.task.taskResponsibilities.flatMap((responsibility) => {
      return responsibility.taskSlots.map((slot) => ({
        id: slot.id,
        responsibilityId: slot.task_responsibility_id,
        assignedUserId: slot.taskAssignment?.user?.id,
        last_name: slot.taskAssignment?.user?.last_name,
        first_name: slot.taskAssignment?.user?.first_name,
        department: slot.taskAssignment?.user?.user_position_slots?.[0]?.position_slot.department,
        position: slot.taskAssignment?.user?.user_position_slots?.[0]?.position_slot.position_type,
        avatar_image_url: slot.taskAssignment?.user?.avatar_image_url,
      }));
    });
    return slots;
  }

  public static getUnassignedUsers(state: AppState) {
    const assignedUsers = this.getAssignedUsers(state);
    const { users } = state.task.task.perfomers;
    const unassignedUsers = {
      suggestedUsers: users.suggestedUsers.filter((user) => {
        return !assignedUsers.find((assignedUser) => assignedUser.taskAssignment.user.id === user.id);
      }),
      otherUsers: users.otherUsers.filter((user) => {
        return !assignedUsers.find((assignedUser) => assignedUser.taskAssignment.user.id === user.id);
      }),
    };

    return unassignedUsers;
  }

  public static getOverTotalvalue(state: AppState) {
    const { assignmentRewards, totalReward } = state.task.task.rewards;
    const sum = assignmentRewards
      .map((assignmentReward) => assignmentReward.total)
      .reduce((accumulator, value) => accumulator + value, 0);
    return sum - Number(totalReward);
  }

  public static canUserManageFailedTasks(state: AppState) {
    const { isProductionHasTaskInDoneStatus, productionStatus, is_root_workflow_completed } = state.task.task;

    return (
      isProductionHasTaskInDoneStatus && (productionStatus === ProductionStatusEnum.In_Progress || !is_root_workflow_completed)
    );
  }

  public static isAllUsersHasValue(state: AppState) {
    const { users } = state.task.task.editNumberOfProducedItemsModal;

    return users.every((i) => typeof i.producedItems === 'number');
  }
}

export const reducer = stateController.getReducer();
