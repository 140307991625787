import { notify } from 'notifications';
import { GetStateFunction } from 'redux/store';
import { ProductConfigurationService } from 'services/product-configurations.service';
import { ProductsService } from 'services/products.service';
import { WorkflowTemplatesService } from 'services/workflow-templates.service';
import { StateController } from 'state-controller';
import { IdName, MetaT } from 'types/common-types';
import { debounce } from 'utils/debounce';
import { Actions as ProductRootActions } from 'pages/product-flow/pages/product/controllers/product-root.controller';

export type CopyWorkflowModalState = {
  isOpen: boolean;
  workflowId: string;
  isProcessing: boolean;
  workflowName: string;
  product: {
    value: IdName;
    options: any[];
    meta: MetaT;
    isLoading: boolean;
  };
  configuration: {
    value: IdName;
    options: any[];
    meta: MetaT;
    isLoading: boolean;
  };
};

const defaultState: CopyWorkflowModalState = {
  workflowId: '',
  isOpen: false,
  workflowName: '',
  isProcessing: false,
  product: {
    value: { id: '', name: '' },
    options: [],
    isLoading: false,
    meta: {
      next: 0,
      prev: 0,
      total: 0,
      perPage: 10,
      lastPage: 0,
      currentPage: 1,
    },
  },
  configuration: {
    value: { id: '', name: '' },
    options: [],
    isLoading: false,
    meta: {
      next: 0,
      prev: 0,
      total: 0,
      perPage: 10,
      lastPage: 0,
      currentPage: 1,
    },
  },
};

const stateController = new StateController<CopyWorkflowModalState>('COPY_WORKFLOW_MODAL', defaultState);

export class CopyWorkflowModalActions {
  public static resetState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  private static setConfigurations(productId: string) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            configuration: {
              ...prev.configuration,
              isLoading: true,
            },
          })),
        );

        const productConfigurations = await ProductConfigurationService.getAll(productId);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            configuration: {
              ...prev.configuration,
              isLoading: false,
              options: productConfigurations,
              value: productConfigurations[0],
            },
          })),
        );
      } catch {
        notify.error('error');
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            configuration: {
              ...prev.configuration,
              isLoading: false,
            },
          })),
        );
      }
    };
  }

  public static openModal(id: string, name: string) {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: true, workflowId: id, workflowName: `${name} copy` }));
    };
  }

  public static onProductInputOpen() {
    return async (dispatch, getState) => {
      const { product } = getState().product.copy_workflow_modal;

      if (product.isLoading || product.options.length) return;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: { ...prev.product, isLoading: true },
          })),
        );

        const { data, meta } = await ProductsService.getProductWithVersion();

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: {
              ...prev.product,
              options: data,
              meta,
            },
          })),
        );
      } catch (error) {
        notify.error('error');
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product: { ...prev.product, isLoading: false },
          })),
        );
      }
    };
  }

  public static loadMoreProducts(search: string = '', isScroll: boolean = true) {
    return (dispatch, getState: GetStateFunction) => {
      const { product } = getState().product.copy_workflow_modal;

      const currentMeta = product.meta;
      const nextPage = isScroll ? currentMeta.next : 0;

      if (isScroll && (!currentMeta.next || product.isLoading)) return;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product: {
            ...prev.product,
            isLoading: true,
            ...(!isScroll && { options: [], meta: { ...prev.product.meta, next: 0 } }),
          },
        })),
      );

      debounce(async () => {
        try {
          const { data, meta } = await ProductsService.getProductWithVersion(search.trim(), nextPage || 0, currentMeta.perPage);

          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              product: {
                ...prev.product,
                options: isScroll ? [...prev.product.options, ...data] : data,
                meta: {
                  ...meta,
                  perPage: currentMeta.perPage || meta.perPage,
                },
                isLoading: false,
              },
            })),
          );
        } catch (error) {
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              product: { ...prev.product, isLoading: false },
            })),
          );
        }
      }, 500);
    };
  }

  public static closeModal() {
    return (dispatch, getState: GetStateFunction) => {
      const { isProcessing } = getState().product.copy_workflow_modal;

      if (isProcessing) return;

      dispatch(stateController.setState({ isOpen: false }));
      dispatch(CopyWorkflowModalActions.resetState());
    };
  }

  public static onNameChange(value: string) {
    return (dispatch) => {
      dispatch(stateController.setState({ workflowName: value }));
    };
  }

  public static onProductChange(newProduct: IdName) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product: {
            ...prev.product,
            value: newProduct,
          },
        })),
      );
      dispatch(CopyWorkflowModalActions.setConfigurations(newProduct.id));
    };
  }

  public static onConfigurationChange(newConfiguration: IdName) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          configuration: {
            ...prev.configuration,
            value: newConfiguration,
          },
        })),
      );
    };
  }

  public static onSave() {
    return async (dispatch, getState: GetStateFunction) => {
      const { id } = getState().product.product_root;
      const { product, workflowId, configuration, workflowName } = getState().product.copy_workflow_modal;

      try {
        dispatch(stateController.setState({ isProcessing: true }));

        await WorkflowTemplatesService.copyWorkflowTemplate(workflowId, {
          name: workflowName.trim(),
          product_id: product.value.id,
          product_configuration_id: configuration.value.id,
        });

        dispatch(stateController.setState({ isProcessing: false }));

        dispatch(CopyWorkflowModalActions.closeModal());

        if (product.value.id === id) {
          dispatch(ProductRootActions.initProduct(id, configuration.value.id));
        }

        notify.success('Successfully copied');
      } catch {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }
}

export const reducer = stateController.getReducer();
