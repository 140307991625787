import { ReactNode } from 'react';
import { IdName } from 'types/common-types';
import Checkbox from 'components/checkbox/checkbox';
import { bindTrigger } from 'material-ui-popup-state';
import { Input } from 'components/ui-new/inputs/input';
import { COLORS } from 'components/manage-tags-modals/constants';
import HighlightText from 'components/highlight-text/highlight-text';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import {
  FetchDataT,
  FormattedItem,
  useDropdownSearchChipsSelector,
} from 'components/ui-new/dropdown-search-chips-selector/use-dropdown-search-chips-selector';
import { isIdNameValueWithColorT } from 'components/ui-new/dropdown-search-chips-selector/helpers';
import {
  Chip,
  ClickAwayListener,
  FormControlLabel,
  Paper,
  PopoverVirtualElement,
  Skeleton,
  Tooltip,
  Popper,
} from '@mui/material';
import { FilterDataType } from 'pages/production/controllers/production-filters-controller/types';
import s from './dropdown-search-chips-selector.module.scss';

type Props<T extends IdName> = {
  values: T[];
  options: T[];
  isOpen: boolean;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  showAllItems?: boolean;
  disablePortal?: boolean;
  // isLoadingOptions?: boolean;
  isFloatingFilterButton?: boolean;
  headerCheckBoxes?: FilterDataType['shortcutOptions'];
  externalAnchorEl?: Element | (() => Element) | PopoverVirtualElement | (() => PopoverVirtualElement) | null | undefined;
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  fetchData?: FetchDataT;
  onSelect: (value: T[], newValue: T, checked: boolean) => void;
  setIsOpen: (value: boolean) => void;
  onChangeInput?: (value: any) => void;
  renderTooltip?: (item: T) => JSX.Element;
  dataFormatting?: (data: any[]) => FormattedItem[];
  renderSelectItem?: (item: T, keyword: string) => JSX.Element;
};

export default function DropdownSearchChipsSelector<T extends IdName>({
  isOpen,
  values,
  options,
  disabled,
  children,
  headerCheckBoxes,
  showAllItems = false,
  disablePortal = true,
  externalAnchorEl = null,
  isFloatingFilterButton = false,
  onOpen,
  onClose,
  onSelect,
  fetchData,
  setIsOpen,
  onChangeInput,
  renderTooltip,
  dataFormatting,
  renderSelectItem,
}: Props<T>) {
  const {
    rootRef,
    keyword,
    chipRefs,
    popupState,
    inputValue,
    filteredList,
    itemsPerPage,
    isFetchingData,
    searchInputRef,
    isLazyProcessing,
    handleInput,
    handleScroll,
    openDropdown,
    onOptionSelect,
    isCheckedHeaderCheckbox,
  } = useDropdownSearchChipsSelector({
    values,
    options,
    isOpen,
    setIsOpen,
    onSelect,
    onOpen,
    onChangeInput,
    onClose,
    fetchData,
    dataFormatting,
  });

  return (
    <div ref={rootRef} {...bindTrigger(popupState)}>
      <div
        data-disbled={disabled}
        className={isFloatingFilterButton ? '' : s.container}
        onClick={() => (isOpen ? setIsOpen(false) : openDropdown())}
      >
        {children}
      </div>

      <Popper
        open={isOpen}
        disablePortal={disablePortal}
        role={undefined}
        anchorEl={externalAnchorEl || rootRef.current}
        slotProps={{
          root: { className: s.menu_wrapper, style: { transformOrigin: 200, zIndex: 99 } },
        }}
        placement="bottom-start"
      >
        <Paper sx={{ borderRadius: '8px' }}>
          <ClickAwayListener onClickAway={() => setIsOpen(false)}>
            <div className={s.menu_container} data-is-lazy-processing={isLazyProcessing}>
              {headerCheckBoxes &&
                headerCheckBoxes.map(({ id, name, value }, index) => (
                  <div
                    className={`${s.option_container} ${s.header_checkbox_item}`}
                    key={Array.isArray(id) ? id[index] : id}
                    onClick={() => onOptionSelect({ id, name: value || name })}
                  >
                    <FormControlLabel
                      className={s.form_control}
                      label={
                        <TextEllipsis maxWidth="100%" className={s.checkbox_label}>
                          {name}
                        </TextEllipsis>
                      }
                      control={<Checkbox checked={isCheckedHeaderCheckbox({ id, name })} size="medium" className={s.checkbox} />}
                    />
                  </div>
                ))}
              <div className={s.search_container}>
                <Input
                  inputRef={searchInputRef}
                  InputProps={{
                    className: s.input,
                    startAdornment: values.map((item, index) => {
                      return item.id.includes('undefined') ? null : (
                        <Tooltip
                          key={index}
                          leaveDelay={0}
                          placement="top-start"
                          title={renderTooltip?.(item) || ''}
                          style={{
                            backgroundColor: isIdNameValueWithColorT(item) ? item.color : undefined,
                            border: isIdNameValueWithColorT(item) ? 'none' : undefined,
                          }}
                        >
                          <Chip
                            ref={(el) => {
                              chipRefs.current[index] = el;
                            }}
                            label={item.name}
                            onDelete={() => onOptionSelect(item)}
                          />
                        </Tooltip>
                      );
                    }),
                  }}
                  size="small"
                  value={inputValue}
                  placeholder="Search"
                  onChange={(event) => handleInput(event.currentTarget.value)}
                />
              </div>
              <div
                onScroll={(event) => {
                  if (fetchData && !showAllItems) {
                    handleScroll(event);
                  }
                }}
                className={`${s.options} ${isFetchingData ? s.opacity_styles : ''}`}
              >
                {isFetchingData ? (
                  Array(5)
                    .fill(null)
                    .map((item, index) => <Skeleton className={s.option_skeleton} key={`skeleton-${index}`} />)
                ) : (
                  <div className={filteredList.length ? s.list_wrapper : ''}>
                    {filteredList.length ? (
                      (showAllItems ? filteredList : filteredList.slice(0, itemsPerPage)).map((item) => (
                        <div
                          key={item.id}
                          className={s.option_container}
                          onClick={() => onOptionSelect(item as T)}
                          style={{
                            backgroundColor: isIdNameValueWithColorT(item) ? item.color : undefined,
                            marginBottom: isIdNameValueWithColorT(item) ? '6px' : undefined,
                            borderRadius: isIdNameValueWithColorT(item) ? '4px' : undefined,
                            color:
                              isIdNameValueWithColorT(item) && COLORS[item.color]?.textColor
                                ? COLORS[item.color].textColor
                                : undefined,
                          }}
                        >
                          {renderSelectItem ? (
                            renderSelectItem(item as T, keyword)
                          ) : (
                            <HighlightText text={item.name} query={keyword} />
                          )}
                        </div>
                      ))
                    ) : (
                      <div className={s.no_options_found}>No results</div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
}
