import { baseAxiosInstance } from 'axios-config';
import { TAKE_ITEMS } from 'constants/take-items';
import { PaginatedResponse, IdName } from 'types/common-types';
import { buildQueryString } from 'utils/build-query-string';
import {
  CreateProductData,
  Product,
  ProductDataPublishedProducts,
  ProductInfoResponse,
  ProductMeta,
  UpdateProductMeta,
  ProductPreviewInfoResponse,
  ProductWithVersion,
  RelatedProductResponse,
  UpdateProduct,
  IsProductDeleteResponse,
  ProductForProduction,
  BarcodeValidationModel,
  FilterParams,
  ProductsByFilterData,
  ProductMetaFindResponse,
} from './products.model';

export class ProductsService {
  public static async getProducts(params?: {
    product_vendor_id?: string;
    product_type_id?: string;
    product_category_id?: string | null;
  }) {
    const queryParams = buildQueryString({
      product_vendor_id: params?.product_vendor_id,
      product_type_id: params?.product_type_id,
      product_category_id: params?.product_category_id,
    });

    const { data } = await baseAxiosInstance.get<ProductDataPublishedProducts[]>(`/products/all?${queryParams}`);

    return data;
  }

  public static async getProductDetailedInfoById(productId: string) {
    const { data } = await baseAxiosInstance.get<ProductInfoResponse>(`/products/info/${productId}`);
    return data;
  }

  public static async getRelatedProduct(productId: string) {
    const { data } = await baseAxiosInstance.get<RelatedProductResponse[]>(`/products/${productId}/related-products`);
    return data;
  }

  public static async getProductWithVersion(search = '', skip = 0, take = 20) {
    const queryParams = buildQueryString({ search, skip, take });
    const { data } = await baseAxiosInstance.get<ProductWithVersion>(`/products/products-with-versions?${queryParams}`);
    return data;
  }

  public static async getProductPreviewDetailedInfoById(productId: string) {
    const { data } = await baseAxiosInstance.get<ProductPreviewInfoResponse>(`/products/view/${productId}`);
    return data;
  }

  public static async getProductById(productId: string) {
    const { data } = await baseAxiosInstance.get<Product>(`/products/${productId}`);
    return data;
  }

  public static async updateProduct(id: string) {
    const { data } = await baseAxiosInstance.put<Product>(`/products/update/${id}`);
    return data;
  }

  public static async changeIsActive(id: string, productData: UpdateProduct) {
    const { data } = await baseAxiosInstance.put<{ message: string }>(`/products/change-is-active/${id}`, productData);
    return data;
  }

  public static async canDeleteProduct(id: string) {
    const { data } = await baseAxiosInstance.get<IsProductDeleteResponse>(`/products/delete/${id}`);
    return data;
  }

  public static async updateProductMeta(id: string, productData: UpdateProductMeta) {
    const { data } = await baseAxiosInstance.patch<ProductMeta>(`/product-metas/${id}`, productData);
    return data;
  }

  public static async addMultipleProductVendor(id: string, productData) {
    const { data } = await baseAxiosInstance.post<ProductMeta>(`/product-metas/${id}/vendors`, productData);
    return data;
  }

  public static async deleteMultipleProductVendor(id: string, vendor_id: string) {
    const { data } = await baseAxiosInstance.delete<ProductMeta>(`/product-metas/${id}/vendors/${vendor_id}`);
    return data;
  }

  public static async getProductMeta(search = '', skip = 0, take = TAKE_ITEMS) {
    const queryParams = buildQueryString({ search, skip, take });
    const { data } = await baseAxiosInstance.get<ProductMetaFindResponse[]>(`/product-metas/find?${queryParams}`);
    return data;
  }

  public static async updateParentCategory(productMetaIds: string[], categoryId: string) {
    const { data } = await baseAxiosInstance.put(`/product-metas/update-parent-category`, {
      productMetaIds,
      product_category_id: categoryId,
    });
    return data;
  }

  public static async createProduct(productData: CreateProductData) {
    const { data } = await baseAxiosInstance.post<Product>('/products/create', productData);
    return data;
  }

  public static async publishProduct(productData: { id: string }) {
    const { data } = await baseAxiosInstance.post(`/products/publish`, productData);
    return data;
  }

  public static async deleteProduct(id: string) {
    const { data } = await baseAxiosInstance.delete<Product>(`/products/delete/${id}`);
    return data;
  }

  public static async resetDraft(id: string) {
    const { data } = await baseAxiosInstance.delete<Product>(`/products/reset-draft/${id}`);
    return data;
  }

  public static async duplicateProduct(productId: string) {
    const { data } = await baseAxiosInstance.post<ProductDataPublishedProducts>(`/products/duplicate`, { id: productId });
    return data;
  }

  public static async updateMainVersion(id: string) {
    const { data } = await baseAxiosInstance.put<{ message: string }>(`/products/change-main-version/${id}`);
    return data;
  }

  public static async getProductsByKeyword(search = '', skip = 0, take = TAKE_ITEMS) {
    const queryParams = buildQueryString({ search, skip, take });
    const { data } = await baseAxiosInstance.get<PaginatedResponse<IdName[]>>(`/products/search-short-info?${queryParams}`);
    return data;
  }

  public static async getProductForProduction({
    workflow_template_id,
    product_variant_id,
  }: {
    workflow_template_id?: string;
    product_variant_id?: string;
  }) {
    let query = '';
    if (workflow_template_id) {
      query = `?workflow_template=${workflow_template_id}`;
    }
    if (product_variant_id) {
      query = `?product_variant=${product_variant_id}`;
    }
    const { data } = await baseAxiosInstance.get<ProductForProduction[]>(`/products/get-for-production${query}`);
    return data;
  }

  public static async barcodeUniqueValidation(barcode: string, productId: string) {
    const { data } = await baseAxiosInstance.get<BarcodeValidationModel>(`/products/is-barcode-valid/${productId}`, {
      params: {
        barcode,
      },
    });
    return data;
  }

  public static async getProductsByFilter(body: FilterParams) {
    const queryParams = buildQueryString({
      order_by: body.order_by,
      sku: body.sku,
      skip: body.skip,
      take: body.take,
      is_available: body.is_available,
      contains_draft: body.contains_draft,
      order_direction: body.order_direction,
      parameters: body.parameters,
      product_search_query: body.name,
      product_name: body.product_name,
      product_tag_id: body.product_tag_id,
      publish_date_end: body.publish_date_end,
      product_vendor_id: body.product_vendor_id,
      publish_date_start: body.publish_date_start,
      product_category_id: body.product_category_id,
      last_update_date_end: body.last_update_date_end,
      last_update_date_start: body.last_update_date_start,
    });

    const { data } = await baseAxiosInstance.get<ProductsByFilterData>(`/products/search?${queryParams}`);

    return data;
  }
}
