import { Stack } from '@mui/material';
import { ChangeEvent, useRef, useState } from 'react';
import DropDownSearchFilter from 'pages/tasks/modules/drop-down-search-filter/drop-down-search-filter';
import DropDownSelectFilter from 'pages/tasks/modules/drop-down-select-filter/drop-down-select-filter';
import DropDownDateFilter from 'pages/tasks/modules/drop-down-date-filter/drop-down-date-filter';
import { FilterSettingEnum, TasksFiltersEnum } from 'pages/tasks/types/types';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { onlyDigitsOrEmptyStringRegex, productVersionFilters } from 'pages/tasks/constants';
import DropDownSearchChipsFilter from 'pages/tasks/modules/drop-down-search-chips-filter/dropdown-search-chips-filter';
import FloatingFilterButton from 'pages/tasks/components/floating-filter-button/floating-filter-button';
import s from './floating-filter.module.scss';

const FloatingFilter = (params) => {
  const dispatch = useDispatch();
  const queryValue = useSelector((state: AppState) => state.tasks.filters.queries[params.fieldName]) || '';

  const anchorElRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState(queryValue);

  const openPopover = () => setIsOpen(true);

  const closePopover = () => setIsOpen(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (productVersionFilters.includes(params.fieldName) && !onlyDigitsOrEmptyStringRegex.test(value)) {
      return;
    }

    setSearchValue(value);

    if (params.fieldName === TasksFiltersEnum.ReasonForFailure) {
      dispatch(
        TasksActions.onReasonForFailureFilterChange({
          fieldName: params.fieldName,
          value,
          gridApi: params.api,
          isQueryField: true,
        }),
      );
      return;
    }

    dispatch(
      TasksActions.onFilterChange({
        fieldName: params.fieldName,
        value,
        gridApi: params.api,
      }),
    );
  };

  // if (!params.showSearchInput && !params.showFilterDropdownButton) return null;

  return (
    <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap="12px" ref={anchorElRef}>
      {params.showSearchInput && (
        <input className={s.input} value={searchValue} type={params.inputType || 'text'} onChange={handleInputChange} />
      )}
      {params.showFilterDropdownButton && <FloatingFilterButton fieldName={params.fieldName} onClick={openPopover} />}
      {params.useSearchFilter && (
        <DropDownSearchFilter
          isOpen={isOpen}
          anchorEl={anchorElRef.current}
          fieldName={params.fieldName}
          gridApi={params.api}
          onClose={closePopover}
          fetchOptionsFunction={params.fetchOptionsFunction}
          includeUnassignedOption={params.includeUnassignedOption}
        />
      )}
      {params.useSelectFilter && (
        <DropDownSelectFilter
          menuItems={[
            { text: 'Contains', value: FilterSettingEnum.Contains },
            { text: 'Blank', value: FilterSettingEnum.Blank },
          ]}
          fieldName={params.fieldName}
          gridApi={params.api}
          isOpen={isOpen}
          anchorEl={anchorElRef.current}
          onClose={closePopover}
          setFloatingFilterSearchValue={setSearchValue}
        />
      )}

      {params.useDropdownSearchChipsSelectorFilter && (
        <DropDownSearchChipsFilter
          anchorEl={anchorElRef.current}
          fieldName={params.fieldName}
          isOpen={isOpen}
          onClose={closePopover}
          setIsOpen={setIsOpen}
          openPopover={openPopover}
          gridApi={params.api}
          fetchOptionsFunction={params.fetchOptionsFunction}
        />
      )}
      {params.useDateFilter && (
        <DropDownDateFilter
          isOpen={isOpen}
          fieldName={params.fieldName}
          gridApi={params.api}
          anchorEl={anchorElRef.current}
          onClose={closePopover}
        />
      )}
    </Stack>
  );
};

export default FloatingFilter;
