import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { UpdateTaskValueT } from 'pages/task/types';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TaskStatusEnum } from 'types/status-enums';

type Args = {
  isInQueue: boolean;
  status: TaskStatusEnum;
  isProcessingGuard: boolean;
  type: 'task' | 'production';
  isManageInQueueStatusPermitted: boolean;
  onUpdate: (value: UpdateTaskValueT, isRefreshTask?: boolean) => void;
};

const useInQueueLogic = ({
  type,
  status,
  isInQueue,
  isManageInQueueStatusPermitted,
  isProcessingGuard = false,
  onUpdate,
}: Args) => {
  const [isShowDimmedInQueue, setIsShowDimmedInQueue] = useState(false);
  const dispatch = useDispatch();

  const currentStatus = useRef(status);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const isShowLockIcon = isInQueue || isShowDimmedInQueue;

  const inQueueTooltip =
    type === 'task' && status === TaskStatusEnum.To_Do && !isInQueue ? 'Press to lock the task' : 'Press to unlock the task';

  const hideInQueueMarkIfNotHoveredOverElement = () => {
    setIsShowDimmedInQueue(false);

    if (wrapperRef.current?.matches(':hover') && currentStatus.current === TaskStatusEnum.To_Do) {
      setIsShowDimmedInQueue(true);
    }
  };

  const handleIsShowDimmedInQueue = () => {
    if (!isManageInQueueStatusPermitted || type === 'production' || status !== TaskStatusEnum.To_Do || isInQueue) return;

    setIsShowDimmedInQueue(true);
  };

  const handleInQueueUpdate = () => {
    if (!isManageInQueueStatusPermitted || !(isInQueue || isShowDimmedInQueue)) return;

    if (isProcessingGuard) {
      dispatch(PermissionGuardActions.openModal(isProcessingGuard));

      return;
    }

    if (isShowDimmedInQueue) {
      setIsShowDimmedInQueue(false);
      onUpdate({ is_in_queue: true });
      return;
    }

    if (status === TaskStatusEnum.To_Do) setIsShowDimmedInQueue(true);

    onUpdate({ is_in_queue: false });
  };

  return {
    wrapperRef,
    currentStatus,
    inQueueTooltip,
    isShowLockIcon,
    isShowDimmedInQueue,
    handleInQueueUpdate,
    setIsShowDimmedInQueue,
    handleIsShowDimmedInQueue,
    hideInQueueMarkIfNotHoveredOverElement,
  };
};

export default useInQueueLogic;
