import { MenuItem, Popover, Select, SelectChangeEvent, Stack } from '@mui/material';
import { KeyboardArrowDownOutlined } from '@mui/icons-material';
import { MenuItemType } from 'pages/tasks/modules/drop-down-select-filter/types';
import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { DropDownProps, FilterSettingEnum, TasksFiltersEnum } from 'pages/tasks/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AppState } from 'redux/store';
import { GridApi } from 'ag-grid-community';
import s from './drop-down-select-filter.module.scss';
import floatingFilterStyle from '../floating-filter/floating-filter.module.scss';

type Props = {
  menuItems: MenuItemType[];
  fieldName: TasksFiltersEnum;
  gridApi: GridApi;
  setFloatingFilterSearchValue: Dispatch<SetStateAction<string>>;
} & DropDownProps;

const DropDownSelectFilter = ({
  isOpen,
  anchorEl,
  menuItems,
  fieldName,
  gridApi,
  onClose,
  setFloatingFilterSearchValue,
}: Props) => {
  const dispatch = useDispatch();
  const filterValue = useSelector((state: AppState) => state.tasks.filters.queries[fieldName]);

  const [selectedValue, setSelectedValue] = useState(filterValue === null ? FilterSettingEnum.Blank : FilterSettingEnum.Contains);
  const [searchValue, setSearchValue] = useState('');

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as FilterSettingEnum;
    const isBlankSelected = value === FilterSettingEnum.Blank;

    if (isBlankSelected) {
      setSearchValue('');
      setFloatingFilterSearchValue('');
    }

    setSelectedValue(value);
    dispatch(TasksActions.onFilterChange({ fieldName, value: isBlankSelected ? null : searchValue, gridApi }));
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    dispatch(TasksActions.onFilterChange({ fieldName, value: event.target.value, gridApi }));
  };

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      slotProps={{ paper: { className: floatingFilterStyle.popover } }}
    >
      <Stack gap="8px">
        <Select
          className={s.select}
          fullWidth
          value={selectedValue}
          IconComponent={KeyboardArrowDownOutlined}
          onChange={handleSelectChange}
          MenuProps={{ slotProps: { paper: { className: s.menu_container } } }}
        >
          {menuItems.map((item) => (
            <MenuItem className={s.menu_item} data-hidden={item.value === selectedValue} value={item.value} key={item.value}>
              {item.text}
            </MenuItem>
          ))}
        </Select>
        <input
          className={floatingFilterStyle.input}
          disabled={selectedValue === FilterSettingEnum.Blank}
          value={searchValue}
          onChange={handleInputChange}
        />
      </Stack>
    </Popover>
  );
};

export default DropDownSelectFilter;
