import { useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { TasksFiltersEnum } from 'pages/tasks/types/types';
import s from './floating-filter-button.module.scss';

type Props = {
  fieldName: TasksFiltersEnum;
  onClick: () => void;
};

const FloatingFilterButton = ({ fieldName, onClick }: Props) => {
  const activeFilters = useSelector((state: AppState) => state.tasks.activeFilters);

  return (
    <button className={s.button} type="button" data-active={activeFilters.includes(fieldName)} onClick={onClick}>
      <div className={s.line} />
      <div className={s.line} />
      <div className={s.line} />
    </button>
  );
};

export default FloatingFilterButton;
