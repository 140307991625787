import { baseAxiosInstance } from 'axios-config';
import {
  AssignResponsibleToTasksBodyArgs,
  BulkStatusChangeCountersT,
  BulkTasksStatusChangeBodyArgs,
  SuggestedDepartmentsT,
  SuggestedPositionsT,
  TaskTableModel,
} from 'services/task-table.model';
import { IdName } from 'types/common-types';
import { PaginateResponse } from 'types/paginate-response';
import { buildQueryString } from 'utils/build-query-string';

export class TaskTableService {
  public static async getAllTask(
    body: any,
    params?: { skip?: number | null; take?: number },
    signal?: AbortController['signal'],
  ) {
    const { data } = await baseAxiosInstance.post<PaginateResponse<TaskTableModel>>('/tasks/all', body, { params, signal });

    return data;
  }

  public static async bulkAssignUsersToTasks(body: AssignResponsibleToTasksBodyArgs[]) {
    const { data } = await baseAxiosInstance.post<string[]>('/tasks/multi-assign', body);
    return data;
  }

  public static async bulkTasksStatusChange(body: BulkTasksStatusChangeBodyArgs) {
    const { data } = await baseAxiosInstance.patch<string[]>('/tasks/status/bulk', body);
    return data;
  }

  public static async getSuggestedDepartments({
    skip,
    take,
    search,
  }: {
    skip?: number | null;
    take?: number;
    search?: string;
  } = {}): Promise<PaginateResponse<IdName>> {
    const queryParams = buildQueryString({
      skip,
      take,
      search,
    });
    const { data } = await baseAxiosInstance.get<PaginateResponse<SuggestedDepartmentsT>>(
      `/departments/all-short-info?${queryParams}`,
    );

    return data;
  }

  public static async getSuggestedPositions({
    skip,
    take,
    search,
  }: {
    skip?: number | null;
    take?: number;
    search?: string;
  } = {}): Promise<PaginateResponse<IdName>> {
    const queryParams = buildQueryString({
      skip,
      take,
      search,
    });
    const { data } = await baseAxiosInstance.get<PaginateResponse<SuggestedPositionsT>>(`/position-type/?${queryParams}`);

    return data;
  }

  public static async getBulkStatusChangeCounters() {
    const { data } = await baseAxiosInstance.get<BulkStatusChangeCountersT[]>('/tasks/bulk-status-change-counters');

    return data;
  }
}
