/* eslint-disable @typescript-eslint/naming-convention */
import { Stack } from '@mui/material';
import { AppState } from 'redux/store';
import Spinner from 'components/spinner/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';
import MultipleAssigneeSelector from 'components/ui-new/multiple-assignee-selector/multiple-assignee-selector';
import s from './assignee-cell.module.scss';

const AssigneeCell = ({ value, data }) => {
  const dispatch = useDispatch();

  const taskDepartments = data.department_ids.map((i: string) => ({ id: i })) || [];
  const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];

  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webTaskEditLevel, accessLevels, taskDepartments),
  );

  const isAssignmentInProgress = data.is_assignment_in_progress;

  const task = useSelector((state: AppState) => state.tasks.tasks.data.find((t) => t.id === data.id));
  const isProcessingGuard = task?.locked;

  const mappedSlots = value?.map((assignee) => ({
    id: assignee?.slot_id,
    responsibilityId: assignee?.task_responsibility_id,
    assignedUserId: assignee?.taskAssignment?.user?.id,
    last_name: assignee?.taskAssignment?.user?.last_name,
    first_name: assignee?.taskAssignment?.user?.first_name,
    avatar_image_url: assignee?.taskAssignment?.user?.avatar_image_url,
  }));

  return (
    <Stack height={value?.length > 1 ? '100%' : 'auto'} justifyContent="center" sx={{ cursor: 'pointer' }}>
      {isAssignmentInProgress ? (
        <Stack display="flex" flexDirection="row" alignItems="center">
          {Array.from({ length: Math.min(mappedSlots?.length || 0, 5) }).map((_, index) => {
            const slotId = mappedSlots[index]?.id;
            return (
              <div
                key={slotId}
                style={{ zIndex: mappedSlots.length - index }}
                className={`${s.spinner_container} ${index !== 0 ? s.spinner_margin : ''}`}
              >
                <Spinner size={14} />
              </div>
            );
          })}
        </Stack>
      ) : (
        <MultipleAssigneeSelector
          withHover
          slots={mappedSlots}
          showUserExtraData={false}
          isEditPermitted={isPermitted}
          isProcessingGuard={isProcessingGuard}
          isAvatarOnly={value?.length > 1}
          assignUser={(user, slotId, slot) => {
            dispatch(TasksActions.assignUserToTask(data.id, user, slotId, slot.responsibilityId));
          }}
          unassignUser={(user, slotId, slot) => {
            dispatch(
              TasksActions.unassignUserFromTask(
                data.id,
                {
                  taskAssignment: {
                    user: {
                      avatar_image_url: '',
                      first_name: '',
                      id: '',
                      last_name: '',
                      department: { id: '', name: '' },
                      position: { id: '', name: '' },
                    },
                  },
                  slot_id: slotId,
                  task_responsibility_id: slot.responsibilityId,
                },
                slotId,
              ),
            );
          }}
        />
      )}
    </Stack>
  );
};

export default AssigneeCell;
