import { FC, SVGProps } from 'react';

export const BadgeIcon2: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.3355 10.8531L17.0111 14.9092C16.8894 16.1504 16.792 17.0995 14.5936 17.0995H4.38835C2.18992 17.0995 2.09257 16.1504 1.97089 14.9092L1.6464 10.8531C1.5815 10.1798 1.79242 9.55511 2.17369 9.07649C2.18181 9.06838 2.18181 9.06838 2.18992 9.06026C2.63609 8.51674 3.30941 8.17603 4.06386 8.17603H14.9181C15.6725 8.17603 16.3377 8.51674 16.7758 9.04404C16.7839 9.05215 16.792 9.06026 16.792 9.06838C17.1895 9.547 17.4086 10.1716 17.3355 10.8531Z"
      stroke="#949494"
      strokeMiterlimit="10"
    />
    <path
      d="M2.5957 8.52642V4.3486C2.5957 1.59042 3.28525 0.900879 6.04342 0.900879H7.07368C8.10394 0.900879 8.3392 1.20915 8.72859 1.72833L9.75885 3.10742C10.0184 3.44813 10.1726 3.65905 10.8621 3.65905H12.9307C15.6889 3.65905 16.3785 4.3486 16.3785 7.10677V8.55887"
      stroke="#949494"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.40527 13.043H11.575" stroke="#949494" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
