import { EyeIcon } from 'icons/eye';
import { debounce } from 'utils/debounce';
import Button from 'components/button/button';
import { Persone2Icon } from 'icons/persone-2';
import { UNASSIGNED } from 'constants/unassigned';
import { InactivatedIcon } from 'icons/inactivated';
import { ReactNode, useMemo, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { CircularProgress, TextField } from '@mui/material';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import { AvatarCircle } from 'components/ui-new/avatar-circle/avatar-circle';
import Label from '../label/label';
import s from './modal-item.module.scss';
import Autocomplete from '../ui-new/autocomplete/autocomplete';
import HighlightText from '../highlight-text/highlight-text';
import CustomChip from '../custom-chip/custom-chip';

const NEW_OPTION_ID = 'new';

type Value = {
  id: string;
  name: string;
  is_active?: boolean;
  [key: string]: any;
};

type Props = {
  label: string;
  options: any[];
  newText?: string;
  loading?: boolean;
  isShowSku?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder: string;
  labelStyle?: string;
  isWarning?: boolean;
  value?: Value | null;
  withAvatar?: boolean;
  withNewOption?: boolean;
  idForNewOption?: string;
  hasMoreOptions?: boolean;
  optionClassName?: string;
  startAdornment?: ReactNode;
  isShowBreadcrumbs?: boolean;
  loadDataAfterFocus?: boolean;
  isActiveTooltipTitle?: string;
  onBlur?: () => void;
  onInputOpen?: () => void;
  onViewIconClick?: (id: string, option: any) => void;
  onAutocompleteChange: (event: React.SyntheticEvent, newValue: any) => void;
  loadMoreOptions?: (value?: string, isScroll?: boolean, withoutDelay?: boolean) => void;
  onTextFieldChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
};

const ModalItem = ({
  label,
  value,
  options,
  newText,
  withAvatar,
  labelStyle,
  placeholder,
  withNewOption,
  idForNewOption,
  startAdornment,
  className = '',
  optionClassName,
  loading = false,
  disabled = false,
  isShowSku = false,
  isShowBreadcrumbs = true,
  loadDataAfterFocus = false,
  isActiveTooltipTitle = 'Product is inactive. To enable production for this product, you need to activate it.',
  onBlur,
  onInputOpen,
  loadMoreOptions,
  onViewIconClick,
  onTextFieldChange,
  onAutocompleteChange,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [wasValueChanged, setWasValueChanged] = useState(false);
  const [inputValueCurrent, setInputValueCurrent] = useState('');
  const [wasOptionSelected, setWasOptionSelected] = useState(false);

  const isNameAlreadyExists = Boolean(options.find((i) => i.name === inputValueCurrent));

  // TODO: Decompose logic in custom hook, think about re-render optimizations
  const handleOnClose = (event: React.SyntheticEvent) => {
    if (withNewOption && !wasOptionSelected && inputValueCurrent) {
      const newOption = {
        id: idForNewOption || NEW_OPTION_ID,
        name: inputValueCurrent.replace(/\s*\(new\)$/, ''),
      };

      onAutocompleteChange(event, newOption);
      setWasOptionSelected(false);
    }
    setInputValueCurrent('');
    setWasValueChanged(false);
    setIsOpen(false);
  };

  const handleOptionSelect = (event: React.SyntheticEvent, newValue: Value) => {
    if (withNewOption && newValue?.id === NEW_OPTION_ID) {
      setWasOptionSelected(true);
    } else {
      setTimeout(() => {
        onAutocompleteChange(event, newValue);
        setInputValueCurrent(newValue.name);
        loadMoreOptions?.(newValue.name, false);
      }, 0);
    }
  };

  const handleOnOpen = () => {
    setIsOpen(true);

    if (withNewOption) {
      setWasOptionSelected(false);
    }
    if (onInputOpen) onInputOpen();
  };

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setWasValueChanged(true);
    setInputValueCurrent(event.target.value);
    onTextFieldChange?.(event);
  };

  const handleScroll = async (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    const bottomOffset = scrollHeight - (scrollTop + clientHeight);
    const shouldLoadMore = bottomOffset < 100;
    if (isOpen && shouldLoadMore && !loading) {
      debounce(async () => {
        await loadMoreOptions?.(inputValueCurrent, true);
      }, 200);
    }
  };

  const handleOnFocus = () => {
    if (loadDataAfterFocus && loadMoreOptions) {
      loadMoreOptions('', false, true);
    }
  };

  const handleOnBlur = () => {
    if (value && loadMoreOptions && wasValueChanged) loadMoreOptions(value.name, false);
    if (onBlur) onBlur();
  };

  const filteredOptions = useMemo(() => {
    const withoutSelected = value ? options.filter((item) => item.id !== value?.id) : options;

    return withNewOption && Boolean(inputValueCurrent || value) && !isNameAlreadyExists ? [...withoutSelected] : withoutSelected;
  }, [options, value, inputValueCurrent]);

  return (
    <div>
      <Label labelStyle={labelStyle} className={s.label}>
        {label}
      </Label>

      <Autocomplete
        fullWidth
        size="small"
        open={isOpen}
        value={value}
        disableClearable
        multiple={false}
        loading={loading}
        disabled={disabled}
        className={className}
        isShowSku={isShowSku}
        placeholder="Placeholder"
        options={filteredOptions}
        classes={{
          popper: s.popper,
          option: optionClassName,
          paper: loading ? `${s.paper_loading} ${s.paper}` : s.paper,
        }}
        disableListWrap={false}
        getOptionLabel={(option) => option.name || ''}
        isOptionEqualToValue={(option, item) => option.id === item?.id}
        noOptionsText={
          withNewOption ? (
            <div className={s.new_option_container} onClick={handleOnClose}>
              {inputValueCurrent || value?.name} {newText || '(new)'}
            </div>
          ) : (
            'No options'
          )
        }
        onBlur={handleOnBlur}
        onOpen={handleOnOpen}
        onFocus={handleOnFocus}
        onClose={handleOnClose}
        onChange={handleOptionSelect}
        renderInput={(params) => (
          <TextField
            {...params}
            size="medium"
            className={s.input}
            placeholder={placeholder}
            onChange={handleTextFieldChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {params.InputProps.endAdornment}
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {!!value && !value?.is_active && Object.keys(value).includes('is_active') && (
                    <CustomChip text="Inactive" tooltipTitle={isActiveTooltipTitle} icon={<InactivatedIcon />} type="red" />
                  )}
                </InputAdornment>
              ),
              startAdornment: startAdornment ? (
                <InputAdornment className={s.start_icon} position="start">
                  {startAdornment}
                </InputAdornment>
              ) : null,
            }}
            ref={params.InputProps.ref}
          />
        )}
        renderOption={(props, option, { inputValue }) => {
          let maxWidth: string;
          if (option.is_active && !!onViewIconClick) {
            maxWidth = '375px';
          } else if (!option.is_active && !!onViewIconClick) {
            maxWidth = '275px';
          } else {
            maxWidth = '410px';
          }
          return (
            <li {...props} key={isShowSku ? option.configurations[0]?.sku : option.id}>
              <div className={s.item_container}>
                <div className={s.icon_with_chip}>
                  <div className={option.icon ? s.icon_with_title : `${s.icon_with_title} ${s.text}`}>
                    {withAvatar && (
                      <AvatarCircle
                        className={s.avatar}
                        image={<Persone2Icon />}
                        imageSrc={option.avatar || ''}
                        lastName={option.lastName || ''}
                        isEmpty={option.id === UNASSIGNED}
                        firstName={option.firstName || ''}
                      />
                    )}
                    <div className={option.icon ? s.icon : ''}>{option.icon ? option.icon : ''}</div>
                    <HighlightText text={option.name} query={inputValue} maxWidth={maxWidth} />
                  </div>
                  <div className={s.chip_with_button}>
                    {!option.is_active && Object.keys(option).includes('is_active') && (
                      <CustomChip
                        className={s.chip}
                        text="Inactive"
                        tooltipTitle={isActiveTooltipTitle}
                        icon={<InactivatedIcon />}
                        type="red"
                      />
                    )}
                    {!!onViewIconClick && (
                      <Button
                        className={s.button}
                        size="XS"
                        variant="outlined"
                        color="secondary"
                        onClick={(e) => {
                          e.stopPropagation();
                          onViewIconClick(option.id, option);
                        }}
                      >
                        <EyeIcon width="16px" height="16px" />
                      </Button>
                    )}
                  </div>
                </div>

                {isShowBreadcrumbs && option.breadcrumbs ? (
                  <TextEllipsis maxWidth="400px" className={s.breadcrumbs_container}>
                    <span className={`${s.title} ${s.breadcrumbs}`}>{option.breadcrumbs}</span>
                  </TextEllipsis>
                ) : (
                  ''
                )}
                {isShowSku && option.configurations.length ? (
                  <TextEllipsis maxWidth="400px" className={s.breadcrumbs_container}>
                    {option.configurations && option.configurations[0] && (
                      <div className={`${s.title} ${s.breadcrumbs}`}>
                        <HighlightText text={`SKU-${option.configurations[0].sku}`} query={inputValue} maxWidth={maxWidth} />
                      </div>
                    )}
                  </TextEllipsis>
                ) : (
                  ''
                )}
              </div>
            </li>
          );
        }}
        ListboxProps={{
          ...(loadMoreOptions && {
            onScroll: async (event) => {
              handleScroll(event);
            },
          }),
        }}
      />
    </div>
  );
};

export default ModalItem;
