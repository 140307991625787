import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardActions, PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';
import { TaskStatusEnum } from 'types/status-enums';
import s from './money-cell.module.scss';

const MoneyCell = ({ value, type, data }) => {
  const dispatch = useDispatch();
  const currency = useSelector((state: AppState) => state.settings.currency);

  const taskDepartments = data.department_ids.map((id) => ({ id }));
  const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];

  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webTaskEditLevel, accessLevels, taskDepartments),
  );

  const isBasicRewardEditable =
    type === 'basic_reward' && !data.is_reporting_period_closed && data.status !== TaskStatusEnum.Done;

  const task = useSelector((state: AppState) => state.tasks.tasks.data.find((t) => t.id === data.id));
  const isProcessingGuard = task?.locked;

  const openBasicRewardModal = () => {
    if (!isPermitted) {
      dispatch(PermissionGuardActions.openModal());
      return;
    }

    if (isProcessingGuard) {
      dispatch(PermissionGuardActions.openModal(isProcessingGuard));

      return;
    }

    if (isBasicRewardEditable) {
      dispatch(TasksActions.openEditBasicRewardModal(data.id));
    }
  };

  return (
    <button className={s.button} type="button" data-pressable={isBasicRewardEditable} onClick={openBasicRewardModal}>
      {value} {currency}
    </button>
  );
};

export default MoneyCell;
