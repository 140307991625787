import { useKeyPress } from 'ahooks';
import { debounce } from 'utils/debounce';
import { IdName } from 'types/common-types';
import { PaginateResponse } from 'types/paginate-response';
import { useEffect, useMemo, useRef, useState } from 'react';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { PAGE_SIZE } from 'components/ui-new/dropdown-search-chips-selector/constants';
import { ProductionValueIdNameT } from 'pages/production/controllers/production-filters-controller/types';

export type PaginationOptions = { skip: number; take: number };
export type FetchDataT = (keyword: string, paginationOptions?: PaginationOptions) => any;

export type FormattedItem = IdName & {
  [key: string]: any;
};

type Args = {
  options: IdName[];
  isOpen: boolean;
  values: ProductionValueIdNameT[];
  onOpen?: VoidFunction;
  onClose?: VoidFunction;
  fetchData?: FetchDataT;
  onSelect: (values: ProductionValueIdNameT[], newValue: ProductionValueIdNameT, checked: boolean) => void;
  setIsOpen: (value: boolean) => void;
  onChangeInput?: (value: any) => void;
  dataFormatting?: (data: any[]) => any[];
};

export const useDropdownSearchChipsSelector = ({
  values,
  options,
  isOpen,
  onOpen,
  onClose,
  onSelect,
  fetchData,
  setIsOpen,
  onChangeInput,
  dataFormatting,
}: Args) => {
  const [keyword, setKeyword] = useState('');
  const [lastPage, setLastPage] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [isLazyProcessing, setIsLazyProcessing] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(fetchData ? PAGE_SIZE : options.length);

  const timerRef = useRef<NodeJS.Timeout>();
  const rootRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLDivElement>(null);
  const chipRefs = useRef<Array<HTMLDivElement | null>>([]);

  const popupState = usePopupState({ variant: 'popper', popupId: `multiple-search-chips-selector` });

  popupState.close = () => {
    setIsOpen(false);
  };

  const sortedOptionsList = useMemo(() => {
    const propsOptions = [...options];
    let sortedList: IdName[] = [];
    if (lastPage) {
      sortedList = propsOptions;
    } else {
      sortedList = propsOptions.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });
    }
    return sortedList;
  }, [options, lastPage]);

  const filteredList = useMemo(() => {
    const uniqueOptions = Array.from(new Map(sortedOptionsList.map((item) => [item.id, item])).values());

    return uniqueOptions
      .filter((item) => item.name.trim() !== '')
      .filter((item) => !values.some((el) => el.id === item.id))
      .filter((item) => item.name.toLowerCase().includes(keyword.toLowerCase()));
  }, [keyword, sortedOptionsList, values]);

  const isCheckedHeaderCheckbox = (item: ProductionValueIdNameT) => {
    const itemId = item.id;

    return Array.isArray(itemId)
      ? values.some((value) => itemId.some((innerItem) => innerItem === value.id))
      : values.some((value) => value.id.includes(itemId));
  };

  const handleFetchData = async (search: string, paginationOptions?: PaginationOptions) => {
    setIsFetchingData(true);

    if (fetchData) {
      const data = (await fetchData(search, paginationOptions)) as PaginateResponse<IdName>;
      const dataMeta: PaginateResponse<IdName>['meta'] = data.meta;

      if (data && 'meta' in data && 'data' in data) {
        if (dataFormatting && onChangeInput) {
          onChangeInput(dataFormatting(data.data));
        } else if (onChangeInput) {
          onChangeInput(data.data);
        }

        setLastPage(dataMeta.lastPage);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (dataFormatting && onChangeInput) {
          onChangeInput(dataFormatting(data as unknown as IdName[]));
        } else if (onChangeInput) {
          onChangeInput(data);
        }
      }
    }

    if (values.length) {
      setItemsPerPage(values.length + PAGE_SIZE);
    } else {
      setItemsPerPage(PAGE_SIZE);
    }

    setCurrentPage(1);
    setIsFetchingData(false);
    setKeyword(search);
  };

  const openDropdown = async () => {
    setIsOpen(true);

    if (!isOpen && onOpen) {
      onOpen();
    }

    if (searchInputRef) {
      setTimeout(() => {
        searchInputRef.current?.focus();
      });
    }

    if (values.length) {
      setItemsPerPage(values.length + PAGE_SIZE);
      handleFetchData('', { take: values.length + PAGE_SIZE, skip: 0 });
    } else {
      setItemsPerPage(PAGE_SIZE);
      handleFetchData('', { take: PAGE_SIZE, skip: 0 });
    }
  };

  const handleInput = (value: string) => {
    if (!fetchData || !onChangeInput) {
      setInputValue(value);
      return setKeyword(value.trim());
    }

    let paginationOptions: PaginationOptions;
    if (values.length) {
      paginationOptions = { skip: 0, take: values.length + PAGE_SIZE };
    } else {
      paginationOptions = { skip: 0, take: PAGE_SIZE };
    }
    debounce(() => handleFetchData(value.trim(), paginationOptions), 600, timerRef);
    setCurrentPage(0);

    return setInputValue(value);
  };

  const getData = async (isListScrolled: boolean) => {
    if (fetchData) {
      let formattedData: FormattedItem[] = [];
      setIsLazyProcessing(true);
      const data = (await fetchData(keyword, {
        take: PAGE_SIZE,
        skip: itemsPerPage,
      })) as PaginateResponse<IdName>;
      if (dataFormatting) {
        formattedData = dataFormatting(data.data);
      } else {
        formattedData = data.data;
      }
      if (isListScrolled) {
        setCurrentPage(currentPage + 1);
        setItemsPerPage(itemsPerPage + PAGE_SIZE);
      }
      onChangeInput?.([...options, ...formattedData]);
      setIsLazyProcessing(false);
    }
  };

  const loadData = async (isListScrolled: boolean, withDebounce: boolean = false) => {
    if (lastPage && fetchData) {
      if (withDebounce) {
        debounce(() => getData(isListScrolled), 200);
      } else {
        getData(isListScrolled);
      }
    } else {
      setItemsPerPage(itemsPerPage + PAGE_SIZE);
    }
  };

  const setValues = (item: ProductionValueIdNameT) => {
    // Dismisses undefined header check on regular property set
    if (values.some((value) => value.id.includes('undefined'))) {
      return onSelect([item], item, !isCheckedHeaderCheckbox(item));
    }

    if (values.some((value) => value.id === item.id)) {
      if (filteredList.length + 1 < PAGE_SIZE) loadData(true);
      return onSelect(
        values.filter((value) => value.id !== item.id),
        item,
        !isCheckedHeaderCheckbox(item),
      );
    }

    if (filteredList.length - 1 < PAGE_SIZE) loadData(true);
    return onSelect([...values, item], item, !isCheckedHeaderCheckbox(item));
  };

  const onOptionSelect = (item: ProductionValueIdNameT) => {
    if (item.id.includes('undefined')) {
      // Dismisses undefined header check on the second undefined click
      if (values.some((value) => value.id.includes('undefined'))) return onSelect([], item, !isCheckedHeaderCheckbox(item));

      return onSelect([item], item, !isCheckedHeaderCheckbox(item));
    }

    // User can have many departments which are stored in an array that has to be processed differently
    if (Array.isArray(item.id)) {
      return item.id.forEach((value) =>
        setValues({ id: value, name: options.find((option) => option.id === value)?.name || '' }),
      );
    }

    return setValues(item);
  };

  const handleScroll = async (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const bottomOffset = scrollHeight - (scrollTop + clientHeight);
    const shouldLoadMore = bottomOffset < 100 && currentPage !== lastPage;

    if (isOpen && shouldLoadMore && scrollTop !== 0) loadData(true, true);
  };

  useKeyPress('esc', () => isOpen && setIsOpen(false));

  useEffect(() => {
    setCurrentPage(1);
    if (!isOpen) {
      setInputValue('');
      setLastPage(0);
      setItemsPerPage(PAGE_SIZE);
      if (onClose) onClose();
    }
  }, [isOpen]);

  return {
    isOpen,
    keyword,
    rootRef,
    chipRefs,
    inputValue,
    popupState,
    itemsPerPage,
    filteredList,
    isFetchingData,
    searchInputRef,
    isLazyProcessing,
    setIsOpen,
    handleInput,
    handleScroll,
    openDropdown,
    onOptionSelect,
    isCheckedHeaderCheckbox,
  };
};
