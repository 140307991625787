import { baseAxiosInstance } from 'axios-config';
import { BreadcrumbItem } from 'components/ui-new/breadcrumbs/components/helpers/helpers';
import { BreadcrumbsQuery } from 'services/breadcrumbs.model';

export class BreadcrumbsService {
  public static async getBreadcrumbs(query: BreadcrumbsQuery) {
    const { data } = await baseAxiosInstance.get<BreadcrumbItem[]>(`/breadcrumbs`, { params: query });

    return data;
  }
}
