import {
  MapAssignAtArgs,
  UpdateTasksParamsArgs,
  UpdatedTaskResponsibilitiesValuesReturnT,
  UpdateTasksFromStatusChangeWsResponseArgs,
} from 'pages/tasks/types/function-types';
import { IdName } from 'types/common-types';
import { UNASSIGNED } from 'constants/unassigned';
import { BulkSCProcessedState, SelectedTaskT, TaskTableStateT } from 'pages/tasks/types/types';
import { CreateUpdateTaskResponse } from 'services/production-task.model';
import { AssignResponsibleToTasksBodyArgs, BulkStatusChangeCountersT } from 'services/task-table.model';

export const getAllTasksOnTheScreen = (tasks: TaskTableStateT[] = []): SelectedTaskT[] =>
  tasks.map(({ id, department_ids, status, locked, is_in_queue, production_status, is_reporting_period_closed, task_slots }) => ({
    id,
    departmentIds: department_ids,
    slots: task_slots.map(({ id: slot_id, task_responsibility_id }) => ({ slot_id, task_responsibility_id })),
    status,
    locked,
    is_in_queue,
    production_status,
    is_reporting_period_closed,
  }));

export const getResponsibleId = (responsibleId: string | undefined): string | null => {
  if (responsibleId === UNASSIGNED) return null;
  return responsibleId || null;
};

export const prepareRequestBodyForSingleBulkAssignUsers = (
  performerId: string | null,
  tasks: SelectedTaskT[],
): AssignResponsibleToTasksBodyArgs[] => {
  const taskSlotIds = tasks.flatMap((task) => task.slots.map((slot) => slot.slot_id)).filter((slotId) => slotId);

  return [
    {
      user_id: performerId,
      task_slot_ids: taskSlotIds,
    },
  ];
};

export const prepareRequestBodyForMultipleBulkAssignUsers = (
  performers: Record<string, string | null>,
  tasks: SelectedTaskT[],
): AssignResponsibleToTasksBodyArgs[] => {
  const performerEntries = Object.entries(performers).map(([index, userId]) => ({ index: Number(index), userId }));

  return performerEntries.map(({ index, userId }) => {
    const taskSlotIds = tasks.map((task) => task.slots[index]?.slot_id);

    return {
      user_id: userId,
      task_slot_ids: taskSlotIds,
    };
  });
};

export const collectAllTaskSlotIds = (assignments: AssignResponsibleToTasksBodyArgs[]): string[] => {
  return assignments.flatMap((assignment) => assignment.task_slot_ids);
};

export const updateTasksFromWsResponse = ({ id, slotId, user, tasks, isUnassignFinishedEvent }: UpdateTasksParamsArgs) => {
  const currentAssignAt = new Date().toISOString();

  return {
    ...tasks,
    data: tasks.data.map((task) => {
      if (task.id !== id) {
        return task;
      }
      // !remember columns may be inactive
      const newPositions = isUnassignFinishedEvent
        ? []
        : user?.position?.map((pos) => ({
            id: pos.id,
            name: pos.name,
            slot_id: slotId,
          })) || [];

      const newDepartments = isUnassignFinishedEvent
        ? []
        : user?.department?.map((dep) => ({
            id: dep.id,
            name: dep.name,
            slot_id: slotId,
          })) || [];

      return {
        ...task,
        assignee:
          task.assignee?.map((assignee) => {
            if (assignee.slot_id === slotId) {
              return {
                ...assignee,
                taskAssignment: isUnassignFinishedEvent ? null : { user },
              };
            }
            return assignee;
          }) || [],
        task_slots: task.task_slots?.map((slot) => {
          if (slot.id === slotId) {
            return {
              ...slot,
              ...(isUnassignFinishedEvent ? {} : { task_assignment_id: user?.id }),
            };
          }
          return slot;
        }),
        is_assignment_in_progress: false,
        assignee_position: [...(task.assignee_position?.filter((pos) => pos.slot_id !== slotId) || []), ...newPositions],
        assignee_department: [...(task.assignee_department?.filter((dep) => dep.slot_id !== slotId) || []), ...newDepartments],
        assigned_at:
          task.assigned_at?.map((assigned) => {
            if (assigned.id === slotId) {
              return {
                id: slotId,
                name: isUnassignFinishedEvent ? '' : currentAssignAt,
              };
            }
            return assigned;
          }) || [],
      };
    }),
  };
};

export const updateTasksFromStatusChangeWsResponse = ({
  id,
  status,
  tasks,
  is_in_queue,
  locked,
  is_reporting_period_closed,
}: UpdateTasksFromStatusChangeWsResponseArgs) => {
  return {
    ...tasks,
    data: tasks.data.map((task) => {
      if (task.id !== id) {
        return task;
      }

      return {
        ...task,
        status,
        is_in_queue,
        locked,
        is_reporting_period_closed,
      };
    }),
  };
};

export const extractUpdatedTaskResponsibilitiesValues = (data: CreateUpdateTaskResponse) => {
  return data.responsibilities.reduce<UpdatedTaskResponsibilitiesValuesReturnT>(
    (acc, responsibility) => {
      responsibility.taskSlots.forEach((taskSlot) => {
        const { taskAssignment } = taskSlot;

        if (taskAssignment?.assign_at) {
          acc.assignAt.push({
            id: taskSlot.id,
            name: taskAssignment.assign_at,
          });
        }

        if (taskAssignment) {
          const { user } = taskAssignment;
          if (user?.user_position_slots?.length) {
            user.user_position_slots.forEach((positionSlot) => {
              const position = positionSlot?.position_slot;

              acc.positions.push({
                id: positionSlot?.position_slot?.position_type_id,
                name: position?.position_type?.name || '',
                slot_id: taskSlot.id,
              });
              acc.departments.push({
                id: positionSlot?.position_slot?.department_id,
                name: position?.department?.name || '',
                slot_id: taskSlot.id,
              });
            });
          }
        }
      });

      return acc;
    },
    {
      positions: [],
      departments: [],
      assignAt: [],
    },
  );
};

export const mapAssignAt = ({ assignee, assigned_ats = [] }: MapAssignAtArgs): IdName[] => {
  return assignee.map((slot) => {
    const assigned_at = slot.taskAssignment ? assigned_ats.shift() || '' : '';

    return {
      id: slot.slot_id,
      name: assigned_at,
    };
  });
};

export const mapBulkStatusChangeCountersToState = (
  counters: BulkStatusChangeCountersT[],
): Record<string, BulkSCProcessedState> => {
  return counters
    .filter((item) => item.processed !== item.total)
    .reduce((acc, item) => {
      acc[item.bulkStatusChangeId] = {
        currentNumberOfTaskForProcessing: item.processed,
        totalNumberOfTasksForProcessing: item.total,
        finishedTasks: item.processed,
        failedTasks: item.failedTasks ? item.failedTasks.map((t) => ({ taskId: t.id, taskKey: t.taskKey })) : [],
        isShow: true,
      };
      return acc;
    }, {} as Record<string, BulkSCProcessedState>);
};
