import dayjs from 'dayjs';
import {
  DeadlineUpdateBody,
  Nodes,
  TaskNodeType,
  WorkflowNodeType,
} from 'pages/production-workflow/controllers/production-workflow.controller';
import { ShouldTriggerDeadlineWarningArgs } from 'pages/production-workflow/controllers/types';
import {
  ProductionIssueEnum,
  ProductionWorkflowHierarchy,
  ProductionWorkflowItems,
  ProductionWorkflowTaskT,
} from 'services/production-workflow.model';
import { IdName } from 'types/common-types';
import { ProductionStatusEnum } from 'types/status-enums';

const getProductionIssues = (production: ProductionWorkflowItems['nestedProductionWorkflow']) => {
  const issues: IdName[] = [];

  if (!production?.product_variant_id) {
    issues.push({
      id: ProductionIssueEnum.UndefinedProduct,
      name: 'Undefined product from <external system name>. Barcode <Product ID from external system>',
    });
  }
  if (production?.deadline_at && new Date(production.deadline_at) < new Date()) {
    issues.push({
      id: ProductionIssueEnum.ProductionDeadlineExpired,
      name: 'The deadline has been reached already',
    });
  }
  if (production?.nested_production_component_has_issues || production?.additional_component_has_issue) {
    issues.push({
      id: ProductionIssueEnum.IssuesInNestedComponents,
      name: 'Nested production component has an issue',
    });
  }
  if (production?.is_any_task_time_limit_exceeded) {
    issues.push({
      id: ProductionIssueEnum.TaskTimeLimitExceeded,
      name: 'The task tracker time has exceeded the task time limit',
    });
  }
  if (production?.is_manual_assignmet_required) {
    issues.push({
      id: ProductionIssueEnum.TasksRequiringManualAssignment,
      name: 'Manual assignment for a task required',
    });
  }

  return issues;
};

export const getTasksIssues = (task?: ProductionWorkflowTaskT) => {
  if (!task) {
    return [];
  }
  const issues: IdName[] = [];
  // task’s time_limit is stored in minutes. total_in_progress_time and total_in_progress_overtime in seconds
  const isTimeLimitExceeded = task.time_limit && task.total_in_progress_time / 60 > task.time_limit;
  const isManualAssignment = task.responsibilities.some(
    (responsibility) =>
      responsibility.assigment_type === 'Manual' && responsibility.taskSlots.some((slot) => !slot.task_assignment_id),
  );

  if (isManualAssignment) {
    issues.push({
      id: ProductionIssueEnum.TasksRequiringManualAssignment,
      name: `Manual assignment for a ${task.is_additional ? 'additional task' : 'task'} required`,
    });
  }

  if (isTimeLimitExceeded) {
    issues.push({
      id: ProductionIssueEnum.TaskTimeLimitExceeded,
      name: 'The task tracker time has exceeded the task time limit',
    });
  }

  return issues;
};

export const getAllIssues = (issue: IdName, itemName: string, itemType: string | undefined) => {
  if (issue.id === ProductionIssueEnum.IssuesInNestedComponents) {
    return `${itemName} production has issues in nested components`;
  }
  if (issue.id === ProductionIssueEnum.TasksRequiringManualAssignment) {
    return `${
      itemType === 'task' || itemType === 'task_additional'
        ? `Assign a performer to the ${itemType === 'task' ? 'task' : 'additional task'} "${itemName}".`
        : `Manual assignment for tasks required in "${itemName}" production.`
    }`;
  }
  if (issue.id === ProductionIssueEnum.TaskTimeLimitExceeded) {
    let itemLabel;
    switch (itemType) {
      case 'task':
        itemLabel = itemType;
        break;
      case 'task_additional':
        itemLabel = 'additional task';
        break;
      case 'production':
        itemLabel = itemType;
        break;
      default:
        itemLabel = 'task';
        break;
    }
    return `The ${
      itemType === 'task' || itemType === 'task_additional' ? 'task' : 'production'
    } tracker time has exceeded the task time limit for "${itemName}" ${itemLabel}.`;
  }
  if (issue.id === ProductionIssueEnum.ProductionDeadlineExpired) {
    return `The deadline has been reached for "${itemName}" production`;
  }
  if (issue.id === ProductionIssueEnum.UndefinedProduct) {
    return `${itemName} has undefined product from <external system name>. Barcode <Product ID from external system>`;
  }
  return issue.name;
};

export const generateNodes = (
  item: ProductionWorkflowItems,
  productionStatus: ProductionStatusEnum,
  isRootCompleted: boolean,
): Nodes => {
  if (item.task) {
    const task: TaskNodeType = {
      id: item.id,
      type: 'task',
      position: { x: item.position_x, y: item.position_y },
      data: {
        productionStatus,
        id: item.task.id,
        name: item.task.name,
        locked: item.task.locked,
        status: item.task.status,
        time_limit: item.task.time_limit,
        isReopened: item.task.is_reopened,
        is_in_queue: item.task.is_in_queue,
        finishedAt: item.task.status_changed_at,
        disable: !!item.task.reopened_tasks?.length,
        responsibilities: item.task.responsibilities,
        departmetsAll: item.task.departmets_all || [],
        total_in_progress_time: item.task.total_in_progress_time,
        is_reporting_period_closed: item.task.is_reporting_period_closed,
        issues: item.task.reopened_tasks?.length ? [] : getTasksIssues(item.task),
      },
    };

    const lastReopenedTask = item.task.reopened_tasks?.[item.task.reopened_tasks.length - 1];

    const reopenedTask: TaskNodeType | undefined = lastReopenedTask?.id
      ? {
          id: lastReopenedTask.id,
          type: 'task',
          position: { x: item.position_x + 37, y: item.position_y + 7 },
          data: {
            disable: false,
            isReopened: true,
            productionStatus,
            id: lastReopenedTask.id,
            name: lastReopenedTask.name,
            status: lastReopenedTask.status,
            locked: lastReopenedTask.locked,
            time_limit: lastReopenedTask.time_limit,
            issues: getTasksIssues(lastReopenedTask),
            is_in_queue: lastReopenedTask.is_in_queue,
            finishedAt: lastReopenedTask.status_changed_at,
            responsibilities: lastReopenedTask.responsibilities,
            total_in_progress_time: lastReopenedTask.total_in_progress_time,
            is_reporting_period_closed: lastReopenedTask.is_reporting_period_closed,
          },
        }
      : undefined;

    return [task, reopenedTask].filter((reopenTask) => reopenTask) as Nodes;
  }

  const nestedWorkflow: WorkflowNodeType = {
    id: item.id,
    type: 'workflow',
    position: { x: item.position_x, y: item.position_y },
    data: {
      productionStatus,
      wasChanged: item.was_changed,
      id: item?.nestedProductionWorkflow?.id || '',
      name: item?.nestedProductionWorkflow?.title || '',
      version: item?.nestedProductionWorkflow?.version || 0,
      progress: item?.nestedProductionWorkflow?.progress ?? 0,
      workflow: item?.nestedProductionWorkflow?.workflow || '',
      finishedAt: item?.nestedProductionWorkflow?.finished_at || '',
      responsible: item?.nestedProductionWorkflow?.responsible || null,
      variantName: item?.nestedProductionWorkflow?.variant_name || '',
      configuration: item?.nestedProductionWorkflow?.configuration || '',
      productVariantId: item?.nestedProductionWorkflow?.product_variant_id || '',
      status: item?.nestedProductionWorkflow?.status || ProductionStatusEnum.To_Do,
      issues: item?.nestedProductionWorkflow ? getProductionIssues(item.nestedProductionWorkflow) : [],
      isRootCompleted,
    },
  };

  return [nestedWorkflow];
};

export const getParentAndChildProductions = (hierarchy: ProductionWorkflowHierarchy[]) => {
  const parentProduction = hierarchy.find((item) => item.is_main);
  const childProductions = hierarchy.filter((item) => !item.is_main);

  return { parentProduction, childProductions };
};

export const getBiggestChildDeadline = (childProductions: ProductionWorkflowHierarchy[]) => {
  return childProductions.reduce((latest, current) => {
    return dayjs(current.deadline_at).isAfter(dayjs(latest.deadline_at)) ? current : latest;
  }).deadline_at;
};

export const shouldTriggerDeadlineWarning = ({
  newDeadline,
  parentProduction,
  childProductions,
  targetId,
  productionIds,
  hierarchy,
}: ShouldTriggerDeadlineWarningArgs) => {
  const biggestDeadlineOfChild = getBiggestChildDeadline(childProductions);
  const isChildDeadlineBiggerThanParent = dayjs(newDeadline).isAfter(dayjs(parentProduction?.deadline_at));
  const isParentDeadlineSmallerThanChild = dayjs(newDeadline).isBefore(dayjs(biggestDeadlineOfChild));
  const isTargetParentProduction = parentProduction?.id === targetId;
  const isHierarchyNotEqualProductionsSelected = hierarchy.length - 1 !== productionIds.length;
  const isParentProductionSelected = productionIds.some((item) => item === parentProduction?.id);

  if (isTargetParentProduction && isParentDeadlineSmallerThanChild && isHierarchyNotEqualProductionsSelected) {
    return true;
  }

  if (!isTargetParentProduction) {
    const childWarningCondition = !isParentProductionSelected && isChildDeadlineBiggerThanParent;
    const parentWarningCondition =
      isParentProductionSelected && isParentDeadlineSmallerThanChild && isHierarchyNotEqualProductionsSelected;

    if (parentWarningCondition || childWarningCondition) {
      return true;
    }
  }

  return false;
};

export const formatToISODate = ({ day, hours, minutes, month, year }: DeadlineUpdateBody) => {
  const date = new Date(year, month - 1, day, hours, minutes);
  return date.toISOString();
};
