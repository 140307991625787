import { AppState, GetStateFunction } from 'redux/store';
import {
  CreateProductConfigurationData,
  ManageProductConfigurationsData,
  ProductConfiguration,
} from 'services/product-configurations.model';
import { ProductConfigurationService } from 'services/product-configurations.service';
import { Actions as ProductWorkFlowLayoutActions } from 'pages/product-flow/product-flow-layout/product-flow-layout.controller';
import { Actions as ProductRootController } from 'pages/product-flow/pages/product/controllers/product-root.controller';
import { StateController } from 'state-controller';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { copyWithCounter } from 'utils/copy-with-counter';
import { v4 as uuidv4 } from 'uuid';
import { notify } from 'notifications';
import { ProductConfigurationTab, RelatedProductResponse } from 'services/products.model';
import { IdNameOrder } from 'types/common-types';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { ActiveTickIcon } from 'icons/active-tick';
import FireMinusIcon from 'icons/fire-minus';
import { MODALS } from 'modules/root-modals/modals';
import { ProductsService } from 'services/products.service';
import s from '../product.module.scss';

export const PRODUCT_CONFIGURATION_TAB_WARNING_TEXT = 'Product configuration with warnings ';

export type ProductConfigurationValidations = {
  errors: {
    warnings: string;
  };
};

export type ProductConfigurationsState = {
  product_configurations: ProductConfigurationTab[];
  active_configuration: ProductConfiguration;
  isProcessing: boolean;
  isInitLoading: boolean;
  validations: ProductConfigurationValidations;
  relatedProductModal: {
    isOpen: boolean;
    productName: string;
    productVersion: string;
    relatedProduct: RelatedProductResponse[];
  };
};

const validationsDefaultState: ProductConfigurationValidations = {
  errors: {
    warnings: '',
  },
};

const defaultState: ProductConfigurationsState = {
  product_configurations: [],
  active_configuration: {
    id: '',
    name: '',
    description: '',
    sku: '',
    order: 0,
    breadcrumbs: [],
    is_active: true,
  },
  isProcessing: false,
  isInitLoading: false,
  validations: validationsDefaultState,
  relatedProductModal: {
    isOpen: false,
    productName: '',
    productVersion: '',
    relatedProduct: [],
  },
};

const stateController = new StateController<ProductConfigurationsState>('PRODUCT_CONFIGURATIONS_STATE', defaultState);
export class Actions {
  public static init(configurationId: string) {
    return async (dispatch) => {
      dispatch(ProductWorkFlowLayoutActions.saveProductRoad());
      dispatch(stateController.setState({ isInitLoading: true }));
      try {
        const configurationInfo = await ProductConfigurationService.getConfigurationInfo(configurationId);
        dispatch(ProductWorkFlowLayoutActions.setProductRoad(configurationInfo.id, configurationInfo.name));
        await dispatch(stateController.setState({ active_configuration: configurationInfo, isInitLoading: false }));
        // const sortedConfigurations = [...configurations]
        //   .sort((a, b) => a.order - b.order)
        //   .map((item) => ({
        //     ...item,
        //     workflow_templates: [...item.workflow_templates].sort((a, b) => a.order - b.order),
        //     product_variants: item.product_variants
        //       .map((variant) => ({
        //         ...variant,
        //         product_variant_photos: variant.product_variant_photos.sort((a, b) => {
        //           const left = a.is_show_by_default ? 1 : 0;
        //           const right = b.is_show_by_default ? 1 : 0;
        //           return right - left;
        //         }),
        //       }))
        //       .sort((a, b) => {
        //         const left = a.is_active ? 1 : 0;
        //         const right = b.is_active ? 1 : 0;
        //         return right - left;
        //       }),
        //   }));
      } finally {
        dispatch(stateController.setState({ isInitLoading: false }));
      }
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setInitLoading() {
    return (dispatch) => {
      dispatch(stateController.setState({ isInitLoading: true }));
    };
  }

  public static setConfigurations(product_configurations: any[]) {
    return async (dispatch) => {
      await dispatch(stateController.setState({ product_configurations }));
      dispatch(Actions.revalidate());
    };
  }

  public static setIsProcessing(value: boolean) {
    return (dispatch) => {
      dispatch(stateController.setState({ isProcessing: value }));
    };
  }

  public static updateProductNameInBreadcrumbs(value: string) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          active_configuration: {
            ...prev.active_configuration,
            breadcrumbs: prev.active_configuration.breadcrumbs.map((item) => {
              if (item.type === 'product') {
                return {
                  ...item,
                  name: value,
                };
              }

              return item;
            }),
          },
        })),
      );
    };
  }

  public static setActiveConfiguration(configurationId: string) {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isProcessing: true,
          })),
        );
        const active_configuration = await ProductConfigurationService.getConfigurationInfo(configurationId);
        await dispatch(stateController.setState({ active_configuration }));
        dispatch(Actions.revalidate());
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isProcessing: false,
          })),
        );
      }
    };
  }

  public static changeConfigurationData(id: string, data: Partial<ProductConfiguration>) {
    return (dispatch, getState: GetStateFunction) => {
      const { product_configurations } = getState().product.product_configurations;
      const mapped = product_configurations.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            ...data,
          };
        }
        return item;
      });
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_configurations: mapped,
        })),
      );
    };
  }

  public static addConfigurationTemplate() {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { product_configurations } = getState().product.product_configurations;

      const nextConfigurationOrder = product_configurations.length
        ? Math.max(...product_configurations.map(({ order }) => order)) + 1
        : 0;

      const newConfiguration: IdNameOrder = {
        id: 'new',
        name: '',
        order: nextConfigurationOrder,
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_configurations: [...prev.product_configurations, { ...newConfiguration }],
        })),
      );
    };
  }

  public static addConfiguration(name: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { id } = getState().product.product_root;
      const { product_configurations } = getState().product.product_configurations;
      const configurationTemplate = product_configurations.find((configuration) => configuration.id === 'new');

      const newConfigurationData: CreateProductConfigurationData = {
        name,
        sku: '',
        product_id: id,
        description: '',
        is_active: true,
        order: configurationTemplate?.order || 0,
      };

      try {
        const configuration = await ProductConfigurationService.create(newConfigurationData);
        dispatch(ProductRootController.updateProductModifiedAt());
        const { id: configId, order } = configuration;

        dispatch(Actions.deleteConfigurationTemplate());
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_configurations: [
              ...prev.product_configurations,
              {
                id: configId,
                name,
                order,
                is_valid: false,
                is_active: true,
              },
            ],
          })),
        );
        dispatch(Actions.revalidate());
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, product_configurations })));
      }
    };
  }

  public static duplicateConfiguration(configuration: ProductConfiguration, configurationNames: string[]) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const temporaryId = uuidv4();
      const { product_configurations } = getState().product.product_configurations;

      const nextConfigurationOrder = product_configurations.length
        ? Math.max(...product_configurations.map(({ order }) => order)) + 1
        : 0;

      try {
        const response = await ProductConfigurationService.duplicate({
          id: configuration.id,
          name: copyWithCounter(configuration.name, configurationNames),
          order: nextConfigurationOrder,
        });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_configurations: [
              ...prev.product_configurations,
              {
                id: temporaryId,
                name: copyWithCounter(configuration.name, configurationNames),
                order: nextConfigurationOrder,
                is_valid: false,
                is_active: true,
              },
            ],
          })),
        );

        const { id: configId, name, order } = response;
        dispatch(ProductRootController.updateProductModifiedAt());

        dispatch(Actions.changeConfigurationData(temporaryId, { id: configId, name, order }));
        dispatch(Actions.revalidate());

        notify.success('Successfully created');
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, product_configurations })));
      }
    };
  }

  public static onConfigurationRename(configurationId: string, name: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { id } = getState().product.product_root;
      const { product_configurations, active_configuration } = getState().product.product_configurations;
      const originalConfiguration = product_configurations.find((configuration) => configuration.id === configurationId);

      try {
        await ProductConfigurationService.update(configurationId, {
          ...originalConfiguration,
          name,
          product_id: id,
        });
        dispatch(ProductRootController.updateProductModifiedAt());

        const modifyConfigurations = product_configurations.map((configuration) => {
          if (configurationId === configuration.id) {
            return { ...configuration, name };
          }
          return configuration;
        });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_configurations: modifyConfigurations,
            active_configuration:
              active_configuration.id === configurationId ? { ...active_configuration, name } : active_configuration,
          })),
        );
      } catch (error) {
        dispatch(Actions.changeConfigurationData(configurationId, originalConfiguration || {}));
      }
    };
  }

  public static openRelationProductModal(entityId: string, productName: string, productVersion: string) {
    return async (dispatch) => {
      const related = await ProductsService.getRelatedProduct(entityId);
      if (!related.length) return;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          relatedProductModal: {
            ...prev.relatedProductModal,
            isOpen: true,
            relatedProduct: related,
            productName,
            productVersion,
          },
        })),
      );
    };
  }

  public static closeRelationProductModal() {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          relatedProductModal: defaultState.relatedProductModal,
        })),
      );
    };
  }

  public static openChangeIsActiveConfirmationModal(configurationId: string, is_active: boolean) {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { name: productName } = getState().product.product_root.product_meta;
      const { product_id: productId } = getState().product.product_configurations.active_configuration;
      const { version } = getState().product.product_root;
      const configurationName = getState().product.product_configurations.product_configurations.find(
        (c) => c.id === configurationId,
      )?.name;
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: is_active ? 'Activate configuration?' : 'Inactivate configuration?',
            text: is_active ? (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Opening access to <strong>{productName}</strong> <strong>{configurationName}</strong> will allow this
                  configuration to be selected for production or used in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      if (!productId) return;

                      dispatch(Actions.openRelationProductModal(productId, productName, version));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to activate <strong>{configurationName}</strong>?
                </span>
              </div>
            ) : (
              <div className={s.activate_product_container}>
                <span className={s.text}>
                  Closing access to <strong>{productName}</strong> <strong>{configurationName}</strong> will prevent this
                  configuration from being selected for production or used in{' '}
                  <span
                    className={s.related_product}
                    onClick={() => {
                      if (!productId) return;

                      dispatch(Actions.openRelationProductModal(productId, productName, version));
                    }}
                  >
                    related products.
                  </span>
                </span>
                <span className={s.text}>
                  Are you sure you want to inactivate <strong>{configurationName}</strong>?
                </span>
              </div>
            ),
            icon: is_active ? <ActiveTickIcon /> : <FireMinusIcon />,
            backgroundColor: is_active ? '#BCF4DE' : '#FFCECE',
            withCloseButton: false,
            actionText: is_active ? 'Activate' : 'Inactivate',
            actionButtonColor: is_active ? 'primary' : 'error',
            action: () => dispatch(Actions.updateIsActive(configurationId, is_active)),
          },
        }),
      );
    };
  }

  public static updateIsActive(configurationId: string, is_active: boolean) {
    return async (dispatch, getState: GetStateFunction) => {
      const { product_configurations } = getState().product.product_configurations;
      const originalConfiguration = product_configurations.find((configuration) => configuration.id === configurationId);

      try {
        await ProductConfigurationService.update(configurationId, {
          is_active,
        });
        dispatch(ProductRootController.updateProductModifiedAt());

        const modifyConfigurations = product_configurations.map((configuration) => {
          if (configurationId === configuration.id) {
            return { ...configuration, is_active };
          }
          return configuration;
        });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_configurations: modifyConfigurations,
          })),
        );
        notify.success(is_active ? 'Successfully activated' : 'Successfully deactivated');
      } catch (error) {
        if (originalConfiguration) {
          dispatch(Actions.changeConfigurationData(configurationId, originalConfiguration));
        }

        notify.error(is_active ? 'Activation error' : 'Deactivation error');
      }
    };
  }

  public static manageConfigurationsOrder(newOrder: IdNameOrder[]) {
    return async (dispatch, getState: GetStateFunction) => {
      const { id: productId } = getState().product.product_root;
      const { product_configurations } = getState().product.product_configurations;

      const data: ManageProductConfigurationsData = {
        product_id: productId,
        configurations: newOrder,
      };

      try {
        await ProductConfigurationService.manage(data);
        const newConfigurationsList = newOrder.map(({ id, order, name }) => ({
          ...product_configurations.find((item) => item.id === id),
          id,
          name,
          order,
        }));

        dispatch(stateController.setState({ product_configurations: newConfigurationsList }));
      } catch (error) {
        dispatch(stateController.setState({ product_configurations }));
      }
    };
  }

  public static deleteConfiguration(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { product_configurations } = getState().product.product_configurations;

      const configurationsCopy: ProductConfigurationTab[] = JSON.parse(JSON.stringify(product_configurations));

      const defaultConfigurationId = configurationsCopy
        .sort((a, b) => a.order - b.order)
        .filter((configuration) => configuration.id !== id)[0].id;

      const activeId = getState().product.product_configurations.active_configuration.id;

      try {
        dispatch(Actions.setIsProcessing(true));

        await ProductConfigurationService.delete(id);
        await dispatch(ProductRootController.updateProductModifiedAt());

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            product_configurations: prev.product_configurations.filter((item) => item.id !== id),
          })),
        );
        dispatch(Actions.revalidate());

        if (id === activeId) {
          dispatch(ProductRootController.initConfigurationTab(defaultConfigurationId));
        }

        notify.success('Deleted successfully');
      } catch (error) {
        dispatch(stateController.setState((prev) => ({ ...prev, product_configurations })));
      } finally {
        dispatch(Actions.setIsProcessing(false));
      }
    };
  }

  public static deleteConfigurationTemplate() {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          product_configurations: prev.product_configurations.filter(({ id }) => id !== 'new'),
        })),
      );
    };
  }

  public static revalidate() {
    return (dispatch, getState: GetStateFunction) => {
      const { product_configurations } = getState().product.product_configurations;

      const newValidation: ProductConfigurationValidations = JSON.parse(JSON.stringify(validationsDefaultState));

      // const otherConfigurations = product_configurations.filter((item) => item.id !== active_configuration.id);

      const isEveryConfigurationValid = product_configurations.every((item) => item.is_valid);

      if (isEveryConfigurationValid) {
        newValidation.errors.warnings = PRODUCT_CONFIGURATION_TAB_WARNING_TEXT;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          validations: newValidation,
        })),
      );
    };
  }
}

export class Selectors {
  public static active(state: AppState) {
    return state.product.product_configurations.active_configuration;
  }

  public static isOtherConfigurationsValid(state: AppState) {
    const { active_configuration, product_configurations } = state.product.product_configurations;
    const otherConfigurations = product_configurations.filter((item) => item.id !== active_configuration.id);
    const isEveryConfigurationValid = otherConfigurations.every((item) => item.is_valid);

    return isEveryConfigurationValid;
  }
}

export const reducer = stateController.getReducer();
