import { baseAxiosInstance } from 'axios-config';
import { PAGE_SIZE } from 'pages/product-flow/pages/workflow-task-template/components/task-template-responsibility/components/responsibility/constants';
import { SuggestedUsersResponse } from 'services/task-responsibility.model';
import { UserShortModel } from 'services/user.model';
import {
  CreateWorkflowTaskTemplateTagRelationData,
  ManageWorkflowTaskTemplateTagsData,
  UpdateWorkflowTaskTemplate,
  WorkflowTaskTemplate,
  WorkflowTaskTemplateTagRelation,
} from 'services/workflow-task-template.model';
import { UserStatusEnum } from 'types/status-enums';
import { buildQueryString } from 'utils/build-query-string';

export class WorkflowTaskTemplateService {
  public static async getWorkflowTaskTemplateInfoById(taskTemplateId: string) {
    const { data } = await baseAxiosInstance.get<WorkflowTaskTemplate>(`/workflow-task-templates/info/${taskTemplateId}`);
    return data;
  }

  public static async updateWorkflowTaskTemplate(id: string, productData: Partial<UpdateWorkflowTaskTemplate>) {
    const { data } = await baseAxiosInstance.put<WorkflowTaskTemplate>(`/workflow-task-templates/update/${id}`, productData);
    return data;
  }

  public static async createWorkflowTaskTemplateTagRelation(body: CreateWorkflowTaskTemplateTagRelationData) {
    const { data } = await baseAxiosInstance.post<
      Pick<WorkflowTaskTemplateTagRelation, 'id' | 'workflow_task_template_id' | 'task_tag_id'>
    >('/workflow-task-template-tag-relation/create', body);

    return data;
  }

  public static async deleteWorkflowTaskTemplateTagRelation(id: string) {
    const { data } = await baseAxiosInstance.delete<
      Pick<WorkflowTaskTemplateTagRelation, 'id' | 'workflow_task_template_id' | 'task_tag_id'>
    >(`/workflow-task-template-tag-relation/${id}`);

    return data;
  }

  public static async manageWorkflowTaskTemplateTags(body: ManageWorkflowTaskTemplateTagsData) {
    const { data } = await baseAxiosInstance.post(`/workflow-task-template-tag-relation/manage`, body);

    return data;
  }

  public static async getSuggestedCandidates(
    responsibilityId: string,
    status: UserStatusEnum[],
    skip: number = 0,
    take: number = PAGE_SIZE,
    search?: string,
  ) {
    const queryParams = buildQueryString({ search, skip, take, status });

    const { data } = await baseAxiosInstance.get<SuggestedUsersResponse<UserShortModel[]>>(
      `/workflow-task-template-responsibilities/suggested-users/${responsibilityId}?${queryParams}`,
    );

    return data;
  }
}
