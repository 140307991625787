import { Stack, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import Spinner from 'components/spinner/spinner';
import s from './processing-indicator.module.scss';

type Props = {
  className?: string;
  isProcessingGuard: boolean;
  enableStopPropagation?: boolean;
};

const ProcessingIndicator = ({ isProcessingGuard, enableStopPropagation, className }: Props) => {
  const dispatch = useDispatch();

  const handleProcessContainerClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (enableStopPropagation) e.stopPropagation();

    if (isProcessingGuard) {
      dispatch(PermissionGuardActions.openModal(isProcessingGuard));
    }
  };

  return (
    <Stack className={`${s.process_container} ${className || ''}`} onClick={handleProcessContainerClick}>
      <div className={s.spinner_container}>
        <Spinner size={12} />
      </div>
      <Typography color="#829DB6">Processing</Typography>
    </Stack>
  );
};

export default ProcessingIndicator;
