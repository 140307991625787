import { ClickAwayListener, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { EyeIcon } from 'icons/eye';
import s from './task-description-cell.module.scss';

const options = {
  // eslint-disable-next-line consistent-return
  replace: (domNode) => {
    if (domNode.type === 'tag' && ['p', 'span', 'div', 'a', 'strong', 'em'].includes(domNode.name)) {
      return (
        <domNode.name className={s.ellipsis} {...domNode.attribs}>
          {domToReact(domNode.children, options)}
        </domNode.name>
      );
    }
  },
};

const TaskDescriptionCell = ({ value }: { value?: string }) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement | null>(null);

  if (!value) return null;

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  return (
    <div className={s.container} ref={anchorRef} onClick={handleOpen}>
      <div className={s.ellipsis}>{parse(value, options)}</div>
      <div className={s.eye} onClick={handleOpen}>
        <EyeIcon width={16} height={16} stroke="#141414" />
      </div>
      <Popover
        open={isOpen}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        slotProps={{ paper: { style: { padding: 0 } } }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div className={s.task_description}>{parse(value)}</div>
        </ClickAwayListener>
      </Popover>
    </div>
  );
};

export default TaskDescriptionCell;
