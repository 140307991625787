/* eslint-disable jsx-a11y/no-autofocus */
import { Tooltip } from '@mui/material';
import { debounce } from 'utils/debounce';
import { IdName } from 'types/common-types';
import { useClickAway, useKeyPress } from 'ahooks';
import { formatToStringArray } from 'utils/array-helpers';
import { PaginateResponse } from 'types/paginate-response';
import InputButtons from 'components/input-buttons/input-buttons';
import useDisableDocumentScroll from 'hooks/use-disable-document-scroll';
import { LightTooltip } from 'components/ui-new/light-tooltip/light-tooltip';
import { CSSProperties, HTMLAttributes, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import AutosuggestDropdown from 'components/text-editable/components/autosuggest-dropdown/autosuggest-dropdown';
import s from './text-editable.module.scss';

const PAGE_SIZE = 10;

const hiddenStyles: CSSProperties = { opacity: 0, position: 'absolute', pointerEvents: 'none' };
const visibleStyles = (position: 'relative' | 'absolute' = 'relative'): CSSProperties => ({
  opacity: 1,
  position,
  pointerEvents: 'all',
});

export type TextEditableProps = HTMLAttributes<HTMLDivElement> & {
  arrayOfUniqueValues?: { value: string; id: number | string }[]; // an array by the values of which the current value (inputValue) is compared
  idOfCurrentValue?: number | string; // if the array of values also contains our value
  tooltipTextForNonUniqueValue?: string; // custom text for the tooltip if the value is not unique
  value: string;
  tooltip?: string;
  maxWidth?: string;
  focused?: boolean;
  className?: string;
  placeholder?: string;
  withLoader?: boolean;
  style?: CSSProperties;
  errorTitle?: ReactNode;
  textClassName?: string;
  allowedEmpty?: boolean;
  highlitedValue?: string;
  resizableInput?: boolean;
  disableActions?: boolean;
  isNotClickable?: boolean;
  enableClickAway?: boolean;
  actionButtonsSize?: number;
  maxLettersWarning?: number;
  inputWidth?: number | string;
  placeholderClassName?: string;
  previewWidth?: number | string;
  textValidationTitle?: ReactNode;
  inputMinWidth?: number | string;
  isFullWidthAutosuggest?: boolean;
  reservedPlaceForButtons?: boolean;
  previewMinWidth?: number | string;
  actionsPosition?: 'bottom' | 'right' | 'top';
  onClickAway?: () => void;
  onCheckClick?: () => void;
  onCancelClick?: () => void;
  onEditModeStart?: () => void;
  onValueChange?: (value: string) => void;
  onEditModeEnd?: (value: string) => void;
  validationCallback?: (value: string) => Promise<boolean>;
  onEditEnd?: ((value: string) => void) | ((value: string) => Promise<void>);
  validateValue?: ((value: string) => boolean) | ((value: string) => Promise<boolean>);
  getSuggestedValues?: (
    value: string,
    skip: number,
    take: number,
  ) => Promise<PaginateResponse<string>> | Promise<PaginateResponse<IdName>>;
};

export default function TextEditable({
  tooltip,
  className,
  style = {},
  textClassName,
  errorTitle = '',
  focused = false,
  idOfCurrentValue,
  placeholder = '',
  maxWidth = '100%',
  actionButtonsSize,
  withLoader = false,
  inputWidth = 'auto',
  arrayOfUniqueValues,
  highlitedValue = '',
  allowedEmpty = false,
  placeholderClassName,
  previewWidth = 'auto',
  resizableInput = false,
  inputMinWidth = '100%',
  isNotClickable = false,
  disableActions = false,
  enableClickAway = true,
  maxLettersWarning = 256,
  textValidationTitle = '',
  value: initialValue = '',
  previewMinWidth = '100%',
  actionsPosition = 'bottom',
  isFullWidthAutosuggest = false,
  reservedPlaceForButtons = false,
  tooltipTextForNonUniqueValue = 'This name already exists',
  onEditEnd,
  onClickAway,
  onCheckClick,
  onCancelClick,
  onEditModeEnd,
  onEditModeStart,
  validationCallback,
  getSuggestedValues,
  onValueChange = () => {},
  validateValue = () => true,
  ...rest
}: TextEditableProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [isUniqueValue, setIsUniqueValue] = useState(true);
  const [isValueChanged, setValueChanged] = useState(false);
  const [inputValue, setInputValue] = useState(initialValue);
  const [isLazyProcessing, setLazyProcessing] = useState(false);
  const [isEditMode, setIsEditMode] = useState(focused || false);
  const [autosuggestLastPage, setAutosuggestLastPage] = useState(1);
  const [resizableInputWidth, setResizableInputWidth] = useState(0);
  const [isShowValueTooltip, setIsShowValueTooltip] = useState(false);
  const [suggestedValues, setSuggestedValues] = useState<string[]>([]);
  const [isInputValueNotValid, setInputValueNotValid] = useState(false);
  const [isAutosuggestLoading, setAutosuggestLoading] = useState(false);
  const [autosuggestCurrentPage, setAutosuggestCurrentPage] = useState(0);

  const valueTooltip = tooltip || (isShowValueTooltip && inputValue) || '';
  const isAutosuggestVisible = isEditMode && Boolean(inputValue) && isValueChanged;
  const isButtonDisabled = isShowError || isInputValueNotValid || !isUniqueValue || (!allowedEmpty && inputValue.trim() === '');

  useDisableDocumentScroll(Boolean(isEditMode && getSuggestedValues), 'textEditableWithAutosuggest');

  const resetState = () => {
    if (isShowError) setIsShowError(false);

    setIsEditMode(false);

    setInputValue(initialValue);

    setInputValueNotValid(false);
  };

  const isInputValueLengthValid = (value) => {
    return value.length < maxLettersWarning;
  };

  const onSuggestedItemClick = (value: string) => {
    setInputValue(value);
    updateValue(value);
  };

  const updateValue = async (value?: string) => {
    if (!isEditMode) return;
    if (!isUniqueValue) return;

    const val = value ?? inputValue;

    await setInputValue(val.trim());

    if (val === initialValue) {
      if (onCheckClick) onCheckClick();

      setIsEditMode(false);
      return;
    }

    try {
      if (withLoader) setIsLoading(true);

      const isLengthValid = isInputValueLengthValid(val);

      if (!isLengthValid) {
        setInputValueNotValid(true);
        return;
      }

      const isAllowUpdate = val.trim() === '' || (!isShowError && (await validateValue(val)));

      if (!isAllowUpdate) {
        setIsShowError(true);
        return;
      }

      if (validationCallback) {
        const data = await validationCallback(val);
        if (!data) return;
      }

      if (allowedEmpty || val.trim() !== '') {
        if (onEditEnd) await onEditEnd(val.trim());
        setIsEditMode(false);
      }
    } finally {
      if (withLoader) setIsLoading(false);
    }
  };

  const onClickCapture = (event) => {
    if (isNotClickable) return;

    event.stopPropagation();
    setIsEditMode(true);
    inputRef.current?.select();
  };

  const handleOnCancelClick = () => {
    resetState();
    if (isEditMode && onCancelClick) {
      onCancelClick();
    }
  };

  const handleOnScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    if (!getSuggestedValues) return;
    const { scrollTop, clientHeight, scrollHeight } = event.target as HTMLDivElement;
    const bottomOffset = scrollHeight - (scrollTop + clientHeight);
    const shouldLoadMore = bottomOffset < 100 && autosuggestCurrentPage !== autosuggestLastPage;
    if (isEditMode && shouldLoadMore) {
      debounce(async () => {
        setLazyProcessing(true);
        const data = await getSuggestedValues(inputValue, autosuggestCurrentPage * PAGE_SIZE, PAGE_SIZE);
        setAutosuggestLastPage(data.meta.lastPage);
        setAutosuggestCurrentPage(data.meta.currentPage);
        setSuggestedValues([...suggestedValues, ...formatToStringArray(data.data, 'name')]);
        setLazyProcessing(false);
      }, 200);
    }
  };

  const handleGetSuggestedValues = (val: string) => {
    setAutosuggestLoading(true);

    if (val) {
      debounce(async () => {
        if (getSuggestedValues) {
          const data = await getSuggestedValues(val, 0, PAGE_SIZE);
          setAutosuggestLastPage(data.meta.lastPage);
          setAutosuggestCurrentPage(data.meta.currentPage);
          setSuggestedValues(formatToStringArray(data.data, 'name'));
        }
        setAutosuggestLoading(false);
      }, 400);
    } else {
      setAutosuggestLoading(false);
    }
  };

  const actionsTransform = useMemo(() => {
    if (actionsPosition === 'bottom') return `translate(0, 110%)`;

    if (actionsPosition === 'top') return `translate(0, -110%)`;

    if (actionsPosition === 'right') return `translate(110%, 0)`;

    return `translate(0, 110%)`;
  }, [actionsPosition]);

  useKeyPress(['enter'], () => {
    if (isEditMode && inputValue.trim().length) updateValue();
  });

  useKeyPress(['esc', 'escape'], () => {
    if (onClickAway && isEditMode) onClickAway();
    resetState();
  });

  // possibility to switch into edit mode from outer component
  useEffect(() => {
    if (focused) {
      inputRef.current?.select();
    }

    if (focused && !isEditMode) {
      setIsEditMode(true);
      inputRef.current?.select();
    }
    if (!focused && isEditMode) {
      setIsEditMode(false);
      setInputValue(initialValue);
    }
  }, [focused]);

  // possibility to notify outer component about state change
  useEffect(() => {
    if (isEditMode && onEditModeStart) onEditModeStart();

    if (!isEditMode) {
      if (onEditModeEnd) onEditModeEnd(inputValue);
      setValueChanged(false);
    }
  }, [isEditMode]);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue, focused]);

  useEffect(() => {
    if ((divRef?.current?.scrollWidth || 0) > (divRef?.current?.clientWidth || 0)) {
      setIsShowValueTooltip(true);
    } else {
      setIsShowValueTooltip(false);
    }
  }, [divRef?.current?.scrollWidth, divRef?.current?.clientWidth]);

  useClickAway(() => {
    if (!enableClickAway) return;
    if (onClickAway && isEditMode) onClickAway();

    resetState();
  }, [containerRef, inputRef, divRef]);

  useEffect(() => {
    setResizableInputWidth(divRef.current?.clientWidth || 0);

    if (isEditMode) {
      if (arrayOfUniqueValues && !idOfCurrentValue) {
        setIsUniqueValue(!arrayOfUniqueValues.some((i) => i.value.trim() === inputValue.trim()));
      }

      if (arrayOfUniqueValues && idOfCurrentValue) {
        const isUnique = arrayOfUniqueValues.every((i) => i.value.trim() !== inputValue.trim() || i.id === idOfCurrentValue);
        setIsUniqueValue(isUnique);
      }
    }
  }, [inputValue, isEditMode]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef?.current && !containerRef?.current.contains(event.target)) {
        setInputValue(initialValue);
        setIsEditMode(false);
        onClickAway?.();
      }
    };

    if (isEditMode) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditMode, setIsEditMode, onClickAway]);

  return (
    <LightTooltip
      disableHoverListener
      disableTouchListener
      className={s.tooltip}
      placement="top-start"
      open={isShowError || isInputValueNotValid}
      title={isInputValueNotValid ? textValidationTitle : errorTitle}
    >
      <div
        ref={containerRef}
        className={`${s.container} ${className}`}
        data-reserved-place={isEditMode && reservedPlaceForButtons}
        style={{ width: isEditMode ? inputWidth : previewWidth, ...style }}
        {...rest}
      >
        <Tooltip title={valueTooltip} placement="top-start">
          <div
            style={{
              ...(isEditMode || (!inputValue && placeholder) ? hiddenStyles : visibleStyles()),
              width: !resizableInput ? previewWidth : undefined,
              maxWidth,
              minWidth: resizableInput && initialValue ? 'auto' : previewMinWidth,
              whiteSpace: 'pre-wrap',
            }}
            className={`${isEditMode ? s.hidden_preview : s.preview} ${textClassName} ${isNotClickable ? s.not_clickable : ''}`}
            ref={divRef}
            onClickCapture={(event) => onClickCapture(event)}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: highlitedValue || inputValue.replace(/ /g, '&nbsp;'),
            }}
          />
        </Tooltip>

        <div
          style={{ ...(!inputValue && placeholder && !isEditMode ? visibleStyles() : hiddenStyles) }}
          className={`${s.placeholder} ${placeholderClassName} ${isNotClickable ? s.not_clickable : ''}`}
          onClickCapture={(event) => onClickCapture(event)}
        >
          {placeholder}
        </div>

        <Tooltip
          placement="top-start"
          open={!isUniqueValue && isEditMode && true}
          title={isUniqueValue ? '' : tooltipTextForNonUniqueValue}
        >
          <input
            style={{
              ...(isEditMode ? visibleStyles() : hiddenStyles),
              minWidth: inputMinWidth,
              width: resizableInput ? resizableInputWidth : inputWidth,
            }}
            className={`${s.input} ${textClassName}`}
            ref={inputRef}
            value={inputValue || ''}
            onClick={(e) => e.stopPropagation()}
            onChange={(event) => {
              // ignore changes while not in edit mode (for example: when user left edit mode and pressed CMD+Z action)
              if (isEditMode) {
                if (isShowError) setIsShowError(false);
                if (!isInputValueLengthValid(event.currentTarget.value)) {
                  if (event.currentTarget.value.length <= maxLettersWarning) setInputValue(event.currentTarget.value);
                  setInputValueNotValid(true);
                  return;
                }
                setInputValueNotValid(false);
                setValueChanged(true);
                if (getSuggestedValues) handleGetSuggestedValues(event.currentTarget.value);
                setInputValue(event.currentTarget.value);
                // notify outer component about changes (required in very specific cases)
                onValueChange(event.currentTarget.value);
              }
            }}
          />
        </Tooltip>

        {Boolean(Array.isArray(suggestedValues) && getSuggestedValues) && (
          <AutosuggestDropdown
            searchedValue={inputValue}
            fullWidth={isFullWidthAutosuggest}
            isLazyProcessing={isLazyProcessing}
            suggestedValues={suggestedValues || []}
            isAutosuggestLoading={isAutosuggestLoading}
            isAutosuggestVisible={isAutosuggestVisible}
            inputHeight={containerRef.current?.clientHeight}
            onScroll={handleOnScroll}
            onSuggestedItemClick={onSuggestedItemClick}
          />
        )}

        {!disableActions && (
          <div
            className={s.actions}
            style={{ ...(isEditMode ? visibleStyles('absolute') : hiddenStyles), transform: actionsTransform }}
          >
            <InputButtons
              isLoading={isLoading}
              buttonSize={actionButtonsSize}
              isConfirmDisabled={isButtonDisabled}
              onConfirmClick={() => updateValue()}
              onCancelClick={handleOnCancelClick}
            />
          </div>
        )}
      </div>
    </LightTooltip>
  );
}
