import { Page } from 'pages/production/controllers/production-list-controller/types';

import { DeadlineUpdateBody } from 'pages/production-workflow/controllers/production-workflow.controller';
import {
  ProductionWorkflow,
  ProductionWorkflowHierarchy,
  ProductionWorkflowTaskResponsibility,
} from 'services/production-workflow.model';
import { LocationTheProductionStatusIsChangingFrom } from 'types/common-enums';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';

export type SilentLoadProductionWorkflowArgs = {
  id: string;
  disableAdditionalTasksSet?: boolean;
};

export type HandleProductionStatusArgs = {
  productionIdToUpdate: string;
  status: ProductionStatusEnum;
  updatingStatusFrom: LocationTheProductionStatusIsChangingFrom;
  production?: ProductionWorkflow;
  productionIdToLoadDataFor?: string;
};

type ProductionInfoForStatusManageT = {
  id: string;
  title: string;
  version: number;
  variantId: string;
  variantName: string;
  configurationName: string;
  status: ProductionStatusEnum;
};

export type UpdateProductionStatusArgs = {
  status: ProductionStatusEnum;
  productionInfo: ProductionInfoForStatusManageT;
  location: LocationTheProductionStatusIsChangingFrom;
  productionIdToLoadDataFor?: string;
  silentLoad?: VoidFunction;
};

export type ShouldTriggerDeadlineWarningArgs = {
  targetId: string;
  newDeadline: string;
  productionIds: string[];
  hierarchy: ProductionWorkflowHierarchy[];
  childProductions: ProductionWorkflowHierarchy[];
  parentProduction: ProductionWorkflowHierarchy | undefined;
};

export type OnConfirmUpdateDeadlineArgs = {
  page: Page;
  rootMetaId?: string;
  rootOrderId?: string;
  productionId: string;
  productionName: string;
  newDeadlineBody: DeadlineUpdateBody;
};

export type UpdateDeadlineArgs = {
  page: Page;
  rootMetaId: string;
  newDeadline: string;
  rootOrderId: string;
  productionId: string;
  isMultiUpdate: boolean;
};

export type PlannedStartDateT = {
  day: number;
  year: number;
  hours: number;
  month: number;
  minutes: number;
};

export type UpdatePlannedStartDateArgs = {
  page: Page;
  rootMetaId?: string;
  newDate: PlannedStartDateT;
  rootOrderId?: string;
  productionId: string;
};

export type FinishedAutoAssignWebsocketResponseT = {
  id: string;
  taskResponsibilities: ProductionWorkflowTaskResponsibility;
};

export enum ProductionWorkflowWebsocketEventCategory {
  AUTO_ASSIGNMENT = 'auto_assignment',
  BULK_STATUS_CHANGE_VIEWER = 'bulk_status_change_viewer',
}

export type StartedTaskStatusChangeForTasksTableT = {
  bulkStatusChangeId: string;
  status: TaskStatusEnum;
  tasksIds: string[];
  totalNumberOfTasksForProcessing: number;
  user_id: string;
};

export type EndTaskStatusChangeForTasksTableT = {
  bulkStatusChangeId: string;
  status: TaskStatusEnum;
  task_ids: string[];
  user_id: string;
};

export type WebsocketStartEndMessageForTaskStatusT = StartedTaskStatusChangeForTasksTableT | EndTaskStatusChangeForTasksTableT;

export type WebsocketResponseMessageForProductionWorkflowT =
  | WebsocketStartEndMessageForTaskStatusT
  | FinishedAutoAssignWebsocketResponseT;
