import { DropDownProps, TasksFiltersEnum } from 'pages/tasks/types/types';
import { DepartmentsIcon } from 'icons/departments';
import HighlightText from 'components/highlight-text/highlight-text';
import DropdownSearchChipsSelector from 'components/ui-new/dropdown-search-chips-selector/dropdown-search-chips-selector';
import { PaginateResponse } from 'types/paginate-response';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { IdName } from 'types/common-types';
import { Stack, Typography } from '@mui/material';
import { getDepartmentPath } from 'pages/production/controllers/production-filters-controller/helpers';
import FloatingFilterButton from 'pages/tasks/components/floating-filter-button/floating-filter-button';
import { TextEllipsis } from 'components/text-ellipsis/text-ellipsis';
import { GridApi } from 'ag-grid-community';
import s from '../floating-filter/floating-filter.module.scss';

type Props = {
  isOpen: boolean;
  gridApi: GridApi;
  fieldName: TasksFiltersEnum;
  openPopover: () => void;
  setIsOpen: (value: boolean) => void;
  fetchOptionsFunction: (params: { search?: string; skip?: number; take?: number }) => Promise<PaginateResponse<IdName>>;
} & DropDownProps;

const DropDownSearchChipsFilter = ({
  anchorEl,
  isOpen,
  fieldName,
  gridApi,
  setIsOpen,
  openPopover,
  fetchOptionsFunction,
}: Props) => {
  const dispatch = useDispatch();
  const filter = useSelector((state: AppState) => state.tasks.filters.filters[fieldName]);

  return (
    <DropdownSearchChipsSelector
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      disablePortal={false}
      externalAnchorEl={anchorEl}
      className={s.container_button}
      isFloatingFilterButton
      headerCheckBoxes={filter.shortcutOptions}
      renderTooltip={(item) => (
        <div>
          <div>{item.name}</div>
          {item.path && (
            <Stack flexDirection="row" alignItems="baseline" gap="4px">
              <div>
                <DepartmentsIcon width={13} height={13} stroke="#5E7285" />
              </div>
              <Typography fontSize={13} color="#5E7285">
                {item.path}
              </Typography>
            </Stack>
          )}
        </div>
      )}
      renderSelectItem={(item, keyword) => (
        <Stack flexDirection="row" alignItems="baseline">
          <div>
            <DepartmentsIcon width={14} height={14} />
          </div>
          <TextEllipsis
            tooltipTitle={
              <div>
                <Typography>{item.name}</Typography>
                {item.path && (
                  <Stack flexDirection="row" alignItems="baseline" gap="4px">
                    <div>
                      <DepartmentsIcon width={14} height={14} stroke="#5E7285" />
                    </div>
                    <div style={{ fontSize: 13, color: '#5E7285' }}>{item.path}</div>
                  </Stack>
                )}
              </div>
            }
          >
            <HighlightText text={item.name} query={keyword} />
            {item.path}
          </TextEllipsis>
        </Stack>
      )}
      dataFormatting={(items) => getDepartmentPath(items)}
      options={(filter?.options as any[]) || []}
      values={filter.value as IdName[]}
      onSelect={(values, value, checked) =>
        dispatch(
          TasksActions.onFilterChange({
            fieldName,
            value: values,
            id: values[0]?.id,
            chipsSelector: true,
            newValue: value,
            isChecked: checked,
            gridApi,
          }),
        )
      }
      onChangeInput={(value) => dispatch(TasksActions.setFilterOptions(fieldName, value))}
      fetchData={(keyword, options) => fetchOptionsFunction({ search: keyword, skip: options?.skip, take: options?.take })}
    >
      <Stack flexDirection="row" alignItems="center" justifyContent="flex-end" gap="12px">
        <FloatingFilterButton fieldName={fieldName} onClick={openPopover} />
      </Stack>
    </DropdownSearchChipsSelector>
  );
};

export default DropDownSearchChipsFilter;
