import { PriorityEnum } from 'types/priority-enums';
import { ProductionStatusEnum, TaskStatusEnum } from 'types/status-enums';
import { FailedTaskModel, RewardType } from 'services/production-task.model';
import { TaskSuggestedUsersModel } from 'services/task-responsibility.model';
import { ProductionWorkflowTaskResponsibility } from 'services/production-workflow.model';
import { WebsocketResponseMessageForTaskStatusT } from 'services/task-table.model';

export type TaskModel = {
  id: string;
  name: string;
  type: string;
  taskKey?: string;
  product_name?: string;
  isEmptySlot?: boolean;
  configuration?: string;
  status: ProductionStatusEnum | TaskStatusEnum;
};

export type DescriptionState = {
  text: string;
  isEditing: boolean;
};

export type RelatedTasksState = {
  nextTasks: TaskModel[];
  previousTasks: TaskModel[];
  failedTasks: FailedTaskModel[];
};

export type TaskAssignmentRewardState = {
  bonus: number;
  total: number;
  userId: string;
  reward: number;
  lastName: string;
  firstName: string;
  timeSpent: string;
  correctionSum: string;
  avatarImageUrl: string;
  items_produced: number;
  timeSpentPercent: number;
  taskAssignmentId: string;
  correctionSumPrev: string;
  is_in_current_reporting_period: boolean;
};

export type EditNumberOfProducedItemsModalState = {
  isLoading: boolean;
  users: ShortUserT[];
  isModalOpen: boolean;
  updatedAssignments: ShortUserT[];
};

export type ShortUserT = {
  userId: string;
  lastName: string;
  firstName: string;
  producedItems: number;
  taskAssignmentId: string;
};

export type TaskBonusesState = {
  id: string;
  name: string;
  value: string;
  valuePrev: string;
  rewardType: RewardType;
};

export type RewardsState = {
  totalBonus: string;
  totalReward: string;
  basicReward: string;
  itemsProduced: number;
  basicRewardPrev: string;
  bonuses: TaskBonusesState[];
  assignmentRewards: TaskAssignmentRewardState[];
};

export type PerformersModel = {
  users: TaskSuggestedUsersModel;
};

export type ProductState = {
  id: string;
  name: string;
  type: string;
  version: string;
  sourceId: string;
  vendorName: string;
  product_configuration_details: string;
};

export type ConfigurationState = {
  id: string;
  sku: string;
  name: string;
};

export type VariantState = {
  id: string;
  sku: string;
  name: string;
  barcode: string;
};

export type UpdateTaskValueT = {
  name?: string;
  is_in_queue?: boolean;
  due_date?: Date | null;
  priority?: PriorityEnum;
  status?: TaskStatusEnum;
};

export type StartedTaskStatusChangeForTasksTableT = {
  bulkStatusChangeId: string;
  status: TaskStatusEnum;
  tasksIds: string[];
  totalNumberOfTasksForProcessing: number;
  user_id: string;
};

export type EndTaskStatusChangeForTasksTableT = {
  bulkStatusChangeId: string;
  status: TaskStatusEnum;
  task_ids: string[];
  user_id: string;
};

export type WebsocketStartEndMessageForTaskStatusT = StartedTaskStatusChangeForTasksTableT | EndTaskStatusChangeForTasksTableT;

export type FinishedAutoAssignWebsocketResponseT = {
  taskResponsibilities: ProductionWorkflowTaskResponsibility;
};

export type WebsocketResponseMessageForTaskDetailsT =
  | WebsocketStartEndMessageForTaskStatusT
  | FinishedAutoAssignWebsocketResponseT
  | WebsocketResponseMessageForTaskStatusT;

export enum TaskDetailsWebsocketEventCategory {
  BULK_STATUS_CHANGE_VIEWER = 'bulk_status_change_viewer',
  TASK_AUTO_ASSIGNMENT = 'task_auto_assignment',
  BULK_STATUS_CHANGE = 'bulk_status_change',
}
