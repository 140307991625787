import { Menu } from '@mui/material';
import { useDispatch } from 'react-redux';
import { MouseEvent, ReactNode, useRef, useState } from 'react';
import TagContain from 'components/tag-contain/tag-contain';
import { usePopupState } from 'material-ui-popup-state/hooks';
import { AccessLevel, Permission } from 'services/permission.model';
import { bindMenu, bindTrigger } from 'material-ui-popup-state/core';
import { LightTooltip } from 'components/ui-new/light-tooltip/light-tooltip';
import { configurableStatuses, statuses } from 'components/status-selector/constants';
import { ProductionStatusEnum, TaskStatusEnum, UserStatusEnum } from 'types/status-enums';
import { useIsPermitted } from 'modules/permission-guard/hooks/use-current-user-permissions';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import s from './status-selector.module.scss';

type StatusesT = ProductionStatusEnum | TaskStatusEnum | UserStatusEnum;

type Props<T extends StatusesT> = {
  status: T;
  toolTip?: string;
  tagClassName?: string;
  optionClassName?: string;
  isBlockedLaunch?: boolean;
  isEditPermitted?: boolean;
  disableDropDown?: boolean;
  useCustomTrigger?: boolean;
  customTrigger?: ReactNode;
  isProcessingGuard?: boolean;
  blockedLaunchTitle?: string;
  containerClassName?: string;
  isAdditionalTasks?: boolean;
  enableStopPropagation?: boolean;
  noDropDownOnExceptions?: boolean;
  productionStatus?: ProductionStatusEnum;
  optionsFor: keyof typeof configurableStatuses;
  onClose?: VoidFunction;
  onSelect?: (status: T) => Promise<void> | void;
};

function StatusSelector<T extends StatusesT>({
  status,
  toolTip,
  optionsFor,
  customTrigger,
  useCustomTrigger,
  isBlockedLaunch,
  disableDropDown,
  productionStatus,
  tagClassName = '',
  blockedLaunchTitle,
  containerClassName,
  optionClassName = '',
  enableStopPropagation,
  isEditPermitted = true,
  isProcessingGuard = false,
  onClose,
  onSelect,
}: Props<T>) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const rootRef = useRef<HTMLDivElement>(null);
  const popupState = usePopupState({ variant: 'dialog', popupId: `actions-${status}` });

  const dispatch = useDispatch();

  const isPermittedManageFailedTasks = useIsPermitted(Permission.webManageFailedTasks, [AccessLevel.access]);

  const isDisabledByProductionStatus =
    productionStatus === ProductionStatusEnum.Done ||
    productionStatus === ProductionStatusEnum.Launching ||
    (productionStatus === ProductionStatusEnum.Canceled &&
      (status === TaskStatusEnum.Done || status === TaskStatusEnum.Canceled));

  const isDisable = disableDropDown || isDisabledByProductionStatus || isLoading;

  const handleOptionSelect = async (value: StatusesT) => {
    setIsOpen(false);
    onClose?.();

    setLoading(true);
    setTimeout(async () => {
      try {
        await onSelect?.(value as T);
      } finally {
        setLoading(false);
      }
    }, 30);
  };

  const handleClick = (e: MouseEvent<HTMLDivElement>, value: boolean) => {
    if (enableStopPropagation) e.stopPropagation();

    if (!isEditPermitted) {
      dispatch(PermissionGuardActions.openModal());
      return;
    }

    if (isProcessingGuard) {
      dispatch(PermissionGuardActions.openModal(isProcessingGuard));

      return;
    }

    if (isDisable) return;

    setIsOpen(value);

    if (!value) onClose?.();
  };

  const renderOptions = configurableStatuses[optionsFor][status as StatusesT];

  return (
    <div ref={rootRef} className={`${s.select_wrapper} ${containerClassName}`} {...bindTrigger(popupState)}>
      {useCustomTrigger && customTrigger ? (
        <div onClick={(e) => handleClick(e, true)}>{customTrigger}</div>
      ) : (
        <LightTooltip className={s.tooltip} title={toolTip} disableHoverListener={!toolTip} placement="bottom-start">
          <div onClick={(e) => handleClick(e, true)} className={s.status_container}>
            <TagContain
              // eslint-disable-next-line no-nested-ternary
              color={isOpen ? (status === UserStatusEnum.Active ? 'deep_green' : '') : ''}
              {...(status ? statuses[status] : {})}
              active={isOpen}
              disabled={isLoading}
              className={`${s.tag} ${tagClassName} ${status ? s[statuses[status]?.className] : ''}`}
              // onClick serves here as disable or enables hover effects on the tag
              // property disabled is not used because it changes the color which is not expected to happen
              onClick={() => {}}
            />
          </div>
        </LightTooltip>
      )}

      <div onClick={(e) => handleClick(e, false)}>
        <Menu
          {...bindMenu(popupState)}
          anchorEl={rootRef.current}
          open={isOpen}
          className={s.option_list}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {renderOptions?.map((option) => {
            if (isBlockedLaunch && option.value === ProductionStatusEnum.In_Progress) {
              return (
                <LightTooltip
                  key={option.text}
                  className={s.tooltip}
                  placement="top-start"
                  title={blockedLaunchTitle}
                  disableHoverListener={!blockedLaunchTitle}
                >
                  <div className={`${s.container} ${s[option.className]} ${s.disabled} ${optionClassName}`}>
                    <div className={s.icon_container}>{option.iconLeft}</div>
                    <span className={s.title}>{option.text}</span>
                  </div>
                </LightTooltip>
              );
            }
            if (!isPermittedManageFailedTasks && option.value === TaskStatusEnum.Reopened) {
              return (
                <div key={option.text} className={`${s.container} ${s[option.className]} ${s.disabled} ${optionClassName}`}>
                  <div className={s.icon_container}>{option.iconLeft}</div>
                  <span className={s.title}>{option.text}</span>
                </div>
              );
            }
            return (
              <div
                key={option.text}
                className={`${s.container} ${s[option.className]} ${optionClassName}`}
                onClick={() => handleOptionSelect(option.value)}
              >
                <div className={s.icon_container}>{option.iconLeft}</div>
                <span className={s.title}>{option.text}</span>
              </div>
            );
          })}
        </Menu>
      </div>
    </div>
  );
}

export default StatusSelector;
