import { CSSProperties } from 'react';
import { TextEllipsis } from '../text-ellipsis/text-ellipsis';
import s from './highlight-text.module.scss';

type Props = {
  text: string;
  query: string;
  maxWidth?: string;
  style?: CSSProperties;
};

const HighlightText = ({ text, query, style, maxWidth = '100%' }: Props) => {
  const index = text.toLowerCase().indexOf(query.toLowerCase());

  if (index === -1) return <TextEllipsis maxWidth={maxWidth}>{text}</TextEllipsis>;

  return (
    <TextEllipsis style={style} tooltipTitle={text} maxWidth={maxWidth}>
      <div>
        {text.substring(0, index)}
        <span className={s.text_highlight}>{text.substring(index, index + query.length)}</span>
        {text.substring(index + query.length)}
      </div>
    </TextEllipsis>
  );
};

export default HighlightText;
