import { baseAxiosInstance } from 'axios-config';
import { UpdateUserPermissionRequest, UserPermissionsResponse } from 'services/permission.model';

export class PermissionsService {
  public static async getPermissionsByUserId(userId: string) {
    const { data } = await baseAxiosInstance.get<UserPermissionsResponse>(`/permissions/user/${userId}`);
    return data;
  }

  public static async updatePermissionsByUserId(request: UpdateUserPermissionRequest) {
    const { data } = await baseAxiosInstance.post<UserPermissionsResponse>(`/permissions/manage/`, request);
    return data;
  }
}
